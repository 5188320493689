import styles from "./AddDataButton.module.css";
import plusIcon from "/assets/common/Plus_White.svg";
import Button from "../../UI/Button/Button";

const AddDataButton = ({ ...props }) => {
  return (
    <div className={styles.container}>
      <Button
        className={styles.btn__add}
        buttonstyle="default-blue"
        onClick={props.onClick}
      >
        <img src={plusIcon} />
        ADD DATA
      </Button>
    </div>
  );
};

export default AddDataButton;
