import { LBL__NO, LBL__YES } from "../../../consts/TextConstants";
import Button from "../../UI/Button/Button";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import styles from "./AccountingPermissionOverlay.module.css";
import addIcon from "/assets/common/Plus_White.svg";

const AccountingPermissionOverlay = ({ className, ...props }) => {
  return (
    <OverlayRegular
      keyName="edit-overlay"
      className={`${styles.overlay} ${className}`}
      blurValue="0.15rem"
      onClose={() => props.onClose(false)}
    >
      <div className={styles.title}>Accounting</div>
      <div className={styles.txt__permission}>
        Do you want Merze to prepare Financials?
      </div>
      <div className={styles.action__items}>
        <Button
          buttonstyle="default-blue-outline"
          onClick={() => props.onClose(false)}
        >
          {LBL__NO}
        </Button>
        <Button buttonstyle="default-blue" onClick={() => props.onClose(true)}>
          {LBL__YES}
        </Button>
      </div>
    </OverlayRegular>
  );
};

export default AccountingPermissionOverlay;
