import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./SideBar.module.css";
import ListItem from "./ListItem";
import logoIcon from "/assets/dashboard/Logo_Dashboard.svg";
import { APP_NAME } from "../../../consts/TextConstants";
import {
  navMenuFeatureItemsList,
  navMenuInfoItemsList,
  navMenuOtherItemsList,
  navMenuItems,
} from "./NavigationLists";
import EmailCard from "../../UI/Email/EmailCard";

const SideBar = () => {
  const location = useLocation();
  const [showEmailOverlay, setShowEmailOverlay] = useState(false);
  const { primaryEntity } = useSelector((state) => state.entity);

  const handleClickOtherItem = (item) => {
    if (item.title === navMenuItems.FEEDBACK) {
      setShowEmailOverlay(true);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo_box}>
          <div className={styles.logo_container}>
            <img src={logoIcon} alt="" />
          </div>
          <div className={styles.title}>{APP_NAME}</div>
          <div className={styles.version}>BETA</div>
        </div>

        <div className={styles.nav_menu}>
          {Object.values(navMenuFeatureItemsList).map((item) => (
            <NavLink
              to={item.path}
              key={item.title}
              state={{ path: item.path }}
            >
              <ListItem
                labelTitle={item.title}
                icon={item.icon}
                active={item.path == location.pathname}
                iconActive={item.iconActive}
              />
            </NavLink>
          ))}
        </div>

        <div className={styles.separator}></div>

        <div>
          {Object.values(navMenuInfoItemsList).map((item) =>
            item.disabled ? (
              <div key={item.title} className={styles.disabledLink}>
                <ListItem
                  labelTitle={item.title}
                  icon={item.icon}
                  active={false}
                  disabled={true}
                />
              </div>
            ) : (
              <NavLink
                to={item.path}
                key={item.title}
                state={{ path: item.path }}
              >
                <ListItem
                  labelTitle={item.title}
                  icon={item.icon}
                  active={item.path === location.pathname}
                  iconActive={item.iconActive}
                />
              </NavLink>
            )
          )}
        </div>
      </div>
      <div className={styles.nav_menu_others}>
        {Object.values(navMenuOtherItemsList).map((item) => (
          <ListItem
            key={item.title}
            labelTitle={item.title}
            icon={item.icon}
            active={item.path == location.pathname}
            iconActive={item.iconActive}
            onClick={() => handleClickOtherItem(item)}
          />
        ))}
      </div>
      {showEmailOverlay && (
        <EmailCard
          onClose={() => setShowEmailOverlay(false)}
          entityId={primaryEntity?.entityId}
        />
      )}
    </div>
  );
};

export default SideBar;
