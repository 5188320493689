import styles from "./InputRadio.module.css";
import { motion } from "framer-motion";

const InputRadio = ({ onSelect, selected, value, label,radioStyle, radioSelectedStyle,...props }) => {
  return (
    <div
      className={`${styles.container} ${props.className}`}
      onClick={onSelect}
    >
      <motion.div
        key={`${label}-${selected ? "selected" : "not"}`}
        initial={{
          borderColor: [
            "rgba(35, 35, 35, 0.9)",
            "rgba(35, 35, 35, 0.9)",
          ],
          transition: { times: [0.1, 0.9], duration: 1 },
        }}
        animate={{ borderColor: "rgba(35, 35, 35, 0.19)" }}
        transition={{ duration: 2 }}
        className={`${styles.radio} ${radioStyle}`}
      >
        {selected && (
          <motion.div
            initial={{ scale: 0.3 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            className={`${styles.selected} ${radioSelectedStyle}`}
          ></motion.div>
        )}
      </motion.div>
      <div className={`${styles.label} ${props.labelStyle}`}>{label}</div>
    </div>
  );
};

export default InputRadio;
