import React from "react";
import styles from "./PaymentStatus.module.css";
import charmTickIcon from "/assets/subscription/links/Charmtick.svg";
import bubbleIcon from "/assets/subscription/links/Bubbleicon.svg";

const PaymentStatus = ({ fileUploaded }) => {
    return (
        <div className={styles.design_center_cont}>
            <div className={styles.pay_design_cont}>
                <div className={styles.circle_with_label}>
                    <div className={`${styles.first_circle} ${fileUploaded ? styles.uploaded : styles.not_uploaded}`}>
                        <img
                            src={fileUploaded ? charmTickIcon : bubbleIcon}
                            className={fileUploaded ? styles.charm_tick : styles.bubble_size}
                            alt="Status Icon"
                        />
                    </div>
                    <div className={`${styles.label} ${fileUploaded ? styles.payment_label : ''}`}>Payment</div>
                </div>
                <div className={styles.hori_line_one}></div>
                <div className={styles.circle_with_label}>
                    <div className={styles.second_circle}>
                        <img src={bubbleIcon} className={styles.bubble_size} alt="Status Icon" />
                    </div>
                    <div className={styles.label}>Confirmation</div>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus;







