import { motion } from "framer-motion";
import styles from "./OverlayRegular.module.css";
import BackDrop from "./Backdrop";
import closeIcon from "/assets/Close.svg";

const OverlayRegular = ({
  children,
  className,
  blurValue = "0.4rem",
  keyName,
  onClose,
  showCloseIcon = true,
  ...props
}) => {
  const variants = {
    slidein: { opacity: 0, y: -500 },
    visible: { opacity: 1, y: 0 },
    slideout: { opacity: 0, y: -500 },
  };
  return (
    <BackDrop className={styles.backdrop} blurValue={blurValue}>
      <div
        key={keyName}
        // variants={variants}
        // initial="slidein"
        // animate="visible"
        // exit={variants.slideout}
        // transition={{ duration: 0.6, type: "spring" }}
        className={`${styles.overlay} ${className}`}
        {...props}
      >
        {showCloseIcon && (
          <button className={styles.btn__close} onClick={onClose}>
            <img src={closeIcon} alt="" />
          </button>
        )}
        {children}
      </div>
    </BackDrop>
  );
};

export default OverlayRegular;
