import { apiSlice } from "../../../store/api-slice";
import {
  METHOD_POST,
  URL_CREATE_PASSWORD,
  URL_CREATE_USER,
  URL_VERIFY_OTP,
} from "../../../consts/APIConstants";

export const signUpApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (email) => ({
        url: URL_CREATE_USER,
        method: METHOD_POST,
        body: { ...email },
      }),
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: URL_VERIFY_OTP,
        method: METHOD_POST,
        body: { ...data },
      }),
    }),
    createPassword: builder.mutation({
      query: (data) => ({
        url: URL_CREATE_PASSWORD,
        method: METHOD_POST,
        body: { ...data },
      }),
    }),
  }),
});

export const { useRegisterMutation, useVerifyOtpMutation, useCreatePasswordMutation } = signUpApiSlice;
