import {
  METHOD_GET,
  METHOD_POST,
  URL_GET_TOKEN,
  URL_LOGOUT,
  URL_SEND_USER_DETAILS,
} from "../consts/APIConstants";
import { apiSlice } from "./api-slice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: URL_GET_TOKEN,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: { ...credentials },
      }),
    }),
    sendUserDetails: builder.mutation({
      query: (userData) => ({
        url: URL_SEND_USER_DETAILS,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: { ...userData },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: URL_LOGOUT,
        method: METHOD_GET,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSendUserDetailsMutation,
  useLogoutMutation,
} = authApiSlice;
