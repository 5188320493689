import styles from "./DayView.module.css";
import { WEEK_DAYS } from "../../../consts/Constants";
import {
  getNumberOfDaysInAMonth,
  getWeekDayOfFirstDayOfAMonth,
  getNumberOfDaysInPreviousMonth,
} from "../../../utils/common-utils";
const DayView = ({
  month,
  year,
  day,
  currentDate,
  start,
  end,
  onSelectDay,
}) => {
  // const today = new Date();
  // const thisYear = today.getFullYear();
  // const thisMonth = today.getMonth() + 1;
  // const thisDay = today.getDate();

  const isStartMonth =
    start.getFullYear() == year && start.getMonth() + 1 == month;
  const isEndMonth = end.getFullYear() == year && end.getMonth() + 1 == month;

  const startDay = start.getDate();
  const endDay = end.getDate();

  const week = getWeekDayOfFirstDayOfAMonth(currentDate);
  const numberOfDaysInPrevMonth = getNumberOfDaysInPreviousMonth(month, year);
  const noOfDays = getNumberOfDaysInAMonth(month, year);

  const handleDayClick = (value) => {
    // if (year != thisYear || month != thisMonth || value < thisDay) {
      onSelectDay(value + 1);
    // }
  };

  return (
    <div className={styles.day_view}>
      {WEEK_DAYS.map((item, index) => (
        <div
          className={`${styles.day_item} ${styles.week_day}`}
          key={`${item}-${index}`}
        >
          {item}
        </div>
      ))}
      {[...Array(week).keys()].map((index) => (
        <button
          className={`${styles.day_item} ${styles.day_disabled}`}
          key={`prevmonth-${index}`}
          disabled={true}
        >
          {numberOfDaysInPrevMonth - week + index + 1}
        </button>
      ))}
      {[...Array(noOfDays).keys()].map((index) => (
        <button
          key={`$current-${index + 1}`}
          className={`${styles.day_item} ${
            index + 1 == day ? styles.day_selected : ""
          }`}
          disabled={
           ( isEndMonth && index + 1 > endDay) || (isStartMonth && index + 1 < startDay)
          }
          onClick={() => handleDayClick(index)}
        >
          {index + 1}
        </button>
      ))}
      {[...Array(42 - week - noOfDays).keys()].map((index) => (
        <button
          key={`nextmonth-${index}`}
          className={`${styles.day_item} ${styles.day_disabled}`}
          disabled={true}
        >
          {index + 1}
        </button>
      ))}
    </div>
  );
};

export default DayView;
