import styles from "./FinancePageOverview.module.css";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import overviewImg from "/assets/finance/OverviewFinance.svg";
import quickBooksIcon from "/assets/finance/QuickBooks.svg";
import zohoBooksIcon from "/assets/finance/ZohoBooks.svg";
import { TEXT__BOOK_KEEPING_TAG_LINE } from "../../../consts/TextConstants";
import LogoBox from "../../UI/LogoBox";
import { useState } from "react";
import BookKeepingLinkOverlay from "./BookKeepingLinkOverlay";
import Button from "../../UI/Button/Button";

const FinancePageOverview = () => {
  const [showLinkPopup, setShowLinkPopup] = useState(false);

  const handleCloseLinkPopup = () => {
    setShowLinkPopup(false);
  };

  const handleZohoClick = () => {
    setShowLinkPopup(true);
  };

  return (
    <>
      {showLinkPopup && (
        <BookKeepingLinkOverlay onClose={handleCloseLinkPopup} />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <LogoBox className={styles.logo} />

          <div className={styles.tagline}>{TEXT__BOOK_KEEPING_TAG_LINE}</div>
          <div className={styles.container_bookkeeping_options}>
            {/* <button className={styles.btn__bookkeeping_option}>
              <img src={quickBooksIcon} alt="" />
            </Button> */}
            <Button
              className={styles.btn__bookkeeping_option}
              onClick={handleZohoClick}
            >
              <img src={zohoBooksIcon} alt="" />
            </Button>
          </div>
          <div className={styles.description}>
            <p>Can’t find what you are looking for? </p>
            <p>
              We are working to provide more Book keeping integration options
            </p>
          </div>
          {/* <ButtonCustom className={styles.btn__skip}>Skip</ButtonCustom> */}
        </div>
        <img src={overviewImg} alt="" />
      </div>
    </>
  );
};

export default FinancePageOverview;
