import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useOutsideClick } from "../../../../hooks/use-outsideClick";
import { isNotEmpty } from "../../../../utils/validations";
import styles from "./TRCBoardAutoComplete.module.css";
import InputCustom from "../../../UI/Input/InputCustom";

function getFilteredSuggestions(suggestions, filterVal) {
  return suggestions.filter((item) =>
    item.name.toLowerCase().startsWith(filterVal.toLowerCase())
  );
}

const TRCBoardAutoComplete = ({
  id,
  defaultValue = "",
  showerror = false,
  errormessage,
  inputStyle,
  onSelect,
  canAddNewOfficer = false,
  ...props
}) => {
  const ref = useRef();

  const outsideClickHandler = () => {
    if (selectedItem !== inputValue) {
      if (canAddNewOfficer) {
        onSelect({ name: inputValue });
      } else {
        setSelectedItem("");
        setInputValue("");
        onSelect({});
      }
    }
  };

  useOutsideClick({
    ref,
    handler: () => {
      outsideClickHandler();
      setShowAutoComplete(false);
    },
  });

  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { boardOfficers } = useSelector((state) => state.entity);

  const suggestions = useMemo(
    () =>
      boardOfficers.map(({ name, title, phoneNumber }) => ({
        name,
        title,
        phoneNumber,
      })),
    [boardOfficers]
  );

  useEffect(() => {
    if (defaultValue !== inputValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const updateFilteredList = useCallback(
    (value) => {
      const filteredArray = getFilteredSuggestions(suggestions, value);
      setFilteredList(filteredArray);
      setSelectedIndex(0);
      setShowAutoComplete(filteredArray.length > 0);
    },
    [suggestions]
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    updateFilteredList(value);
  };

  const handleFocus = () => {
    updateFilteredList(inputValue);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (filteredList.length === 0) {
        outsideClickHandler();
      }
      setShowAutoComplete(false);
    }, 200);
  };

  const handleSelect = (index) => {
    setInputValue(`${filteredList[index].name}`);
    setSelectedItem(`${filteredList[index].name}`);
    onSelect(filteredList[index]);
    setShowAutoComplete(false);
  };

  const handleInputKeyDown = (event) => {
    if (showAutoComplete) {
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : filteredList.length - 1
          );
          break;
        case "ArrowDown":
          event.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex < filteredList.length - 1 ? prevIndex + 1 : 0
          );
          break;
        case "Enter":
          event.preventDefault();
          handleSelect(selectedIndex);
          break;
        case "Escape":
          setShowAutoComplete(false);
          break;
      }
    }
  };

  return (
    <div className={`${styles.autoCompleteContainer} ${inputStyle}`}>
      <InputCustom
        id={id}
        onChange={handleChange}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleInputKeyDown}
        errormessage={errormessage}
        className={`${props.autocompleteSize}`}
      />
      {showAutoComplete && (
        <div
          className={`${styles.autoCompleteListContainer} ${styles.bottom}`}
          ref={ref}
        >
          <ul className={`${styles.autoCompleteList} ${props.listSize}`}>
            {filteredList.map((item, index) => (
              <li
                key={`${index}_${item.name}`}
                className={`${index == selectedIndex ? styles.selected : ""} ${
                  styles.autoCompleteListItem
                }`}
                onClick={() => handleSelect(index)}
              >
                {item.name} <span>({item.title})</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TRCBoardAutoComplete;
