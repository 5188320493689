import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ComplianceDetails.module.css";
import { navigaionActions } from "../../MainNavigation/reducers/navigation-slice";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import {
  BTN__ENTITY_DATA,
  BTN__SUBSIDIARY_DATA,
  LBL__YEAR,
  NOTE__COMPLETED_DATA_VERIFICATION,
  NOTE__PENDING_DATA_VERIFICATION,
  MSG__COMPLIANCE_UPLOAD_DOCUMENTS
} from "../../../consts/TextConstants.jsx";
import uploadIcon from "/assets/common/Upload.svg";
import YearDropdown from "../../UI/Dropdown/YearDropDown.jsx";
import { getYearFromDateString } from "../../../utils/common-utils";
import StatusRepresentationBox from "../../UI/StatusRepresentationBox.jsx";
import DotsLoadingIndicator from "../../UI/LoadingIndicator/DotsLoadingIndicator.jsx";
import ComplianceList from "./ComplianceList.jsx";

const ComplianceDetails = () => {
  const currentYear = new Date().getFullYear();
  const [forYear, setForYear] = useState(currentYear);
  const { primaryEntity } = useSelector((state) => state.entity);

  const { isEntityDataVerified, isSubsidiaryDataVerified } = useSelector(
    (state) => state.compliance
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(navigaionActions.setNavigationTitle("Compliance"));
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.top_section}>
      <header className={styles.header}>
        <div className={styles.upload_section}>
          <span>
            {isEntityDataVerified && isSubsidiaryDataVerified
              ? NOTE__COMPLETED_DATA_VERIFICATION
              : NOTE__PENDING_DATA_VERIFICATION}
          </span>
          <div className={styles.upload_actions}>
            <ButtonCustom
              className={`${!isEntityDataVerified ? styles.btn__pending : ""}`}
            >
              <img src={uploadIcon} alt="" />
              {BTN__ENTITY_DATA}
            </ButtonCustom>
            <ButtonCustom
              className={`${
                !isSubsidiaryDataVerified ? styles.btn__pending : ""
              }`}
            >
              <img src={uploadIcon} alt="" />
              {BTN__SUBSIDIARY_DATA}
            </ButtonCustom>
          </div>
        </div>
        <div className={styles.year_section}>
          <span>{LBL__YEAR}</span>
          <YearDropdown
            placeholder=""
            start={getYearFromDateString(primaryEntity?.incorporationDate)}
            end={currentYear}
            selected={forYear}
            onSelect={setForYear}
            dropdownSize={styles.year_dropdown}
            showMonth={false}
          />
        </div>
      </header>
      <div className={styles.content}>
          <StatusRepresentationBox className={styles.payment_status} status="blue">TO QUOTE <DotsLoadingIndicator /></StatusRepresentationBox>
          <ComplianceList/>
      </div>
      </div>
      <footer className={styles.footer}>
        <span>{MSG__COMPLIANCE_UPLOAD_DOCUMENTS}</span>
      </footer>
    </div>
  );
};

export default ComplianceDetails;
