import { useEffect, useState } from "react";
import styles from "./DotsLoadingIndicator.module.css";
import { motion } from "framer-motion";
import { getNumberOfDaysInAMonth } from "../../../utils/common-utils";

const DotsLoadingIndicator = ({ className }) => {
  const [active, setActive] = useState(0);
  const numberOfDots = 3;

  useEffect(() => {
    const interval = setInterval(() => {
        setActive(prev => prev == numberOfDots - 1 ? 0 : prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${styles.container} ${className}`}>
      {[...Array(numberOfDots).keys()].map((index) => (
        <div
          key={`loading-indicator-dot-${index}`}
          className={`${styles.loader} ${index == active ? styles.active : ""}`}
        ></div>
      ))}
    </div>
  );
};

export default DotsLoadingIndicator;
