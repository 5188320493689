import React from "react";
import styles from "./ActionRenderer.module.css";
import { serviceActionTypes } from "./consts/ServiceConstants";
import MoreInfo from "../../UI/General/MoreInfo";
import Button from "../../UI/Button/Button";
const ActionRenderer = ({
  action: mainAction,
  onUpload,
  onDownload,
  onButtonClick,
  onConfirmation,
  serviceItem,
}) => {
  const getActionComponent = (action, index = null) => {
    switch (action.type) {
      case serviceActionTypes.FORM:
        return (
          <div key={`${index}-${action.type}-${serviceItem.taskId}`}>
            <Button
              onClick={onButtonClick}
              buttonstyle="yellow-filled"
              className={`${styles.action_form}`}
            >
              <span className={""}>{action.label}</span>
              <img src={action.icon} alt="Form Icon" className={styles.icon} />
            </Button>
          </div>
        );
        `   `;
      case serviceActionTypes.MESSAGE:
        const messageComponent = (
          <div key={`${index}-${action.type}-${serviceItem.taskId}`}>
            <p
              className={`${styles.action_message} ${
                styles[action?.actionItemStyle]
              }`}
            >
              {action.label}
            </p>
            {action.additionalInfo && (
              <p className={styles.additional_info}>{action.additionalInfo}</p>
            )}
          </div>
        );
        if (action.showMoreInfo) {
          return (
            <MoreInfo
              key={`${index}-${action.type}-moreinfo-${action.moreInfoContent}`}
              content={action.moreInfoContent}
              position="top"
              showMoreInfoIcon={false}
              customContainerComponent={true}
            >
              {messageComponent}
            </MoreInfo>
          );
        }
        return messageComponent;

      case serviceActionTypes.DOWNLOAD:
        return (
          <Button
            className={`${styles.action_download} ${
              styles[action.actionItemStyle]
            }`}
            onClick={onDownload}
            key={`${index}-${action.type}-${serviceItem.taskId}`}
          >
            <img
              src={action.icon}
              alt="Download"
              className={styles.downloadIcon}
            />
            <span>{action.label}</span>
          </Button>
        );

      case serviceActionTypes.UPLOAD:
        return (
          <div
            className={styles.uploadContainer}
            key={`${index}-${action.type}-${serviceItem.taskId}`}
          >
            <Button
              onClick={onUpload}
              buttonstyle="yellow-filled"
              className={`${styles.action_upload}`}
            >
              <img
                src={action.icon}
                alt="Upload"
                className={styles.uploadIcon}
              />
              <span>{action.label}</span>
            </Button>
          </div>
        );

      case serviceActionTypes.CONFIRMATION:
        return (
          <div
            className={styles.conformation_cont}
            key={`${index}-${action.type}-${serviceItem.taskId}`}
          >
            <p>{action.label}</p>
            <Button
              className={`${styles.action_confirmation} ${
                styles[action.actionItemStyle]
              }`}
              onClick={onConfirmation}
            >
              {action.icon && <img src={action.icon} alt="" />}{" "}
              {action.confiramtionLabel}
            </Button>
          </div>
        );

      case serviceActionTypes.MULTIPLE:
        return (
          <div
            className={styles.multipleActionsContainer}
            key={`${index}-${action.type}-${serviceItem?.taskId}`}
          >
            {action.actions.map((subAction, idx) => {
              return getActionComponent(subAction, idx);
            })}
          </div>
        );

      case serviceActionTypes.MOREINFO:
        return (
          <MoreInfo
            content={action.label}
            position="left"
            className={styles.moreInfo}
            descriptionstyle={styles.moreInfoDescription}
          />
        );

      default:
        return null;
    }
  };

  return getActionComponent(mainAction);
};

export default ActionRenderer;
