import { useState } from "react";
import styles from "./AddNewServiceOverlay.module.css";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import ServiceList from "./ServiceList";
import ServicesAdded from "./ServicesAdded";
import Payments from "../PaymentsFolder/Payments";
import { useGetServicesQuery } from "../apis/serviceApi-slice";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";

const AddNewServiceOverlay = ({ onClose, onSuccess }) => {
  const [showServiceOverView, setShowServiceOverView] = useState(false);
  const [showPayments, setShowPayments] = useState(false);
  const [showAddServiceForm, setShowAddServiceForm] = useState(true);

  const [servicesAdded, setServicesAdded] = useState({});
  const [subscriptionId, setSubscriptionId] = useState(null);

  const { isLoading, isSuccess, isError } = useGetServicesQuery();

  // Handles the 'Add' button click and stores the added service data
  const handleAddClick = (data) => {
    setShowAddServiceForm(false);
    setServicesAdded(data);
    setShowServiceOverView(true);
  };

  // When subscriptionId is set, send it to parent (ServicesPage)
  const handleSubscriptionSuccess = (id) => {
    setShowServiceOverView(false);
    setShowPayments(true);
    setSubscriptionId(id);
  };

  const handleGetQuoteSuccess = () => {
    setShowServiceOverView(false);
    onSuccess();
    onClose();
  };

  const handleBack = () => {
    setShowServiceOverView(false);
    setShowAddServiceForm(true);
  };

  const handlePaymentClose = () => {
    setShowPayments(false);
    onSuccess();
    onClose();
  };

  if (isLoading) return <LoadingComponent />;

  if (isError)
    return (
      <ErrorComponent
        errorMessage={"Unable to fetch the services. Please try again."}
      />
    );

  if (isSuccess)
    return (
      <OverlayRegular onClose={onClose}>
        <div className={styles.service_modal}>
          {showAddServiceForm && (
            <ServiceList
              onClose={onClose}
              onAdd={handleAddClick}
              onAddQuoteSuccess={handleGetQuoteSuccess}
            />
          )}
          {showServiceOverView && (
            <ServicesAdded
              selectedService={servicesAdded}
              onClose={onClose}
              onBack={handleBack}
              onSuccess={handleSubscriptionSuccess} // Pass the callback to handle subscription success
            />
          )}
          {showPayments && (
            <Payments
              onClose={handlePaymentClose}
              onSuccess={handlePaymentClose}
              selectedService={servicesAdded}
              subscriptionId={subscriptionId}
            />
          )}
        </div>
      </OverlayRegular>
    );
};

export default AddNewServiceOverlay;
