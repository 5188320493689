import { useParams } from "react-router-dom";
import EntityDetailsPage from "../components/Main/Entities/EnityDetails/EntityDetailsPage";

const EntityDetails = () => {
  const { id } = useParams();

  return <EntityDetailsPage entityId={id} />;
};

export default EntityDetails;
