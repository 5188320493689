import styles from "./LoadingComponent.module.css";
import LoadingSpinner from "./LoadingSpinner";
import LoadingDocument from "./LoadingDocument";

const LoadingComponent = ({ type = "default", className }) => {
  if (type === "data")
    return (
      <LoadingDocument
        className={`${styles.loading_component} ${styles.loading_document} ${className}`}
      />
    );

  return (
    <LoadingSpinner className={`${styles.loading_component} ${className}`} />
  );
};

export default LoadingComponent;
