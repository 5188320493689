
import { tabStyles } from "../../../consts/Constants";
import styles from "./TabListItemRounded.module.css";

const TabListItemRounded = ({ children, activeTab, tabStyle, lastChild=false, ...props }) => {
  return (
    tabStyle = tabStyles.ROUND_OUT_BORDER ?
    <li
      className={`${styles.tab} ${activeTab ? styles.active : ""} ${lastChild ? styles.lastChild : ""}`}
      {...props}
    >
      <div>
        <span>{children}</span>
      </div>
    </li>
    : null
  );
};

export default TabListItemRounded;
