import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useInput from "../../hooks/use-input";
import styles from "./OnboardingCollectEnityDetails.module.css";
import TreeViewItem from "./TreeView/TreeViewItem";
import InputCustom from "../UI/Input/InputCustom";
import Dropdown from "../UI/Dropdown/Dropdown";
import InputRadio from "../UI/Input/InputRadio";
import {
  LBL__BRAND_NAME,
  LBL__ENTITY_NAME,
  LBL__ENTITY_STRUCTURE,
  LBL__NO,
  LBL__STANDALONE_RADIO,
  LBL__YES,
} from "../../consts/TextConstants";
import { entityStructures } from "../../consts/Constants";
import { isNotEmpty } from "../../utils/validations";

const OnboardingCollectEnityDetails = ({ ...props }) => {
  const { collectedData } = useSelector((state) => state.onboard);
  const [entityStructure, setEntityStructure] = useState(
    collectedData?.entityStructure || ""
  );
  const [standaloneValue, setStandaloneValue] = useState(
    collectedData?.standalone || LBL__NO
  );

  const {
    value: brandName,
    isValid: isBrandNameValid,
    showError: showBrandNameError,
    handleChange: handleBrandNameChange,
    handleBlur: handleBrandNameBlur,
    handleFocus: handleBrandNameFocus,
  } = useInput(isNotEmpty, collectedData?.brandName);

  const {
    value: entityName,
    isValid: isEntityNameValid,
    showError: showEntityNameError,
    handleChange: handleEntityNameChange,
    handleBlur: handleEntityNameBlur,
    handleFocus: handleEntityNameFocus,
  } = useInput(isNotEmpty, collectedData?.entityName);

  useEffect(() => {
    props.onChange(
      {
        entityName,
        brandName,
        // entityStructure,
        // standalone: entityStructure == "Standalone" ? standaloneValue : null,
      },
      isBrandNameValid && isEntityNameValid
      // && entityStructure
    );
  }, [
    entityName,
    brandName,
    // entityStructure, standaloneValue
  ]);

  const handleEntityStructureChange = (value) => {
    setEntityStructure(value);
  };

  const handleStandaloneSelect = (value) => {
    setStandaloneValue(value);
  };

  return (
    <>
      <TreeViewItem labelTitle={LBL__BRAND_NAME}>
        <InputCustom
          className={styles.input}
          value={brandName}
          onChange={handleBrandNameChange}
          onBlur={handleBrandNameBlur}
          onFocus={handleBrandNameFocus}
        />
      </TreeViewItem>

      <TreeViewItem labelTitle={LBL__ENTITY_NAME} lastItem={true}>
        <InputCustom
          className={styles.input}
          value={entityName}
          onChange={handleEntityNameChange}
          onBlur={handleEntityNameBlur}
          onFocus={handleEntityNameFocus}
        />
      </TreeViewItem>

      {/* <TreeViewItem labelTitle={LBL__ENTITY_STRUCTURE} lastItem={true}>
        <Dropdown
          placeholder=""
          options={Object.values(entityStructures)}
          selected={entityStructure}
          onSelect={handleEntityStructureChange}
          id="entity_structure"
          dropdownSize={styles.dropdown}
        />
        {entityStructure == "Standalone" && (
          <div className={styles.standalone}>
            <div className={styles.standalone_question}>
              {LBL__STANDALONE_RADIO}
            </div>
            <div className={styles.radio_options}>
              <InputRadio
                selected={standaloneValue == LBL__YES}
                label={LBL__YES}
                onSelect={() => handleStandaloneSelect(LBL__YES)}
              />

              <InputRadio
                selected={standaloneValue == LBL__NO}
                label={LBL__NO}
                onSelect={() => handleStandaloneSelect(LBL__NO)}
              />
            </div>
          </div>
        )}
      </TreeViewItem> */}
    </>
  );
};

export default OnboardingCollectEnityDetails;
