import { capTableStakes } from "../../../consts/Constants";
import {
  TBL__SAFES,
  TBL__STOCK_OPTION_POOLS,
  TBL__CAP_TABLE_SHARES,
} from "../../../consts/TextConstants";
import Table from "../../UI/Table/Table";
import ShareHolderList from "./ShareHolderList";

const getTableHeaderList = (stakeType) => {
  if (stakeType == capTableStakes.SAFES.key) {
    return TBL__SAFES;
  } else if (stakeType == capTableStakes.STOCK_OPTION_POOLS.key) {
    return TBL__STOCK_OPTION_POOLS;
  }
  return TBL__CAP_TABLE_SHARES;
};

const CapTableTabContent = ({
  activeTab,
  tableData,
  onDelete,
  className,
  ...props
}) => {
  if (activeTab == capTableStakes.SHARES.key) {
    return (
      <ShareHolderList
        shareHolders={tableData}
        onDelete={onDelete}
        {...props}
      />
    );
  }
  return (
    <Table
      id="table_shares"
      name={activeTab}
      className={className}
      tableHeaders={getTableHeaderList(activeTab)}
      minRows={0}
      enableDelete={true}
      onDelete={onDelete}
      showEmptyRowSeparator={false}
      tableData={tableData}
    />
  );
};

export default CapTableTabContent;
