import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import styles from "./DashboardContent.module.css";
import Header from "./DashboardHeader.jsx";
import OngoingTasks from "./OngoingTasks.jsx";
import CompleteTasks from "./CompleteTasks.jsx";
import RecentActivity from "./FooterCont.jsx";
import dashboardImg from "/assets/dashboard/Temp_Upcoming_deadlines.svg";
import DashboardLoading from "./DashboardLoading.jsx";

const DashboardContent = () => {
  const { primaryEntity } = useSelector((state) => state.entity);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.container}>
      {isLoading && <DashboardLoading />}

      {!isLoading && (
        <div className={styles.dashboard_cont}>
          <Header />
          <div className={styles.middle_cont}>
            {/* <OngoingTasks />
            <CompleteTasks /> */}
            <img src={dashboardImg} alt="" />
          </div>
          {/* <div className={styles.footer_cont}>
            <RecentActivity />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default DashboardContent;
