import { createSlice } from "@reduxjs/toolkit";
import { removeAuthToken } from "../utils/authentication";
import { flowTypes } from "../consts/Constants";

const initialState = {
  showCreatePassword: false,
  currentFlow: flowTypes.SIGN_IN,
  showUserDetails: false,
  showOTP: false,
  user: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setShowOTPForm(state, action) {
      state.showOTP = action.payload;
    },
    setShowCreatePassword(state, action) {
      state.showCreatePassword = action.payload;
    },
    setCurrentFlow(state, action) {
      state.currentFlow = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setShowUserDetailsForm(state, action) {
      state.showUserDetails = action.payload;
    }
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
