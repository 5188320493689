import { useEffect, useRef, useState } from "react";
import styles from "./DropDownContainer.module.css";
import { isNotEmpty } from "../../../utils/validations";
import DropdownHeader from "./DropdownHeader";
import DropdownList from "./DropdownList";
import { AnimatePresence } from "framer-motion";

const DropDownContainer = ({ children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const ref = useRef(null);

  const toggleDropdown = () => setIsOpen((currState) => !currState);

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
        if (!isNotEmpty(props.selected)) {
          setShowError(true);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleSelect = (option) => {
    toggleDropdown();
    props.onSelect(option);
    setShowError(false);
  };

  return (
    <div
      className={`${styles.dropDownContainer} ${props.className}`}
      ref={ref}
      id={props.id}
    >
      <DropdownHeader
        onClick={toggleDropdown}
        arrow={props.arrow}
        selected={props.selected}
        placeholder={props.placeholder}
        isOpen={isOpen}
        className={props.headerStyle}
        arrowStyle={props.arrowStyle}
        disabled={props.disabled}
      ></DropdownHeader>
      <AnimatePresence>
        {isOpen && (
          <DropdownList
            className={`${styles.dropDownList} ${props.listStyle}`}
            onSelect={handleSelect}
            options={props.options}
            listItemStyle={props.listItemStyle}
            showListOnTop={props.showListOnTop}
          />
        )}
      </AnimatePresence>

      {showError && (
        <div className={styles.invalid}>{props.errormessage || ""}</div>
      )}
    </div>
  );
};

export default DropDownContainer;
