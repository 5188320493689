import { useState } from "react";
import styles from "./PhoneInputCountryDropdown.module.css";
import {
  FlagEmoji,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

const PhoneInputCountryDropdown = ({ onSelect, ...props }) => {
  const initialDefaultList = defaultCountries.filter(
    (item) => item[1] == "us" || item[1] == "in"
  );
  const initialOthersList = defaultCountries.filter(
    (item) => item[1] != "us" && item[1] != "in"
  );

  const initialList = [...initialDefaultList, ...initialOthersList];
  const [countryList, setCountryList] = useState(initialList);

  const handleChangeSearchInput = (event) => {
    const newList = initialList.filter(
      (item) =>
        item[0].toLowerCase().startsWith(event.target.value.toLowerCase()) ||
        item[2].startsWith(event.target.value)
    );
    setCountryList(newList);
  };
  return (
    <div>
      <div className={styles.dropdown_container}>
        <input
          className={styles.input__search}
          type="text"
          placeholder="Search"
          onChange={handleChangeSearchInput}
        />
        {countryList.map((c) => {
          const country = parseCountry(c);
          return (
            <div
              className={styles.menu_item}
              key={country.iso2}
              value={country.iso2}
              onClick={() => onSelect(country.iso2)}
            >
              <FlagEmoji iso2={country.iso2} className={styles.img_flag} />
              <span>{country.name}</span>
              <span>+{country.dialCode}</span>
            </div>
          );
        })}
        {countryList.length == 0 && <div className={styles.empty_message}>No entries to show</div>}
      </div>
    </div>
  );
};

export default PhoneInputCountryDropdown;
