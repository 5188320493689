import { useSelector } from "react-redux";
import styles from "./SignInUpLandingPage.module.css";
import SignInUpPage from "./SignInUpPage";
import RegisterUserDetails from "./RegisterUserDetails";
import OTPVerificationForm from "./OTP/OTPVerificationForm.jsx";
import LogoBox from "../UI/LogoBox.jsx";


const SignInUpLandingPage = ({ isSignUp = false }) => {
  const { showOTP, showUserDetails } = useSelector((state) => state.login);
  const title = isSignUp || showUserDetails ? "Create Account" : "Login";

  return (
    <div className={styles.main}>
      <LogoBox />
      <div className={styles.page_title}>{title}</div>
      {(!showOTP && !showUserDetails) && <SignInUpPage />}
      {showOTP && <OTPVerificationForm  isSignUp={isSignUp}/>}
      {showUserDetails  && <RegisterUserDetails />}
    </div>
  );
};

export default SignInUpLandingPage;
