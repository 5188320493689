import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 1,
  collectedData: null,
  stepCompletionStatus: [false, false, false],
};

const onboardSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setCollectedData(state, action) {
      state.collectedData = { ...state.collectedData, ...action.payload };
    },
    resetCollectedData(state) {
      state.collectedData = null;
    },
    updateStepCompletionStatus(state, action) {
      state.stepCompletionStatus[action.payload.step] = action.payload.status;
    },
  },
});

export const onboardActions = onboardSlice.actions;

export default onboardSlice.reducer;
