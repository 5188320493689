import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    navigationTitle: ""
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setNavigationTitle(state, action) {
            state.navigationTitle = action.payload;
        }
    }
});

export const navigaionActions = navigationSlice.actions;

export default navigationSlice.reducer;