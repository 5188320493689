
// currencyConverter.jsx

// Function to fetch exchange rates for INR and USD
export const getExchangeRatesForINRAndUSD = async () => {
    try {
        const response = await fetch("https://open.er-api.com/v6/latest/USD");
        const data = await response.json();
        return data.rates.INR; // Return INR rate
    } catch (error) {
        console.error("Error fetching exchange rate:", error);
        return null; // Return null in case of an error
    }
};
