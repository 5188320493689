import styles from "./TreeViewItem.module.css";

const TreeViewItem = ({ children, labelTitle, lastItem = false, ...props }) => {
  return (
    <div className={`${styles.inner_item} ${lastItem ? styles.last_item_container : ""} ${props.className}`}>
      <label className={`${styles.label} ${lastItem ? styles.last_item : ""}`}>
        {labelTitle}
      </label>
      {children}
    </div>
  );
};

export default TreeViewItem;
