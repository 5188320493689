import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptionPrice: 1791,
    packageFeatures: ["Form 1099", "Form 1120", "Form 5472", "State Franchise Tax", "Extension Filing"],
};
const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        
    }
});

export const subscriptionActions = subscriptionSlice.actions;

export default subscriptionSlice.reducer;