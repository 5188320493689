import styles from "./FileInput.module.css";
import { useRef, useState } from "react";
import attachIcon from "/assets/common/File_Gray.svg";
import attachIconBlue from "/assets/common/File_Blue.svg";
import { getFullFileName } from "../../../utils/common-utils";

const FileInput = ({ placeHolder, defaultFile = null, acceptFileTypes = ".pdf, .xls, .xlsx", ...props }) => {
  const fileRef = useRef();
  const [selectedFile, setSelectedFile] = useState(defaultFile);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    props.onChange(event.target.files[0]);
  };

  const handleFileClick = () => {
    fileRef.current.click();
  };
  return (
    <>
      <button
        className={`${styles.btn__attach_file} ${
          selectedFile ? styles.btn__selected : ""
        }`}
        onClick={handleFileClick}
      >
        <span>
          <img src={selectedFile ? attachIconBlue : attachIcon} alt="" />
          {selectedFile ? getFullFileName(selectedFile.name) : placeHolder}
        </span>
      </button>
      <input
        className={styles.input__file}
        ref={fileRef}
        type="file"
        onChange={handleFileChange}
        accept={acceptFileTypes}
      />
    </>
  );
};

export default FileInput;
