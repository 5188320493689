
export const TERMS_AND_CONDITIONS = () => {
    return (
        <>
            {/* <h1 style={{ color: '#2c3e50', marginBottom: '2rem' }}>Terms and Conditions</h1> */}

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>1. Acceptance of Terms</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                By accessing and using Merze, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use the Website.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>2. Use of the Website</h2>
            <ul style={{ listStyleType: 'disc', marginBottom: '4rem', marginTop: '2rem', marginLeft: '3rem' }}>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Eligibility : </strong> You must be 18 years old to use this website. By using the Website, you represent and warrant that you are of legal age to form a binding contract.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>User Conduct:</strong> You agree to use the Website only for lawful purposes. You shall not use the Website in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party’s use and enjoyment.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Content:</strong> The content provided on this Website is for informational purposes only and should not be construed as professional advice. Merze makes no representations or warranties regarding the accuracy or completeness of any information provided on the Website.
                </li>
            </ul>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>3. Intellectual Property Rights</h2>
            <ul style={{ listStyleType: 'disc', marginLeft: '3rem', marginBottom: '4rem', marginTop: '2rem' }}>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Ownership:</strong> All content, trademarks, logos, and other intellectual property displayed on the Website are the property of Merze or its licensors. Unauthorized use of any content on the Website is strictly prohibited.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Limited License:</strong> You are granted a limited, non-exclusive, non-transferable license to access and use the Website and its content for personal, non-commercial use only. Any other use of the Website and its content without prior written consent from Merze is prohibited.
                </li>
            </ul>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>4. Third-Party Links</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                The Website may contain links to third-party websites or services not owned or controlled by Merze. Merze has no control over and assumes no responsibility for third-party websites or services' content, privacy policies, or practices. You acknowledge and agree that Merze shall not be responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with the use of any such content, goods, or services available on or through any such websites or services.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>5. Disclaimer of Warranties</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                The Website is provided on an “as-is” and “as-available” basis. Merze makes no warranties, expressed or implied, regarding the Website, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Merze does not warrant the Website being uninterrupted, error-free, or secure.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>6. Limitation of Liability</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginTop: '2rem' }}>To the fullest extent permitted by law, Merze shall not be liable for any indirect, incidental, special,
                consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or
                indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from: </p>
            <ul style={{ listStyleType: 'disc', marginLeft: '3rem', color: '#333', marginBottom: '4rem', marginTop: '2rem' }}>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    Your use or inability to use the Website.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8' }}>
                    Any unauthorized access to or use of our servers and any personal information stored therein.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8' }}>
                    Any interruption or cessation of transmission to or from the Website.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8' }}>
                    Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Website by any third party.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8' }}>
                    Any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the Website.
                </li>
            </ul>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>7. Indemnification</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                You agree to indemnify, defend, and hold harmless Merze, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Website, or your violation of these Terms and Conditions.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>8. Privacy Policy</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                Your website use is also governed by our <span style={{ fontSize: '1.6rem', color: '#000000', fontFamily: 'SpartanSemiBold' }}>Privacy Policy</span>, which explains how we collect, use, and protect your personal information. Using the Website, you consent to collecting and using your information as outlined in the Privacy Policy.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>9. Changes to Terms</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                Merze reserves the right, at its sole discretion, to modify or replace these Terms and Conditions at any time. It is your responsibility to review these terms periodically for any changes. Your continued use of the Website after posting any changes indicates your acceptance. If you disagree with the new terms, stop using the Website.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>10. Termination</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                Merze may terminate or suspend your access to the Website, with or without notice, for any reason, including, without limitation, breach of these Terms and Conditions. Upon termination, your right to use the Website will immediately cease. All provisions of the Terms and Conditions that, by their nature, should survive termination shall survive, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>11. Governing Law and Dispute Resolution</h2>
            <ul style={{ listStyleType: 'disc', marginLeft: '3rem', color: '#333', marginBottom: '4rem', marginTop: '2rem' }}>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Governing Law:</strong> These Terms are governed by and constructed by the laws of the State of Karnataka, India, without regard to its conflict-of-law principles.
                </li>
                <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8' }}>
                    <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Dispute Resolution:</strong> Any disputes arising out of or relating to these Terms or the use of the Site will be resolved through binding arbitration by the rules of the American Arbitration Association. The arbitration will take place in Bangalore, Karnataka, India, and the arbitrator’s decision will be final and binding.
                </li>
            </ul>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>12. Severability</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                If any provision of these Terms and Conditions is found invalid or unenforceable by a court of
                competent jurisdiction, the remaining provisions shall continue in full force and effect. The invalid or
                unenforceable provision will be modified to be valid and enforceable to the maximum extent law
                permits.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>13. Entire Agreement</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                These Terms and Conditions, along with the Privacy Policy and any other legal notices published by
                Merze on the Website, constitute the entire agreement between you and Merze concerning the
                Website. They supersede all prior or contemporaneous electronic, oral, or written communications
                concerning the Website between you and Merze.
            </p>

            <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>14. Contact Information</h2>
            <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
                If you have any questions or concerns about these Terms and Conditions, please contact us at:

            </p>

            <strong style={{ display: 'block', fontFamily: "SpartanSemiBold", fontSize: '1.6rem', marginBottom: '4rem' }}>
                Email: <a href="mailto:support@merze.io" style={{ color: '#3498db' }}>hello@merze.io</a>
            </strong>
        </>
    );
};



// export const TERMS_AND_CONDITIONS = () => {
//     return (
//         <>
//             <h1 style={{ color: '#2c3e50', marginBottom: '2rem' }}>Terms and Conditions</h1>

//             <h2 style={{ color: '#2c3e50' }}>1. Acceptance of Terms</h2>

//             <p style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '20px', color: '#333' }}>
//                 By accessing and using Merze, you agree to be bound by these Terms and Conditions. If you do not
//                 agree with any part of these terms, you must not use the Website.
//             </p>

//             <h2 style={{ color: '#2c3e50' }}>2. Use of the Website</h2>
//             <ul style={{ listStyleType: 'disc', marginLeft: '20px', color: '#333', marginBottom: '1rem' }}>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     <strong>Eligibility:</strong> You must be 18 years old to use this website. By using the Website, you represent
//                     and warrant that you are of legal age to form a binding contract.
//                 </li>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     <strong>User Conduct:</strong> You agree to use the Website only for lawful purposes. You shall not use the
//                     Website in any manner that could damage, disable, overburden, or impair the Website or interfere with
//                     any other party’s use and enjoyment.
//                 </li>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     <strong>Content:</strong> The content provided on this Website is for informational purposes only and should
//                     not be construed as professional advice. Merze makes no representations or warranties regarding the
//                     accuracy or completeness of any information provided on the Website.
//                 </li>
//             </ul>

//             <h2 style={{ color: '#2c3e50' }}>3. Intellectual Property Rights</h2>
//             <ul style={{ listStyleType: 'disc', marginLeft: '20px', color: '#333', marginBottom: '1rem' }}>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     <strong>Ownership:</strong> All content, trademarks, logos, and other intellectual property displayed on the
//                     Website are the property of Merze or its licensors. Unauthorized use of any content on the Website is
//                     strictly prohibited.
//                 </li>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     <strong>Limited License:</strong> You are granted a limited, non-exclusive, non-transferable license to access
//                     and use the Website and its content for personal, non-commercial use only. Any other use of the Website
//                     and its content without prior written consent from Merze is prohibited.
//                 </li>
//             </ul>

//             <h2 style={{ color: '#2c3e50' }}>4. Third-Party Links</h2>
//             <p style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', margin: '20px', color: '#333' }}>
//                 The Website may contain links to third-party websites or services not owned or controlled by Merze. Merze has no
//                 control over and assumes no responsibility for third-party websites or services' content, privacy policies, or practices.
//                 You acknowledge and agree that Merze shall not be responsible or liable, directly or indirectly, for any damage or
//                 loss caused by or in connection with the use of any such content, goods, or services available on or through any
//                 such websites or services.
//             </p>

//             {/* Additional sections... */}

//             <h2 style={{ color: '#2c3e50' }}>6. Limitation of Liability</h2>
//             <ul style={{ listStyleType: 'disc', marginLeft: '20px', color: '#333', marginBottom: '1rem' }}>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     Your use or inability to use the Website.
//                 </li>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     Any unauthorized access to or use of our servers and any personal information stored therein.
//                 </li>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     Any interruption or cessation of transmission to or from the Website.
//                 </li>
//                 <li style={{ lineHeight: '1.8', marginBottom: '1rem' }}>
//                     Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Website by any third party.
//                 </li>
//             </ul>

//             {/* Continue with remaining sections */}
//         </>
//     );
// };

