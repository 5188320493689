import { createSlice } from "@reduxjs/toolkit";
import {
  paymentDetails_India,
  paymentDetails_US_Brex,
  paymentDetails_US_Others,
  paymentLinks_US_Others,
  paymentLinks_US_Main,
  paymentLinks_India,
} from "../../../../consts/PaymentConstants";

const initialState = {
  services: [],
  servicesAdded: [],
  totalAddedServices: 0,
  selectedService: null,
  selectedYear: "",
  auditFromYear: "",
  error: null,
  bankAccounts: {},
};

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setBankAccounts(state, action) {
      // Add this reducer
      const { IND, MBW, OTH } = action.payload;
      // paymentDetails_India
      const bankDetailsIndia = {
        bank_id: IND[0].bank_id,
        data: [
          {
            ...paymentDetails_India.accountHolderName,
            detail: IND[0].bank_account_holder_name,
          },
          {
            ...paymentDetails_India.accountNumber,
            detail: IND[0].bank_account_number,
          },
          { ...paymentDetails_India.ifscCode, detail: IND[0].bank_ifsc_code },
          {
            ...paymentDetails_India.accountType,
            detail: IND[0].bank_account_type,
          },
          { ...paymentDetails_India.bank, detail: IND[0].bank_name },
        ],
        linkData: paymentLinks_India,
      };

      const bankDetailsMBW = {
        bank_id: MBW[0].bank_id,
        data: [
          {
            ...paymentDetails_US_Brex.accountHolderName,
            detail: MBW[0].bank_account_holder_name,
          },
          {
            ...paymentDetails_US_Brex.accountNumber,
            detail: MBW[0].bank_account_number,
          },
          {
            ...paymentDetails_US_Brex.swiftCode,
            detail: MBW[0].bank_swift_code,
          },
          { ...paymentDetails_US_Brex.ifscCode, detail: MBW[0].bank_ifsc_code },
          { ...paymentDetails_US_Brex.bank, detail: MBW[0].bank_name },
          {
            ...paymentDetails_US_Brex.purposeCode,
            detail: MBW[0].bank_purpose_code,
          },
        ],
        linkData: paymentLinks_US_Main,
      };

      const bankDetailsOTH = {
        bank_id: OTH[0].bank_id,
        data: [
          {
            ...paymentDetails_US_Others.accountHolderName,
            detail: OTH[0].bank_account_holder_name,
          },
          {
            ...paymentDetails_US_Others.accountNumber,
            detail: OTH[0].bank_account_number,
          },
          {
            ...paymentDetails_US_Others.achRoutingNumber,
            detail: OTH[0].bank_ach_routing_number,
          },
          {
            ...paymentDetails_US_Others.fedwireRoutingNumber,
            detail: OTH[0].bank_fedwire_routing_number,
          },
          { ...paymentDetails_US_Others.bank, detail: OTH[0].bank_name },
          {
            ...paymentDetails_US_Others.beneficiaryAddress,
            detail: OTH[0].bank_beneficiary_address,
          },
        ],
        linkData: paymentLinks_US_Others,
      };

      state.bankAccounts = {
        India: bankDetailsIndia,
        "Mercury | Brex": bankDetailsMBW,
        "Other Banks": bankDetailsOTH,
      };
    },

    setServices: (state, action) => {
      let services = [];
      action.payload.forEach((service) => {
        services.push({
          serviceId: service.service_id,
          serviceName: service.service_name,
          serviceDescription: service.service_description,
          serviceShortDescription: service.service_short_description,
          servicePrice: parseFloat(service?.service_price) || 0,
          createdAt: service.created_at,
          updatedAt: service.updated_at,
        });
      });
      state.services = services;
    },
    setAllServices: (state, action) => {
      let addedServices = [];

      const getYearDescription = (input) => {
        if (input?.auditFromMonth) {
          const fromYear = input?.auditFromYear?.toString().slice(-2);
          const toYear = input?.auditToYear?.toString().slice(-2);
          return `${input?.auditFromMonth} '${fromYear} - ${input?.auditToMonth} '${toYear}`;
        }
        return input.year || input.auditFromYear || "";
      };

      action.payload.subscribed_services?.forEach((service) => {
        const year =
          service.service_input?.year || service.service_input?.auditFromYear;

        addedServices.push({
          subscriptionId: service.subscription_id,
          taskId: service.task_id,
          serviceName:
            service.service_name === "Tax Residency Certificate"
              ? "TRC"
              : service.service_name,
          serviceShortDescription: service.service_short_description,
          year: year === "N/A" ? "" : year,
          yearDescription: getYearDescription(service.service_input),
          paymentStatus: service.payment_status || "",
          taskStatus: service.service_status,
          servicePrice: service.service_price,
          isDocumentsAvailable: true,
          assigneeName: service.service_assigned_to,
          taskPhase: service.task_phases?.active_phase,
          taskPhaseId: service.task_phases?.task_phases_id,
          documents: service.files || [],
          ticketNumber: service.task_ticket_number,
          entityName: service.entity_name,
          entityId: service.entity_id,
        });
      });
      state.servicesAdded = addedServices;
      state.totalAddedServices = action.payload.total;
    },
    updateTotalServicesCount: (state, action) => {
      state.totalAddedServices = action.payload;
    },
  },
});

export const serviceActions = serviceSlice.actions;
export default serviceSlice.reducer;
