import { useState } from "react";
import styles from "./Input.module.css";
import eyeIconShow from "/assets/login/EyeFilled.svg";
import eyeIconHide from "/assets/login/EyePlain.svg";

const Input = ({ label, showError = false, type, errormessage, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const inputClasses = `${styles.input} ${
    type == "password" && props.placeholder?.trim() == ""
      ? styles.input__password
      : ""
  }`;

  return (
    <div
      className={`${styles.input__control} ${showError ? "invalid" : ""} ${
        props.className
      }`}
    >
      <div className={styles.label__container}>
        <label htmlFor={props.id}>{label}</label>
        {showError && (
          <p>
            <span>*</span>
            {errormessage}
          </p>
        )}
      </div>
      <div className={inputClasses}>
        <input
          type={showPassword ? "text" : type}
          autoComplete={props.autoComplete ? props.autoComplete : "off"}
          {...props}
        />
        {type == "password" && props.value != "" && (
          <i className={styles.btn__eye} onClick={handleClickShowPassword}>
            <img src={showPassword ? eyeIconHide : eyeIconShow} alt="" />
          </i>
        )}
      </div>
    </div>
  );
};

export default Input;
