import { useState, useCallback } from 'react';
import { isNotEmpty, isPhoneNumberWithoutCountryCode, isValid } from "../../../../utils/validations";

export const useFormValidation = () => {
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = useCallback((currentStep, formData) => {
    let stepValid = false;

    switch (currentStep) {
      case 0: // Business Info
        stepValid = 
          formData?.businessAddress?.isValid &&
          isValid(formData?.officerDetails?.name) &&
          isValid(formData?.title) &&
          isPhoneNumberWithoutCountryCode(formData?.phoneNumber);

        break;
      case 1: // Delivery Info
        stepValid = 
          formData?.deliveryAddress?.isValid &&
          formData?.appliedCountries?.length > 0;
        break;
      case 2: // Payment Type
        stepValid = true; // Always valid as it's just a selection
        break;
      case 3: // Bank Details
        stepValid = 
          formData?.bankAddress?.isValid &&
          isValid(formData?.achNumber) &&
          isValid(formData?.accountNumber) &&
          isValid(formData?.bankName);
        break;
      default:
        stepValid = false;
    }

    setIsFormValid(stepValid);
  }, []);

  return { isFormValid, validateForm };
};