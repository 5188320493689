import { motion } from 'framer-motion';
import styles from './LoadingSpinner.module.css';

const loadingCircleVariants = {
    phase1: {
        opacity: [1, 0.5, 0.1],
    },
    phase2: {
        opacity: [0.1, 1, 0.5],
    },
    phase3: {
        opacity: [0.5, 0.1, 1],
    },
    exit: {
        opacity: 0,
        scale: 0,
    },
};

const loadingCircleTransition = {
    duration: 1.5,
    repeat: Infinity,
    ease: "easeInOut",
    staggerChildren: 0.2,
};

const LoadingCircle = ({ phase }) => {
    return (
        <motion.span
            className={styles.loadingCircle}
            variants={loadingCircleVariants}
            animate={phase}
            exit="exit"
            transition={loadingCircleTransition}
        />
    );
};

const LoadingSpinner = ({ loading = true }) => {
    const phases = ["phase1", "phase2", "phase3"];

    return (
        <motion.div
            className={styles.loadingContainer}
            initial="initial"
            animate={loading ? "loading" : "exit"}
        >
            {phases.map((phase, index) => (
                <LoadingCircle key={index} phase={phase} />
            ))}
        </motion.div>
    );
}

export default LoadingSpinner;









