export const formatRequestData = (data, files = [], payloadKeys) => {
  const formData = new FormData();
  for (const item in data) {
    formData.set(payloadKeys[item], data[item]);
  }

  let index = 0;
  while (index < files.length) {
    formData.set(files[index], files[index + 1]);
    index += 2;
  }
  return formData;
};

export const formatRequestDataToJSON = (data, payloadKeys) => {
  let requestData = {};

  for (const item in data) {
    requestData[payloadKeys[item]] = data[item];
  }
  return requestData;
};


export const getAsOnYearsList = (incorporationDate, currentTaxYear = "Jan - Dec") => {
  let asOnYearsList = [];
  let showCurrentDateAsDefault = false;
  
  if (!incorporationDate) {
    return { asOnYearsList, showCurrentDateAsDefault };
  }
  const startDate = new Date(incorporationDate);
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;



  if (currentTaxYear == "Apr - Mar") {
    if (startMonth < 4) {
      asOnYearsList.push(`Mar 31, ${startYear}`);
    }
  }
  if (startYear != currentYear) {
    asOnYearsList.push(`Dec 31, ${startYear}`);
  } else {
    if (startMonth > 3 || currentTaxYear != "Apr - Mar") {
      showCurrentDateAsDefault = true;
      asOnYearsList.push(`Dec 31, ${startYear}`);
    }
  }
  for (let year = startYear + 1; year < currentYear; year++) {
    if (currentTaxYear == "Apr - Mar") {
      asOnYearsList.push(`Mar 31, ${year}`);
    }
    asOnYearsList.push(`Dec 31, ${year}`);
  }
  if (
    currentMonth > 3 &&
    currentTaxYear == "Apr - Mar" &&
    startYear != currentYear
  ) {
    asOnYearsList.push(`Mar 31, ${currentYear}`);
  }
  asOnYearsList.reverse();

  return { asOnYearsList, showCurrentDateAsDefault };
};
