import React, { useState } from "react";
import PayFromOptions from "./PayFromOptions";
import PaymentDetails from "./PaymentDetails";
import PriceDetails from "./PriceDetails";
import styles from "./Payments.module.css";
import { useSelector } from "react-redux";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import SuccessPage from "./PaymentSuccessPage";

const Payments = ({ onClose, selectedService, subscriptionId, onSuccess }) => {
  const [showPayOptions, setShowPayOptions] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [showPaymentSuccessPage, setShowPaymentSuccessPage] = useState(false);

  // Handle country selection
  const handleOpenPaymentDetails = (country) => {
    setSelectedCountry(country);
    setShowPayOptions(false);
    setShowPaymentDetails(true);
  };

  // Back to payment options
  const handleBackToPayOptions = () => {
    setShowPaymentDetails(false);
    setShowPayOptions(true);
    setSelectedCountry(null);
  };

  const handleSuccess = () => {
    setShowPaymentDetails(false);
    setShowPaymentSuccessPage(true);
  };

  return (
    <OverlayRegular onClose={onClose} showCloseIcon={!showPaymentSuccessPage}>
      <div className={styles.container}>
        {showPayOptions && (
          <PayFromOptions
            onSelect={handleOpenPaymentDetails}
            price={selectedService?.servicePrice}
          />
        )}
        {showPaymentDetails && (
          <PaymentDetails
            option={selectedCountry}
            onBack={handleBackToPayOptions}
            onClose={onClose}
            onChangeBank={setSelectedBankId}
            onSuccess={handleSuccess}
            selectedService={selectedService}
            subscriptionId={subscriptionId}
            selectedBankId={selectedBankId}
          />
        )}
        {showPaymentSuccessPage && <SuccessPage onClose={onSuccess} />}
      </div>
    </OverlayRegular>
  );
};

export default Payments;
