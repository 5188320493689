import { createSlice } from "@reduxjs/toolkit";
import { navMenuItems } from "../../MainNavigation/SideBar/NavigationLists";

const initialState = {
  isUserOnboarded: true,
  isBasicInfoComplete: true,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setUserOnboardStatus(state, action) {
      state.isUserOnboarded = action.payload;
    },
    setUserBasicInfoComplete(state, action) {
      state.isBasicInfoComplete = action.payload;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
