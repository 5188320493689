import styles from "./MaskedText.module.css";

const MaskedText = ({
  value,
  visibleCharacters = 4,
  showFixedMaskChar = false,
  maskedCharacters,
  ...props
}) => {
  if (value == undefined || value == null) {
    return <></>;
  }

  let noOfCharactersToBeMasked = 0;
  if (value.length > visibleCharacters) {
    noOfCharactersToBeMasked = showFixedMaskChar
      ? maskedCharacters
      : value.length - visibleCharacters;
  }

  return (
    <div className={`${styles.container} ${props.className}`}>
      {[...Array(noOfCharactersToBeMasked).keys()].map((item) => (
        <div key={`masked-char-${item}`} className={styles.masked_text} />
      ))}
      {value.slice(-visibleCharacters)}
    </div>
  );
};

export default MaskedText;
