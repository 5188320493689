import TabListItemRounded from "./TabListItemRounded";
import TabListItemUnderlined from "./TabListItemUnderlined";
import { tabStyles } from "../../../consts/Constants";

const TabListItem = ({
  children,
  tabStyle = tabStyles.UNDERLINED,
  ...props
}) => {
  return (
    <>
      {tabStyle == tabStyles.ROUND_OUT_BORDER && (
        <TabListItemRounded {...props}>{children}</TabListItemRounded>
      )}
      {(tabStyle == tabStyles.UNDERLINED ||
        tabStyle == tabStyles.BACKGROUND_BOX) && (
        <TabListItemUnderlined {...props} tabStyle={tabStyle}>
          {children}
        </TabListItemUnderlined>
      )}
    </>
  );
};

export default TabListItem;
