import { useState } from "react";
import { useSelector } from "react-redux";
import EditRegistrationDetails from "./EditRegistrationDetails";
import { entityEditForms } from "../../../../consts/Constants";
import EditIRSInformation from "./EditIRSInformation";
import EditRegisteredAgentInfo from "./EditRegisteredAgentInfo";
import {
  useLazyGetEntitiesQuery,
  useUpdateEntityMutation,
  useLazyGetPrimaryEntityQuery,
} from "../apis/entityApi-slice";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import { BTN__RETRY } from "../../../../consts/TextConstants";
import { formatRequestData } from "../../../../utils/dataFormatter";
import { PAYLD__UPDATE_ENTITY } from "../../../../consts/PayloadConstants";

const EditPrimaryEntityDetails = ({ entityId, onSuccess, ...props }) => {
  const [entityData, setEntityData] = useState(null);
  const [error, setError] = useState({ isError: false });
  const { currentEditForm, primaryEntity } = useSelector(
    (state) => state.entity
  );

  const [updateEntity, { isLoading }] = useUpdateEntityMutation();
  const [getEntities] = useLazyGetEntitiesQuery();
  const [getPrimaryEntity] = useLazyGetPrimaryEntityQuery();

  const handleSubmitData = async (data, files, type) => {
    const newData = { ...entityData };
    setEntityData(newData);

    try {
      const requestData = formatRequestData(
        {
          entityId,
          ...data,
        },
        files,
        PAYLD__UPDATE_ENTITY
      );

      await updateEntity({ params: { type }, data: requestData }).unwrap();
      if (type !== "registration-entity") {
        // await getEntityById({ entity_id: entityId }).unwrap();
        await getEntities().unwrap();
      }
      if (entityId === primaryEntity?.entityId) {
        await getPrimaryEntity().unwrap();
      }

      onSuccess();
      props.onClose();
    } catch (err) {
      console.log(err);
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      {currentEditForm == entityEditForms.REGISTRATION && (
        <EditRegistrationDetails
          entityInfo={entityData?.registrationInfo}
          onSubmitData={handleSubmitData}
          entityId={entityId}
          isLoading={isLoading}
          {...props}
        />
      )}
      {currentEditForm == entityEditForms.IRS && (
        <EditIRSInformation
          irsInfo={entityData?.irsInfo}
          onSaveData={handleSubmitData}
          isLoading={isLoading}
          {...props}
        />
      )}
      {currentEditForm == entityEditForms.AGENT && (
        <EditRegisteredAgentInfo
          agentInfo={entityData?.agentInfo}
          onSubmitData={handleSubmitData}
          isLoading={isLoading}
          {...props}
        />
      )}
    </>
  );
};

export default EditPrimaryEntityDetails;
