import { useLocation } from "react-router-dom";
import CompliancePage from "../components/Main/Compliance/CompliancePage";

const Compliance = () => {
  const location = useLocation();

  return <CompliancePage />;
};

export default Compliance;
