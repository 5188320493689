import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./CreatePasswordPage.module.css";
import LogoBox from "../../UI/LogoBox";
import {CREATE_PASSWORD_TITLE} from "../../../consts/TextConstants.jsx";
import CreatePasswordForm from "./CreatePasswordForm.jsx";
import { loginActions } from "../../../store/login-slice.jsx";
import { flowTypes } from "../../../consts/Constants.jsx";

const CreatePasswordPage = () => {
    const { showCreatePassword } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
      if (!showCreatePassword) {
          navigate("/");
      }
    }, []);

    const handleSubmit = () => {
      dispatch(loginActions.setCurrentFlow(flowTypes.SIGN_IN));
      navigate("/");
    };
  return (  
    <div className={styles.main}>
      <div className={styles.container}>
        <LogoBox />
        <div className={styles.title}>
          <p>{CREATE_PASSWORD_TITLE}</p>
        </div>
        <CreatePasswordForm onSubmit={handleSubmit}/>
      </div>
    </div>
  );
};

export default CreatePasswordPage;
