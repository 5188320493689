import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceTypes from "./ServiceTypes";
import ServiceForm from "./ServiceForm";
import styles from "./ServiceList.module.css";
import { serviceActions } from "../reducers/service-slice";
import SearchBar from "../../../UI/Input/SearchBar";

const ServiceList = ({ onClose, onAdd, onAddQuoteSuccess }) => {
  const dispatch = useDispatch();

  const { services } = useSelector((state) => state.services);

  const [selectedService, setSelectedService] = useState(services[0]);
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    setFilteredServices(services);
  }, [services]);

  const handleSearchChange = (value) => {
    if (value !== "") {
      const filtered = services?.filter((service) =>
        service?.serviceName?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredServices(filtered);
    } else {
      setFilteredServices(services);
    }
  };

  const handleServiceSelect = (service) => {
    dispatch(serviceActions.setSelectedService(service));
    setSelectedService(service);
  };

  const handleAddButtonClick = async (data) => {
    onAdd(data);
    setSelectedService(null);
  };

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.service_name}>Services</h1>
        <SearchBar
          id="input-search"
          placeholder="Search"
          inputstyle={styles.searchbar}
          onChange={handleSearchChange}
        />
      </div>
      <div className={styles.content}>
        <ServiceTypes
          selectedService={selectedService}
          onSelect={handleServiceSelect}
          services={filteredServices}
        />
        <ServiceForm
          selectedService={selectedService}
          services={filteredServices}
          onAdd={handleAddButtonClick}
          onAddQuoteSuccess={onAddQuoteSuccess}
          onClose={onClose}
        />
      </div>
    </>
  );
};

export default ServiceList;
