import { useEffect, useState } from "react";
import styles from "./CalendarView.module.css";
import DayView from "./DayView";
import MonthView from "./MonthView";
import YearView from "./YearView";
import { MONTH_NAMES } from "../../../consts/Constants";
import { getNumberOfDaysInAMonth } from "../../../utils/common-utils";

const CalendarView = ({ selectedDate, startDate, endDate, onSelectDay, ...props }) => {
  const [day, setDay] = useState(selectedDate.getDate());
  const [month, setMonth] = useState(selectedDate.getMonth() + 1);
  const [year, setYear] = useState(selectedDate.getFullYear());

  const [closeOnDaySelect, setCloseOnDaySelect] = useState(false);

  const [showMonthView, setShowMonthView] = useState(false);
  const [showYearView, setShowYearView] = useState(false);

  const handleMonthClick = () => {
    setShowMonthView(!showMonthView);
    setShowYearView(false);
  };

  const handleSelectMonth = (value) => {
    const numberOfDays = getNumberOfDaysInAMonth(value, year);
    if (numberOfDays < day) {
      setDay(numberOfDays);
    }
    const endMonth = endDate.getMonth() + 1;
    if (year == endDate.getFullYear() && value == endMonth) {
      const endDay = endDate.getDate();
      if (day > endDay) {
        setDay(endDay);
      }
    }

    const startMonth = startDate.getMonth() + 1;
    if (year == startDate.getFullYear() && month == startMonth) {
      const startDay = startDate.getDate();
      if (day < startDay) {
        setDay(startDay);
      }
    }
    setMonth(value);
    setShowMonthView(false);
  };

  const handlYearClick = () => {
    setShowYearView(!showYearView);
    setShowMonthView(false);
  };

  const handleSelectYear = (value) => {
    // const today = new Date();
    // const thisMonth = today.getMonth();
    // const thiYear = today.getFullYear();
    // const thisDay = today.getDate();

    const endMonth = endDate.getMonth() + 1;
    if (value == endDate.getFullYear() && month > endMonth) {
      setMonth(endMonth);
      const endDay = endDate.getDate();
      if (day > endDay) {
        setDay(endDay);
      }
    }

    const startMonth = startDate.getMonth() + 1;
    if (value == startDate.getFullYear() && month < startMonth) {
      setMonth(startMonth);
      const startDay = startDate.getDate();
      if (day < startDay) {
        setDay(startDay);
      }
    }
    setYear(value);
    setShowYearView(false);
  };

  const handleSelectDay = (value) => {
    setDay(value);
    setCloseOnDaySelect(true);
  };

  useEffect(() => {
    const newDate = new Date(year, month - 1, day);
    props.onChange(newDate)
    if(closeOnDaySelect){
      onSelectDay();
    };
  }, [year, month, day]);

  return (
    <div className={`${styles.date_picker} ${props.className}`}>
      <div className={styles.header}>
        <div onClick={handleMonthClick}>{MONTH_NAMES[month - 1]}</div>
        <div onClick={handlYearClick}>{year}</div>
      </div>
      {showMonthView && (
        <MonthView
          month={month}
          year={year}
          start={startDate}
          end={endDate}
          onSelect={handleSelectMonth}
        />
      )}
      {showYearView && (
        <YearView
          year={year}
          start={startDate.getFullYear()}
          end={endDate.getFullYear()}
          onSelect={handleSelectYear}
        />
      )}
      {!showMonthView && !showYearView && (
        <DayView
          month={month}
          year={year}
          day={day}
          currentDate={selectedDate}
          onSelectDay={handleSelectDay}
          start={startDate}
          end={endDate}
        />
      )}
    </div>
  );
};

export default CalendarView;
