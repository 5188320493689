import React, { useState, useRef, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./ServiceTableRow.module.css";
import ActionItems from "./ActionItems";
import MoreInfo from "../../UI/General/MoreInfo";
import { TableData, TableRow } from "../../UI/Table/CustomTable";
import { useOutsideClick } from "../../../hooks/use-outsideClick";
import { TBL__SERVICES } from "../../../consts/TextConstants";
import rightArrow from "/assets/common/ArrowRight_White.svg";
import folderIcon from "/assets/common/Folder.svg";
import emailIcon from "/assets/subscription/links/EmailIcon.svg";
import userIcon from "/assets/subscription/AssignedPerson.svg";
import Button from "../../UI/Button/Button";

const ServiceTableRow = ({
  serviceItem,
  row,
  column,
  handleEmail,
  handlePayClick,
  handleDocumentsClick,
  onSuccess,
}) => {
  const emailRefs = useRef(null);

  const [showMoreInfoDropdown, setShowMoreInfoDropdown] = useState(false);

  const { primaryEntity } = useSelector((state) => state.entity);

  useOutsideClick({
    ref: emailRefs,
    handler: () => {
      setShowMoreInfoDropdown(false);
    },
  });

  const getPaymentStatusContent = useCallback((serviceItem) => {
    const status = serviceItem.paymentStatus?.toLowerCase() || "";
    const price = serviceItem.servicePrice
      ? ` - $${serviceItem.servicePrice}`
      : "";
    const formattedStatus = status[0]?.toUpperCase() + status?.slice(1);
    return status === "paid" ? `${formattedStatus}${price}` : formattedStatus;
  }, []);

  const renderActionItem = (serviceItem) => {
    const taskStatus = serviceItem.taskStatus?.toLowerCase();

    if (taskStatus === "active" && serviceItem.taskPhaseId) {
      return (
        <ActionItems
          serviceItem={serviceItem}
          entityId={serviceItem?.entityId}
          onSuccess={onSuccess}
        />
      );
    }
    const paymentStatus = serviceItem.paymentStatus?.toLowerCase();

    const actionConfig = {
      completed: { description: "COMPLETED", style: styles.green },
      active: { description: "IN PROGRESS", style: styles.yellow },
      pending: { description: "Complete Payment", style: styles.black },
      verification: { description: "Verifying payment", style: styles.yellow },
      quote: { description: "Under Quotation", style: styles.yellow },
    };

    const { description, style } =
      actionConfig[taskStatus] || actionConfig[paymentStatus] || {};

    return (
      <>
        {description && (
          <div className={`${styles.action_description} ${style}`}>
            <p>{description}</p>
          </div>
        )}
      </>
    );
  };
  const handleMoreInfoClick = useCallback(() => {
    setShowMoreInfoDropdown(true);
  }, []);

  const memoizedTableCells = useMemo(() => {
    return TBL__SERVICES.map((key, col) => (
      <TableData key={`service-row-${key}-${row}-${{ col }}`}>
        {key === "taskStatus" && (
          <div
            className={`${styles.task_status} ${
              styles[serviceItem.taskStatus]
            }`}
          ></div>
        )}
        {key === "serviceName" && (
          <MoreInfo
            content={`${serviceItem.serviceShortDescription} ${
              serviceItem.serviceName === "Audit & Review Report"
                ? `: ${serviceItem?.yearDescription}`
                : ""
            }`}
            position="top"
            showMoreInfoIcon={false}
            customContainerComponent={true}
            key={`service-details-${row}`}
          >
            <div className={styles.contentWrapper}>
              <span>{serviceItem.serviceName}</span>
              {/* Render the ticket number */}
              <div className={styles.ticket_number}>
                {`#${serviceItem.ticketNumber}`}
              </div>
            </div>
          </MoreInfo>
        )}
        {key === "description" && (
          <div className={styles.entity_name}>
            {[serviceItem?.entityName, serviceItem?.year]
              .filter(Boolean)
              .join(" . ")}
          </div>
        )}

        {key === "actionItems" && renderActionItem(serviceItem)}

        {key === "documents" &&
          (serviceItem?.documents?.length > 0 ? (
            <div
              className={styles.documents}
              onClick={() => {
                handleDocumentsClick(serviceItem);
              }}
            >
              <img src={folderIcon} alt="File" />
            </div>
          ) : (
            <div />
          ))}

        {key === "assignedTo" &&
          (serviceItem?.assigneeName ? (
            <MoreInfo
              content={serviceItem?.assigneeName}
              position="top"
              showMoreInfoIcon={false}
              customContainerComponent={true}
              key={`assignedTo-${row}`}
            >
              <div className={styles.assigned_to}>
                <img src={userIcon} alt="User" />
              </div>
            </MoreInfo>
          ) : (
            <div />
          ))}

        {key === "paymentStatus" && (
          <div className={styles.verification_status}>
            {serviceItem.paymentStatus?.toLowerCase() === "pending" ? (
              <Button
                className={styles.btn__pay}
                buttonstyle="default-blue"
                onClick={() => handlePayClick(serviceItem)}
              >
                PAY
                <img src={rightArrow} alt="" />
              </Button>
            ) : (
              <MoreInfo
                content={getPaymentStatusContent(serviceItem)}
                position="top"
                showMoreInfoIcon={false}
                customContainerComponent={true}
                key={`payment-status-${row}`}
              >
                <div
                  className={`${styles.payment_status} ${
                    styles[serviceItem.paymentStatus?.toLowerCase()]
                  }`}
                >
                  {serviceItem.paymentStatus[0]?.toUpperCase()}
                </div>
              </MoreInfo>
            )}
          </div>
        )}
        {key === "moreInfo" && (
          <div
            key={`more-info-${row}-${column}`}
            className={styles.dots}
            onClick={handleMoreInfoClick}
            ref={(comp) => (emailRefs.current = comp)}
          >
            {showMoreInfoDropdown && (
              <ul className={styles.email_card}>
                <li
                  className={styles.card_content}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMoreInfoDropdown(false);
                    handleEmail(serviceItem);
                  }}
                >
                  <img
                    src={emailIcon}
                    className={styles.email_icon}
                    alt="Email Icon"
                  />
                  <span>Email</span>
                </li>
              </ul>
            )}
            ...
          </div>
        )}
      </TableData>
    ));
  }, [
    serviceItem,
    row,
    column,
    showMoreInfoDropdown,
    handleEmail,
    handlePayClick,
  ]);

  return (
    <TableRow className={styles.table_height} key={`service-row-${row}`}>
      {memoizedTableCells}
    </TableRow>
  );
};

export default ServiceTableRow;
