import React, { useState, useEffect } from "react";
import AttachRecipt from "./AttachRecipt";
import GstForm from "./GstForm";
import SuccessPage from "./PaymentSuccessPage";
import BasePrice from "./BasePrice"; // Import the new component
import styles from "./PriceDetails.module.css";
import { getExchangeRatesForINRAndUSD } from "../../../../utils/currencyConverter";
import Button from "../../../UI/Button/Button";


const PriceDetails = ({
    option,
    onFileChange,
    selectedService,
    selectedBankId,
    subscriptionId,
    onSuccess,
}) => {

    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [showPaymentSuccessPage, setShowPaymentSuccessPage] = useState(false);
    const [showGstForm, setShowGstForm] = useState(false);
    const [showAttachRecipt, setShowAttachRecipt] = useState(false);
    const [exchangeRate, setExchangeRate] = useState(null);
    const [businessName, setBusinessName] = useState("");


    useEffect(() => {
        const fetchExchangeRate = async () => {
            const rate = await getExchangeRatesForINRAndUSD();
            setExchangeRate(rate);
        };
        fetchExchangeRate();
    }, []);

    const handleGSTSubmit = () => {
        setShowGstForm(false);
        setShowAttachRecipt(true);
    };

    const handleAccountTypeChange = (type) => {
        setSelectedAccountType(type);
        setShowGstForm(type === "Business");
        setShowAttachRecipt(type === "Personal");
    };

    const handleEditGstFromClick = () => {
        setShowGstForm(true);
        setShowAttachRecipt(false);
    };

    const basePriceInINR = exchangeRate ? (selectedService.servicePrice * exchangeRate).toFixed(0) : 0;
    const gstInINR = exchangeRate ? (selectedService.servicePrice * exchangeRate * 0.18).toFixed(0) : 0;
    const totalPriceInINR = (parseFloat(basePriceInINR) + parseFloat(gstInINR)).toFixed(0);


    return (
        <div className={styles.price_details_cont}>
            <div
                className={`${styles.usa_price_cont} ${(option === "U.S.A" || (option === "India" && selectedAccountType === "Business"))
                    ? styles["border-radius"]
                    : ""
                    } ${showAttachRecipt ? styles["no-bottom-radius"] : ""}`}
            >
                {option === "U.S.A" ? (
                    <span>${selectedService.servicePrice}</span>
                ) : option === "India" ? (
                    selectedAccountType === "Personal" ? (
                        <span>₹{totalPriceInINR}</span>
                    ) : (
                        <span>₹{totalPriceInINR}</span>
                    )
                ) : null}

                {option === "India" && selectedAccountType === "Business" && (
                    <div className={styles.gst_message}>
                        <span>including GST</span>
                    </div>
                )}
            </div>



            <div className={styles.payment_container}>
                {option === "India" && selectedAccountType === "Personal" && (
                    <BasePrice
                        basePriceInINR={basePriceInINR}
                        gstInINR={gstInINR}
                        servicePrice={selectedService.servicePrice}
                    />
                )}

                {option === "India" && selectedAccountType === "Business" && showAttachRecipt && (
                    <div className={styles.attach_recipt_container}>
                        <BasePrice
                            basePriceInINR={basePriceInINR}
                            gstInINR={gstInINR}
                            servicePrice={selectedService.servicePrice}
                            businessName={businessName}
                            handleEditGstFormClick={handleEditGstFromClick}
                        />
                    </div>
                )}


                {option === "India" && !selectedAccountType && (
                    <div>
                        <BasePrice
                            basePriceInINR={basePriceInINR}
                            gstInINR={gstInINR}
                            servicePrice={selectedService.servicePrice}
                        />
                        <div className={styles.choose_your_pay_cont}>
                            <div className={styles.bottom_cont_para}>
                                What type of account are you paying from?
                            </div>
                            <div className={styles.two_btn}>
                                <Button 
                                    className={styles.business_btn}
                                    onClick={() => handleAccountTypeChange("Business")}
                                >
                                    BUSINESS
                                </Button>
                                <Button
                                    className={styles.business_btn}
                                    onClick={() => handleAccountTypeChange("Personal")}
                                >
                                    PERSONAL
                                </Button>
                            </div>
                        </div>
                    </div>
                )}



                {(option === "U.S.A" || showAttachRecipt) && (
                    <AttachRecipt
                        onFileChange={onFileChange}
                        selectedService={selectedService}
                        selectedBankId={selectedBankId}
                        subscriptionId={subscriptionId}
                        entityId={selectedService?.entityId}
                        onSuccess={onSuccess}
                    />
                )}

                {option === "India" && selectedAccountType === "Business" && showGstForm && (
                    <GstForm
                        onSubmit={handleGSTSubmit}
                        onHide={() => setShowGstForm(false)}
                        onBusinessNameChange={setBusinessName}
                        entityId={selectedService?.entityId}
                    />
                )}
            </div>
        </div>
    );
};

export default PriceDetails;






















































