import { useEffect, useRef, useState } from "react";
import styles from "./OTPInput.module.css";

const OTPInput = ({ otpLength = 6, ...props }) => {
  const [otp, setOtp] = useState(new Array(otpLength).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (index, event) => {
    let value = event.target.value;
    value = value.replace(/[^\d]/gi, "");

    let newOtp = [];
    if (value.length == otpLength) {
      for (let i = 0; i < value.length; i++) {
        newOtp[i] = value[i];
      }
    } else {
      newOtp = [...otp];
      newOtp[index] = value.substring(value.length - 1);
    }

    setOtp(newOtp);

    props.onComplete(newOtp.join(""));

    if (value && index < otpLength - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    } else if (index == otpLength - 1 && value && inputRefs.current[index]) {
      inputRefs.current[index].blur();
    }
  };

  const handleKeyDown = (index, event) => {
    if (
      event.key == "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
  };

  return (
    <div className={`${styles.input__container} ${props.className}`}>
      {[...Array(otpLength).keys()].map((index) => (
        <div key={`div_otp_${index}`} className={styles.input__control}>
          <input
            key={`otp_${index}`}
            type="text"
            ref={(input) => (inputRefs.current[index] = input)}
            onChange={(event) => handleChange(index, event)}
            onKeyDown={(event) => handleKeyDown(index, event)}
            onClick={() => handleClick(index)}
            value={otp[index]}
          />
        </div>
      ))}
    </div>
  );
};

export default OTPInput;
