import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import useInput from "../../hooks/use-input.jsx";
import { loginActions } from "../../store/login-slice.jsx";
import { authActions } from "../../store/auth-slice.jsx";
import Form from "../UI/Form.jsx";
import Input from "../UI/Input/Input.jsx";
import Button from "../UI/Button/Button.jsx";
import styles from "./LoginForm.module.css";
import {
  BTN__RETRY,
  LBL__EMAIL,
  ERR_EMAIL,
  PLACEHOLDER_EMAIL,
  BTN__NEXT,
  ERR_DEFAULT
} from "../../consts/TextConstants.jsx";
import { isEmail } from "../../utils/validations.jsx";
import { createCode } from "supertokens-web-js/recipe/passwordless";
import MessageDialog from "../UI/Modal/MessageDialog.jsx";

const LoginForm = ({ ...props }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({ isError: false });
  const [isValid, setIsValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {
    value: email,
    isValid: isEmailValid,
    showError: showEmailError,
    handleChange: handleEmailChange,
    handleBlur: handleEmailBlur,
    handleFocus: handleEmailFocus,
    reset: resetEmail,
  } = useInput(isEmail);

  useEffect(() => {
    setIsValid(isEmailValid && !isLoading);
  }, [isEmailValid, isLoading]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      let response = await createCode({
        email,
      });
      if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // the reason string can tell why sign in / up was not allowed.
        // window.alert(response.reason);
        setError({
          isError: true,
          title: "Sign in Error",
          message: response.reason,
        });
      } else {
        // OTP sent successfully.
        // window.alert("Please check your email for an OTP");
        dispatch(authActions.setUser(email));
        dispatch(loginActions.setShowOTPForm(true));
      }
    } catch (err) {
      let errorMessage;
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        errorMessage  = err.message;
      } else {
        err.message = "Oops! Something went wrong."
      }
      setError({
        isError: true,
        title: "Sign in Error",
        message: errorMessage || ERR_DEFAULT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseError = () => {
    resetEmail();
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <div className={styles.form_container}>
      <AnimatePresence>
        {error.isError && (
          <MessageDialog
            title={error.title}
            message={error.message}
            buttonTitle={BTN__RETRY}
            onClose={handleCloseError}
          />
        )}
      </AnimatePresence>
      <Form method="post" className={props.className} onSubmit={handleSubmit}>
        <Input
          label={LBL__EMAIL}
          id="input_email"
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          onFocus={handleEmailFocus}
          value={email}
          name="email"
          showError={showEmailError}
          errormessage={ERR_EMAIL}
          placeholder={PLACEHOLDER_EMAIL}
        />
        <Button
          type="submit"
          buttonstyle="fixed_width_blue_login"
          disabled={!isValid}
          isLoading={isLoading}
        >
          {BTN__NEXT}
        </Button>
      </Form>
    </div>
  );
};

export default LoginForm;
