import styled from "styled-components";

export const GradientText =styled.span`
background: linear-gradient(to left, #00e3c2, #0047ff);
background: linear-gradient(to top);

-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

