import { redirect, json } from "react-router-dom";
import {
  BASE_URL,
  CT_APP_JSON,
  METHOD_POST,
  URL_GET_TOKEN,
} from "../../../consts/APIConstants";
import { setAuthToken } from "../../../utils/authentication";

export const userLoginAction = async ({ request }) => {
  const data = await request.formData();
  const loginData = {
    email: data.get("email"),
    password: data.get("password"),
  };
  try {
    const response = await fetch(BASE_URL + URL_GET_TOKEN, {
      method: METHOD_POST,
      headers: { "Content-Type": CT_APP_JSON },
      body: JSON.stringify(loginData),
    });
    const responseData = await response.json();
    if (!response.ok) {
      return json({ status: "error", message: responseData.message });
    }
    await setAuthToken(responseData.accessToken);
    await setUser(loginData.email);
  } catch (e) {
    return json({
      status: "error",
      message: "Something happened. Please try again  ",
    });
    // return redirect("/home");

  }

  return redirect("/home");
};

export const otpVerificationAction = () => {
  return redirect("/changepassword");
};

export const getUserDetails = async (userData) => {};
