import styles from "./DropdownList.module.css";
import { motion } from "framer-motion";

const DropdownList = ({
  options,
  onSelect,
  listItemStyle,
  className,
  showListOnTop = false,
}) => {
  return (
    <motion.ul
      className={`${styles.list_container} ${className} ${
        showListOnTop ? styles.top : styles.bottom
      }`}
      initial={{ height: 0 }}
      animate={{ height: "auto" }}
      exit={{ height: 0 }}
    >
      {options.map((item) => (
        <li
          key={item}
          className={`${styles.list_item} ${listItemStyle}`}
          onClick={() => onSelect(item)}
        >
          {item}
        </li>
      ))}
    </motion.ul>
  );
};

export default DropdownList;
