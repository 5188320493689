import styles from "./InputCheckBox.module.css";
import { motion } from "framer-motion";
import { useState } from "react";

const InputCheckBox = ({
  title,
  onClickLabel,
  labelStyle,
  checkBoxStyle,
  className,
  checked: initialChecked = false,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <label className={styles.input__control}>
        <input
          type="checkbox"
          {...props}
          id={title}
          checked={checked}
          onChange={handleChange}
        />
        <span className={`${styles.checkmark} ${checkBoxStyle}`}></span>
      </label>
      {title && (
        <label
          className={`${styles.label__title} ${labelStyle}`}
          onClick={onClickLabel}
        >
          {title}
        </label>
      )}{" "}
    </div>
  );
};

export default InputCheckBox;
