import styles from "./RevenueStatementForm.module.css";
import { useState } from "react";
import Button from "../../UI/Button/Button";
import Calendar from "../../UI/DatePicker/Calendar";
import { useLazyGetRevenueStatementQuery } from "./apis/financeApi-slice";
import {
  addNumberOfDaysToDate,
  getDateFromString,
  getStringFromDateInFormat,
} from "../../../utils/common-utils";
import { REDIRECT_URL__ZOHO } from "../../../consts/APIConstants";
import { useSelector } from "react-redux";
import { BTN__SUBMIT } from "../../../consts/TextConstants";

const RevenueStatementForm = ({ onSubmit, fromDate, toDate, setFromDate, setToDate }) => {
  const today = new Date();

  // const [fromDate, setFromDate] = useState(addNumberOfDaysToDate(today, -30));
  // const [toDate, setToDate] = useState(new Date());

  const { primaryEntity } = useSelector((state) => state.entity);

  // const [getRevenueStatement, { isLoading }] =
  //   useLazyGetRevenueStatementQuery();

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      entity_id: primaryEntity?.entityId,
      from_date: getStringFromDateInFormat(fromDate, "YYYY-MM-DD"),
      to_date: getStringFromDateInFormat(toDate, "YYYY-MM-DD"),
      redirect_uri: REDIRECT_URL__ZOHO,
    });
  };

  const handleFromDateChange = (value) => {
    setFromDate(value);
    if (value > addNumberOfDaysToDate(toDate, -7)) {
      const newToDate = addNumberOfDaysToDate(value, 7);
      setToDate(newToDate);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label htmlFor="fromDate" className={styles.label}>
        From
      </label>
      <Calendar
        datePickerStyle={styles.date_picker}
        selectedDate={fromDate}
        id="fromDate"
        onChange={handleFromDateChange}
        showCalenderIcon={false}
        showArrowDownIcon={true}
        arrowStyle={styles.img_arrow}
        startDate={getDateFromString(primaryEntity?.incorporationDate)}
        endDate={addNumberOfDaysToDate(new Date(), -7)}
      />
      <label htmlFor="toDate" className={styles.label}>
        To
      </label>
      <Calendar
        datePickerStyle={styles.date_picker}
        selectedDate={toDate}
        id="toDate"
        onChange={(value) => setToDate(value)}
        showCalenderIcon={false}
        showArrowDownIcon={true}
        arrowStyle={styles.img_arrow}
        startDate={addNumberOfDaysToDate(fromDate, 7)}
        endDate={new Date()}
      />
      <Button
        className={styles.btn__submit}
        buttonstyle="default-blue"
        // isLoading={isLoading}
        onClick={handleSubmit}
      >
        {BTN__SUBMIT}
      </Button>
    </form>
  );
};

export default RevenueStatementForm;
