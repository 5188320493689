import styles from "./Form.module.css";
import {motion} from "framer-motion";

const Form = ({ children, className, ...props }) => {
  return (
    <motion.form initial={{opacity:0, y: 30}} animate={{opacity:1, y: 0}} layout {...props} className={`${styles.form} ${className}`}>
      {children}
    </motion.form>
  );
};

export default Form;
