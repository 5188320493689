import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./EntityPage.module.css";
import addIcon from "/assets/common/Plus_White.svg";
import { entityEditForms } from "../../../consts/Constants";
import { entityActions } from "./reducers/entity-slice";
import EntityEditPage from "./EntityEdit/EntityEditPage";
import { AnimatePresence } from "framer-motion";
import {
  useGetEntitiesQuery,
  useLazyGetEntitiesQuery,
} from "./apis/entityApi-slice";
import { navigaionActions } from "../../MainNavigation/reducers/navigation-slice";
import EntityList from "./EntityList";
import Button from "../../UI/Button/Button";
import {
  TITLE__ENTITY_LIST,
  BTN__ADD_ENTITY,
} from "../../../consts/TextConstants";
import ErrorComponent from "../../UI/Error/ErrorComponent";
import LoadingList from "../../UI/LoadingIndicator/LoadingList";

const EntityPage = () => {
  const dispatch = useDispatch();

  const [openEditOverlay, setOpenEditOverlay] = useState(false);

  const { primaryEntity, entities } = useSelector((state) => state.entity);

  const {
    isSuccess,
    error,
    isFetching: isLoading,
  } = useGetEntitiesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [
    getEntities,
    {
      isFetching: isLoadingOnTrigger,
      isSuccess: isSuccessOnTrigger,
      error: errorOnTrigger,
    },
  ] = useLazyGetEntitiesQuery();

  useEffect(() => {
    dispatch(
      navigaionActions.setNavigationTitle(
        // primaryEntity?.entityName || "Entity Details"
        "My Entities"
      )
    );
  }, [primaryEntity]);

  const handleAddEntity = () => {
    dispatch(entityActions.setEditForm(entityEditForms.ADD_ENTITY));
    setOpenEditOverlay(true);
  };

  useEffect(() => {
    if (primaryEntity?.isRegistrationEditable) {
      dispatch(entityActions.setEditForm(entityEditForms.REGISTRATION));
      setOpenEditOverlay(true);
    }
  }, [primaryEntity]);

  const handleSuccess = async () => {
    try {
      await getEntities().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClose = () => {
    setOpenEditOverlay(false);
  };

  if (isLoading || isLoadingOnTrigger)
    return <LoadingList count={4} className={styles.loading} />;

  if (error || errorOnTrigger)
    return (
      <ErrorComponent errorMessage="Failed to fetch entities. Please refresh the page again ..." />
    );

  if (isSuccess || isSuccessOnTrigger)
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {/* <div className={styles.header__title}>{TITLE__ENTITY_LIST}</div> */}
          <Button
              className={styles.btn__add}
              buttonstyle="default-blue"
              onClick={handleAddEntity}
            >
            <img src={addIcon} alt="" />
            {BTN__ADD_ENTITY.toUpperCase()}
          </Button>
        </div>
        <EntityList entities={entities} onSuccess={handleSuccess} />

        <AnimatePresence>
          {openEditOverlay && (
            <EntityEditPage
              className={styles.edit_overlay}
              onClose={handleEditClose}
              entityId={primaryEntity.entityId}
              selectedCountry={primaryEntity.incorporationCountry}
              entityType={primaryEntity.entityType}
              entityName={primaryEntity.entityName}
              onSuccess={handleSuccess}
            />
          )}
        </AnimatePresence>
      </div>
    );
};

export default EntityPage;
