import { NavLink } from "react-router-dom";
import styles from "./BookKeepingLinkOverlay.module.css";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import Button from "../../UI/Button/Button";
import {
  BTN__LOGIN,
  TEXT__BOOK_KEEPING_TAG_LINE,
  BTN__SIGNUP,
} from "../../../consts/TextConstants";
import zohoBooksIcon from "/assets/finance/ZohoBooks.svg";
import moreInfoIcon from "/assets/common/MoreInfo.svg";
import MoreInfo from "../../UI/General/MoreInfo";
import {
  URL__REDIRECT_ZOHO,
  URL__ZOHO_SIGN_UP,
} from "../../../consts/APIConstants";

const BookKeepingLinkOverlay = ({ ...props }) => {
  const handleZohoLoginClick = () => {
    window.location.href = URL__REDIRECT_ZOHO;
  };
  return (
    <OverlayRegular
      className={styles.overlay}
      blurValue="0.15rem"
      onClose={props.onClose}
    >
      <div className={styles.container_zoho_books_icon}>
        <img src={zohoBooksIcon} />
      </div>
      <div className={styles.tagline}>
        {TEXT__BOOK_KEEPING_TAG_LINE}
        <MoreInfo
          content="Merze will be added as a admin user automatically"
          position="top"
        />
      </div>
      <div className={styles.action_items}>
        <Button
          className={styles.btn__action}
          buttonstyle="default-blue"
          onClick={handleZohoLoginClick}
          hasHoverStyles={true}
        >
          {BTN__LOGIN}
        </Button>
        OR
        <a
          href={URL__ZOHO_SIGN_UP}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.no_underline}
        >
          <Button
            className={styles.btn__action}
            buttonstyle="default-blue-outline"
            hasHoverStyles={true}
          >
            {BTN__SIGNUP}
          </Button>
        </a>
      </div>
    </OverlayRegular>
  );
};

export default BookKeepingLinkOverlay;
