import styles from "./LogoBox.module.css";
import { APP_NAME } from "../../consts/TextConstants";
import logo from "/assets/Logo.svg";

const LogoBox = ({...props}) => {
    return(<div className={`${styles.logo_container} ${props.className}`}>
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <p className={styles.app_name}>{APP_NAME}</p>
      </div>);
};

export default LogoBox;