import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AccountDropDown.module.css";
import logoutIcon from "/assets/dashboard/Logout.svg";
import logoutHoverIcon from "/assets/dashboard/Logout_Hover.svg";
import settingsIcon from "/assets/dashboard/Settings.svg";
import settingsHoverIcon from "/assets/dashboard/Settings_Hover.svg";
import {
  LBL__ACCOUNT_SETTINGS,
  LBL__LOGOUT,
  BTN__RETRY,
} from "../../../consts/TextConstants";
import { authActions } from "../../../store/auth-slice";
import { onboardActions } from "../../Onboard/reducers/onboard-slice";
import { useNavigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";
import { useLogoutMutation } from "../../../store/authApi-slice";
import MessageDialog from "../../UI/Modal/MessageDialog";
import userIcon from "/assets/dashboard/User.svg";

const ListItem = ({ label, icon, hoverIcon, ...props }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <li
      className={styles.listItem}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...props}
    >
      <p>{label}</p>
      <img src={isHover ? hoverIcon : icon} alt="" />
    </li>
  );
};

const AccountDropDown = ({ ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState({ isError: false });
  const { userDetails } = useSelector((state) => state.auth);

  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      const response = await logout().unwrap();
      if (response.status == 200) {
        const result = await Session.signOut();
        dispatch(onboardActions.resetCollectedData());
        navigate("/", { replace: true });
      } else {
        setError({
          isError: true,
          title: "Logout error",
          message: "There is some error. Please try again.",
        });
      }
    } catch (err) {
      setError({
        isError: true,
        title: "Logout error",
        message: err?.data?.message,
      });
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <div className={styles.container}>
      <div className={styles.user_details}>
        <div className={styles.user_icon}>
          <img src={userIcon} alt="user" />
        </div>
        <div className={styles.user_info}>
          <p className={styles.user_name}>{userDetails?.userName}</p>
          <p className={styles.user_email}>{userDetails?.userEmail}</p>
        </div>
      </div>
      <div className={styles.divider} />
      <ul>
        <ListItem
          key={LBL__ACCOUNT_SETTINGS}
          label={LBL__ACCOUNT_SETTINGS}
          icon={settingsIcon}
          hoverIcon={settingsHoverIcon}
        />
        <ListItem
          key={LBL__LOGOUT}
          label={LBL__LOGOUT}
          icon={logoutIcon}
          hoverIcon={logoutHoverIcon}
          onClick={handleLogout}
        />
        {error.isError && (
          <MessageDialog
            title={error.title}
            message={error.message}
            buttonTitle={BTN__RETRY}
            onClose={handleCloseError}
          />
        )}
      </ul>
    </div>
  );
};

export default AccountDropDown;
