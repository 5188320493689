import { useState } from "react";

const useInput = (validate, defaultValue = "", shouldValidateOnChange = false) => {
    const [ enteredValue, setEnteredValue ] = useState(defaultValue);
    const isEnteredValueValid = validate(enteredValue);
    const [showError, setShowError] = useState(false);

    const handleChange = (event) => {
        if(shouldValidateOnChange) {
            const isValid = validate(event.target.value);
            if (isValid) {
                setEnteredValue(event.target.value);
            }
        } else {
            setEnteredValue(event.target.value);
        }
    };

    const handleBlur = (event) => {
        if(!isEnteredValueValid) {
            setShowError(true);
        }
    };

    const handleFocus = (event) => {
        setShowError(false);
    };

    const reset = (value) => {
        setEnteredValue(value || "");
    };

    return {
        value: enteredValue,
        isValid: isEnteredValueValid,
        showError,
        handleChange,
        handleBlur,
        handleFocus,
        reset,
        setShowError,
    };
};

export default useInput;