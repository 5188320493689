import {
  URL_CREATE_SHARE_HOLDER,
  URL_GET_SHARE_HOLDERS,
  METHOD_POST,
  METHOD_GET,
  URL_DELETE_SHARE_HOLDER,
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { capTableActions } from "../reducers/capTable-slice";

const capTableApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addShareHolder: builder.mutation({
      query: (data) => ({
        url: URL_CREATE_SHARE_HOLDER,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "multipart/form-data" },
        body: data,
      }),
    }),
    getShareHolders: builder.query({
      query: (args) => {
        return {
          url: URL_GET_SHARE_HOLDERS,
          method: METHOD_GET,
          params: args,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(capTableActions.handleStakesData(data));
        } catch (error) {}
      },
    }),
    deleteShareHolder: builder.mutation({
      query: (data) => ({
        url: URL_DELETE_SHARE_HOLDER,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: data,
      }),
    }),

  }),
});

export const {
  useAddShareHolderMutation,
  useLazyGetShareHoldersQuery,
  useGetShareHoldersQuery,
  useDeleteShareHolderMutation
} = capTableApiSlice;
