import { motion } from "framer-motion";
import styles from "./TabListItemUnderlined.module.css";

const TabListItemUnderlined = ({ children, activeTab, tabStyle, ...props }) => {
  return (
    <li
      className={`${styles.tab} ${activeTab ? styles.active : ""} `}
      {...props}
    >
      <div className={styles.content}>{children}</div>
      {activeTab && (
        <motion.div
          className={styles[tabStyle]}
          layoutId={`tab-active-${tabStyle}`}
        ></motion.div>
      )}
    </li>
  );
};

export default TabListItemUnderlined;
