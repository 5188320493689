import { useState } from "react";
import styles from "./WelcomePage.module.css";
import WelcomeContent from "./WelcomeContent";
import Pagination from "../UI/Pagination/Pagination";
import OverlayRegular from "../UI/Modal/OverlayRegular";

const WelcomePage = ({ ...props }) => {
  const [active, setActive] = useState(0);

  const handleChange = (index) => {
    setActive(index);
  };

  return (
    <OverlayRegular keyName="welcome-page" className={styles.welcome} showCloseIcon={false}>
      <Pagination
        className={styles.pagination}
        active={active}
        onChange={handleChange}
      />
      <WelcomeContent
        active={active}
        onChange={handleChange}
        onClose={props.onClose}
      />
    </OverlayRegular>
  );
};

export default WelcomePage;
