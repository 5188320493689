import { useSelector } from "react-redux";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import styles from "./BusinessAccountsOverlay.module.css";
import addIcon from "/assets/common/Plus_White.svg";
import Table from "../../UI/Table/Table";
import { TBL__BANK_ACCOUNTS } from "../../../consts/TextConstants";
import {
  CustomTable,
  TableData,
  TableHeader,
  TableRow,
} from "../../UI/Table/CustomTable";
import linkIcon from "/assets/finance/Link_Blue.svg";
import MaskedText from "../../UI/Text/MaskedText";
import { getCurrencyWithSymbol } from "../../../utils/common-utils";

const formatAccountBalance = (balance, currencyCode) => {
  const balanceWithCurrency = getCurrencyWithSymbol(currencyCode, balance);

  return (
    balanceWithCurrency.substring(0, 1) + " " + balanceWithCurrency.substring(1)
  );
};

const AccountsList = ({ bankAccounts }) => {
  return (
    <CustomTable>
      <thead>
        <TableRow>
          <TableHeader key={`table-header-0`}></TableHeader>
          {Object.values(TBL__BANK_ACCOUNTS).map((item, index) => (
            <TableHeader key={`table-header-${index + 1}`}>{item}</TableHeader>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {bankAccounts.map((account, row) => (
          <TableRow key={`table-row-${row}`}>
            <TableData key={`table-row-column-linkIcon-${row}-0`}>
              <img src={linkIcon} alt="" />
            </TableData>
            {Object.keys(TBL__BANK_ACCOUNTS).map((key, column) => (
              <TableData key={`table-row-column-${key}-${row}-${column}`}>
                {key == "accountNumber" && (
                  <MaskedText
                    value={account.accountNumber}
                    showFixedMaskChar={false}
                    maskedCharacters={3}
                  />
                )}
                {key == "balance" &&
                  `${formatAccountBalance(
                    account.balance,
                    account.currencyCode
                  )}`}

                {key != "accountNumber" && key != "balance" && (
                  <>{account[key]}</>
                )}
              </TableData>
            ))}
          </TableRow>
        ))}
      </tbody>
    </CustomTable>
  );
};

const BusinessAccountsOverlay = ({ className, ...props }) => {
  const { bankAccounts } = useSelector((state) => state.finance);

  return (
    <OverlayRegular
      keyName="edit-overlay"
      className={`${styles.overlay} ${className}`}
      blurValue="0.15rem"
      onClose={props.onClose}
    >
      <div className={styles.title}>Business accounts</div>
      <div className={styles.sub_header}>
        <div className={styles.description}>
          <img src={linkIcon} alt="" />
          Bank accounts synced from book keeping software cannot be deleted
        </div>

        {/*  Enable it for Phase 1.1 */}
        {/* <ButtonCustom className={styles.btn__add}>
        <img src={addIcon} alt="" /> ADD ACCOUNT
      </ButtonCustom> */}
      </div>

      <AccountsList bankAccounts={bankAccounts} />

      <div className={styles.footer_text}>
        <div className={styles.info_icon}>i</div>Bank information is only used
        for Book keeping purposes
      </div>
    </OverlayRegular>
  );
};

export default BusinessAccountsOverlay;
