//ServicesDocumentOverlay.jsx component below

import React from "react";
import { useSelector } from "react-redux";
import styles from "./ServicesDocumentsOverlay.module.css";
import {
  CustomTable,
  TableHeader,
  TableRow,
  TableData,
} from "../../UI/Table/CustomTable";
import downloadArrow from "/assets/common/DownloadArrow.svg";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import { downloadFile } from "../../../utils/fileHandler";
import fileGray from "/assets/common/File_Gray.svg";
import {
  URL__DOWNLOAD_SERVICE_PHASE_FILES,
  BASE_URL,
} from "../../../consts/APIConstants";
import Button from "../../UI/Button/Button";
const ServicesDocumentOverlay = ({ onClose, serviceItem }) => {
  // const { primaryEntity } = useSelector((state) => state.entity);

  const handleDownload = async (fileName, type) => {
    try {
      const fileDownloadURL = BASE_URL + URL__DOWNLOAD_SERVICE_PHASE_FILES;

      const queryparams = `?entity_id=${encodeURIComponent(
        serviceItem?.entityId
      )}&task_id=${encodeURIComponent(
        serviceItem?.taskId
      )}&file_name=${encodeURIComponent(fileName)}`;

      downloadFile(fileDownloadURL, queryparams, fileName, type === "download");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <OverlayRegular onClose={onClose} className={styles.overlay}>
      <div className={styles.header}>Documents</div>
      <CustomTable className={styles.table}>
        <thead>
          <TableRow key={`table-header-row-0`}>
            <TableHeader key={`table-header-0`}>Document</TableHeader>
            <TableHeader key={`table-header-1`}></TableHeader>
            <TableHeader key={`table-header-2`}></TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {serviceItem?.documents?.map((document, row) => (
            <TableRow key={`table-row-${row}`}>
              <TableData key={`table-data-col-0-row-${row}`}>
                {document}
              </TableData>
              <TableData key={`table-data-col-1-row-${row}`}>
                <Button
                  className={styles.btn__download}
                  onClick={() => handleDownload(document, "open")}
                >
                  Open
                </Button>
              </TableData>
              <TableData key={`table-data-col-2-row-${row}`}>
                <Button
                  className={styles.btn__download}
                  onClick={() => handleDownload(document, "download")}
                >
                  <img src={downloadArrow} alt="" />
                </Button>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </CustomTable>
    </OverlayRegular>
  );
};

export default ServicesDocumentOverlay;
