import styles from "./SubscriptionPage.module.css";
import { GradientText } from "../../UI/GradientText";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import overviewImg from "/assets/subscription/OverviewCompliance.svg";
import logo from "/assets/Logo.svg";
import {
  APP_NAME,
  BTN__ALREADY_COMPLETED,
  BTN__GET_QUOTE,
  BTN__GO_TO_COMPLIANCE,
  MSG__QUOTATION_REQUEST_SENT,
  SUB_PACKAGE_FEATURE_1,
  SUB_PACKAGE_FEATURE_2,
  SUB_PACKAGE_FEATURE_3,
  TITLE_COMPLIANCE,
} from "../../../consts/TextConstants";
import LogoBox from "../../UI/LogoBox";
import { useState } from "react";
import NavLink from "../../UI/Link/NavLink";
import { complianceActions } from "../Compliance/reducers/compliance-slice";
import { useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import MessageDialog from "../../UI/Modal/MessageDialog";

const SubscriptionPage = ({complianceYear}) => {
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  const handleQuotationClick = () => {
    setShowMessage(true);
  };

  const handleCompletedClick = () => {
    dispatch(complianceActions.setComplianceCompletionStatus(true));
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
    dispatch(complianceActions.setQuotationRequestStatus(true));
  };

  return (
    <div className={styles.wrapper}>
        <AnimatePresence>
        {showMessage && (
          <MessageDialog
            isError={false}
            message={MSG__QUOTATION_REQUEST_SENT}
            buttonTitle={BTN__GO_TO_COMPLIANCE}
            onClose={handleCloseMessage}
          />
        )}
      </AnimatePresence>
      <div className={styles.container}>
        <div className={styles.content}>
          <LogoBox className={styles.logo} />
          {/* <div className={styles.year_text}>{complianceYear}</div> */}
          <GradientText className={styles.title}>
            {TITLE_COMPLIANCE}
          </GradientText>
          <div className={styles.features}>
            <p>{SUB_PACKAGE_FEATURE_1}</p>
            <p>{SUB_PACKAGE_FEATURE_2}</p>
            <p>{SUB_PACKAGE_FEATURE_3}</p>
          </div>

          <div className={styles.coming_soon}>
          Coming Soon...
          </div>
          {/* <ButtonCustom
            className={styles.btn__quote}
            onClick={handleQuotationClick}
          >
            {BTN__GET_QUOTE}

          </ButtonCustom> */}
          {/* <ButtonCustom
            className={styles.btn__completed}
            onClick={handleCompletedClick}
          >
            {BTN__ALREADY_COMPLETED}
          </ButtonCustom> */}
        </div>
        <img src={overviewImg} alt="" />
      </div>
    </div>
  );
};

export default SubscriptionPage;
