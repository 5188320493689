import { createSlice } from "@reduxjs/toolkit";

const complianceList = [
  {
    filing: "Vendor Payment",
    form: "1099",
    period: "Jan-Dec",
    dueDate: "Jan 31, 2024",
    status: "waiting for data",
  },
  {
    filing: "State Franchise tax",
    form: "DFT",
    period: "Jan-Dec",
    dueDate: "Oct 31, 2024",
    status: "waiting for data",
  },
  {
    filing: "Income Tax",
    form: "Multiple",
    period: "Apr-Mar",
    dueDate: "Apr 15, 2024",
    status: "waiting for data",
  },
  {
    filing: "Foreign Accounts",
    form: "FBAR",
    period: "Jan-Dec",
    dueDate: "Apr 15, 2024",
    status: "waiting for data",
  },
  {
    filing: "Ownership Report",
    form: "BOIR",
    period: "Jan-Dec",
    dueDate: "Jan 1, 2024",
    status: "waiting for data",
  },
];

const initialState = {
  isSubscribed: false,
  hasRequstedQuotation: false,
  hasCompletedCompliance: false,
  isEntityDataVerified: false,
  isSubsidiaryDataVerified: false,
  selectedYear: 2023,
  complianceList: complianceList,
};

const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    setSubscriptionStatus(state, action) {
      state.isSubscribed = action.payload;
    },
    setComplianceCompletionStatus(state, action) {
      state.hasCompletedCompliance = action.payload;
    },
    setQuotationRequestStatus(state, action) {
      state.hasRequstedQuotation = action.payload;
    },
    setComplianceListData(state, action) {
      state.complianceList = [...action.payload];
    }
  },
});

export const complianceActions = complianceSlice.actions;

export default complianceSlice.reducer;
