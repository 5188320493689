import {
  CustomTable,
  TableData,
  TableHeader,
  TableRow,
} from "../../UI/Table/CustomTable";
import styles from "./ComplianceList.module.css";
import { TBL__COMPLIANCE_LIST } from "../../../consts/TextConstants";
import { useSelector } from "react-redux";
const ComplianceList = ({ ...props }) => {
  const { complianceList } = useSelector((state) => state.compliance);
  return (
    <div className={props.className}>
      <CustomTable>
        <thead>
          <TableRow>
            {Object.values(TBL__COMPLIANCE_LIST).map((item) => (
              <TableHeader key={`compliance-header-${item}`}>
                {item}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {complianceList.map((complianceItem, row) => (
            <TableRow key={`compliance-row-${row}`}>
              {Object.keys(TBL__COMPLIANCE_LIST).map((key) => (
                <TableData
                  key={`compliance-data-${row}-${complianceItem[key]}`}
                >
                  {complianceItem[key]}
                </TableData>
              ))}
            </TableRow>
          ))}
          <TableRow></TableRow>
        </tbody>
      </CustomTable>
    </div>
  );
};

export default ComplianceList;
