import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navigaionActions } from "../MainNavigation/reducers/navigation-slice";

const TentativeContent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(navigaionActions.setNavigationTitle(""));
  }, []);
  return (<div></div>);
};

export default TentativeContent;