export const PRIVACY_POLICY_CONTENT = () => {
  return (
    <>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>1.  Introduction </h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
        Welcome to MERZE! We prioritize your privacy and are dedicated to protecting your personal
        information. This Privacy Policy explains how we collect, use, and safeguard your data when you visit
        our website. Using our website, you consent to the practices outlined in this Policy.
      </p>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>2. Information We Collect </h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '0.2rem', marginTop: '2rem' }}>When you visit our website, we may gather the following types of information: </p>
      <ul style={{ listStyleType: 'disc', marginBottom: '4rem', marginTop: '1rem', marginLeft: '3rem' }}>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Personal Information: </strong> This includes information that can identify you personally, such as your
          name, email address, phone number, and other contact details you provide when filling out
          forms or contacting us.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Non-Personal Information: </strong>
          This includes data that does not personally identify you, such as
          your IP address, browser type, operating system, and usage patterns on our website.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Cookies and Tracking Technologies: </strong>
          We may utilize cookies and similar tracking technologies
          to collect information about your interactions with our website. This helps us enhance your
          experience and tailor content to your preferences.
        </li>
      </ul>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>3. How We Use Your Information</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '0.5rem', marginTop: '2rem' }}>We use the information we collect for the following purposes:</p>
      <ul style={{ listStyleType: 'disc', marginBottom: '4rem', marginTop: '1rem', marginLeft: '3rem' }}>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>To Provide Services: </strong>
          We use your personal information to respond to inquiries, provide
          consulting services, and manage our business relationships.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>To Improve the Site: </strong>
          Technical information and cookies help us analyze how visitors use the
          Site, allowing us to enhance its functionality and content.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>To Communicate with You: </strong>
          We may use your contact information to send you updates,
          newsletters, or other communications related to our services. You can opt out of these
          communications at any time.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Legal Compliance: </strong>
          We may use your information to comply with legal obligations or protect
          our rights and interests.
        </li>
      </ul>


      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>4. Sharing Your Information</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '0.5rem', marginTop: '2rem' }}>
        We do not sell, trade, or otherwise transfer your personal information to outside parties without
        your consent, except in the following circumstances:
      </p>
      <ul style={{ listStyleType: 'disc', marginBottom: '4rem', marginTop: '1rem', marginLeft: '3rem' }}>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Service Providers: </strong>
          We may share your information with trusted third-party service providers
          who assist us in operating our website, conducting our business, or providing services to you.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Legal Requirements: </strong>
          We may disclose your information if required by law, court order, or
          governmental regulation or to protect the rights, property, or safety of Merze, our users, or
          others.
        </li>
      </ul>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>5. Data Security</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
        We implement various security measures to protect your personal information. However, no method
        of transmission over the Internet or electronic storage is entirely secure, and we cannot guarantee
        absolute security.
      </p>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>6. Third-Party Links </h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>Our website may contain links to third-party websites. These external sites have privacy policies; we
        are not responsible for their content or practices. </p>


      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>7. Your Choices</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '0.5rem', marginTop: '2rem' }}>
        You have several rights regarding your personal information:
      </p>
      <ul style={{ listStyleType: 'disc', marginBottom: '4rem', marginTop: '1rem', marginLeft: '3rem' }}>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Access and Correction: </strong>
          You can access and update your personal information by contacting
          us directly.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Opt-Out: </strong>
          You can opt out of receiving marketing communications from us by following the
          unsubscribe instructions in our emails or by contacting us.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Cookies: </strong>
          Most web browsers are set to accept cookies by default, but you can adjust your
          browser settings to decline cookies if you prefer.
        </li>
      </ul>


      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>8. Children’s Privacy</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
        Our website is not intended for children under 13. We do not knowingly collect personal information
        from children under 13. If we discover that we have inadvertently collected such information, we will
        take steps to delete it promptly.
      </p>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>9. Terms and Conditions</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
        Please refer to our Terms and Conditions page for detailed information about the rules and
        guidelines for using our website.
      </p>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>10. Pricing Policy</h2>
      <ul style={{ listStyleType: 'disc', marginBottom: '4rem', marginTop: '1rem', marginLeft: '3rem' }}>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Consulting Services: </strong>
          The prices for our consulting services are outlined on the Site or
          provided upon request. All prices are in Dollars ($) and are subject to change without notice.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Billing and Payment: </strong>
          Unless otherwise agreed upon, payment for services is typically
          required in advance. We accept various payment methods, including RazorPay. Any
          additional charges, such as taxes or fees, will be communicated to you before finalizing the
          transaction.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Refunds/Cancellations: </strong>
          Our refund policy aligns with the products and services available on
          our website. Refund requests will be processed within 5-7 working days, and the amount will
          be credited to your bank account within the specified timeline.
        </li>
        <li style={{ fontFamily: "SpartanMedium", fontSize: '1.6rem', color: '#000000', lineHeight: '1.8', }}>
          <strong style={{ fontFamily: "SpartanSemiBold", fontSize: '1.6rem' }}>Pricing Changes: </strong>
          We reserve the right to adjust pricing at any time. Changes will be reflected
          on the Site, and any agreed-upon rates for existing contracts will remain unchanged until the
          end of the contract period. </li>
      </ul>




      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>11. Contact Us</h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
        If you have any questions or concerns about this Privacy Policy or any other inquiries, please contact us at:
      </p>
      <strong style={{ display: 'block', fontFamily: "SpartanSemiBold", fontSize: '1.6rem', marginBottom: '4rem' }}>
        Email: <a href="mailto:support@merze.io" style={{ color: '#3498db' }}>hello@merze.io</a>
      </strong>

      <h2 style={{ color: '#000000', fontFamily: "SpartanBold", fontSize: '2rem' }}>12.  Changes to This Privacy Policy </h2>
      <p style={{ fontFamily: 'SpartanMedium', color: '#000000', fontSize: '1.6rem', lineHeight: '1.6', marginBottom: '4rem', marginTop: '2rem' }}>
        We may update this Privacy Policy occasionally to reflect changes in our practices or for other
        operational, legal, or regulatory reasons. We encourage you to review this page periodically for the
        most up-to-date information on our privacy practices.
      </p>
    </>
  );
};












































// export const PRIVACY_POLICY_CONTENT = () => {
//   return (
//     <>
//       <h1>Interpretation and Definitions</h1>
//       <br />
//       <h2>Interpretation</h2>
//       <br />
//       <p>
//         The words of which the initial letter is capitalized have meanings
//         defined under the following conditions. The following definitions shall
//         have the same meaning regardless of whether they appear in singular or
//         in plural. Definitions For the purposes of these Terms and Conditions:
//         Affiliate means an entity that controls, is controlled by or is under
//         common control with a party, where "control" means ownership of 50% or
//         more of the shares, equity interest or other securities entitled to vote
//         for election of directors or other managing authority. Country refers
//         to: Delaware, United States Company (referred to as either "the
//         Company", "We", "Us" or "Our" in this Agreement) refers to Corporation,
//         23d. Device means any device that can access the Service such as a
//         computer, a cellphone or a digital tablet. Service refers to the
//         Website. Terms and Conditions (also referred as "Terms") mean these
//         Terms and Conditions that form the entire agreement between You and the
//         Company regarding the use of the Service. Third-party Social Media
//         Service means any services or content (including data, information,
//         products or services) provided by a third-party that may be displayed,
//         included or made available by the Service. Website refers to Merze,
//         accessible from http://www.merze.io You means the individual accessing
//         or using the Service, or the company, or other legal entity on behalf of
//         which such individual is accessing or using the Service, as applicable.
//       </p>
//       <br />
//       <h2>Acknowledgment</h2>
//       <p>
//         These are the Terms and Conditions governing the use of this Service and
//         the agreement that operates between You and the Company. These Terms and
//         Conditions set out the rights and obligations of all users regarding the
//         use of the Service. Your access to and use of the Service is conditioned
//         on Your acceptance of and compliance with these Terms and Conditions.
//         These Terms and Conditions apply to all visitors, users and others who
//         access or use the Service. By accessing or using the Service You agree
//         to be bound by these Terms and Conditions. If You disagree with any part
//         of these Terms and Conditions then You may not access the Service. You
//         represent that you are over the age of 18. The Company does not permit
//         those under 18 to use the Service. Your access to and use of the Service
//         is also conditioned on Your acceptance of and compliance with the
//         Privacy Policy of the Company. Our Privacy Policy describes Our policies
//         and procedures on the collection, use and disclosure of Your personal
//         information when You use the Application or the Website and tells You
//         about Your privacy rights and how the law protects You. Please read Our
//         Privacy Policy carefully before using Our Service.
//       </p>
//       <p>
//         We strongly advise You to read the terms and conditions and privacy
//         policies of any third-party web sites or services that You visit.
//       </p>
//       <br />
//       <h2>Termination</h2>
//       <p>
//         We may terminate or suspend Your access immediately, without prior
//         notice or liability, for any reason whatsoever, including without
//         limitation if You breach these Terms and Conditions. Upon termination,
//         Your right to use the Service will cease immediately.
//       </p>
//       <br />
//       <h2>Limitation of Liability</h2>
//       <p>
//         Notwithstanding any damages that You might incur, the entire liability
//         of the Company and any of its suppliers under any provision of this
//         Terms and Your exclusive remedy for all of the foregoing shall be
//         limited to the amount actually paid by You through the Service or 100
//         USD if You haven't purchased anything through the Service. To the
//         maximum extent permitted by applicable law, in no event shall the
//         Company or its suppliers be liable for any special, incidental,
//         indirect, or consequential damages whatsoever (including, but not
//         limited to, damages for loss of profits, loss of data or other
//         information, for business interruption, for personal injury, loss of
//         privacy arising out of or in any way related to the use of or inability
//         to use the Service, third-party software and/or third-party hardware
//         used with the Service, or otherwise in connection with any provision of
//         this Terms), even if the Company or any supplier has been advised of the
//         possibility of such damages and even if the remedy fails of its
//         essential purpose. Some states do not allow the exclusion of implied
//         warranties or limitation of liability for incidental or consequential
//         damages, which means that some of the above limitations may not apply.
//         In these states, each party's liability will be limited to the greatest
//         extent permitted by law.
//       </p>
//       <br />
//       <h2>Disclaimer</h2>
//       <p>
//         The Service is provided to You "AS IS" and "AS AVAILABLE" and with all
//         faults and defects without warranty of any kind. To the maximum extent
//         permitted under applicable law, the Company, on its own behalf and on
//         behalf of its Affiliates and its and their respective licensors and
//         service providers, expressly disclaims all warranties, whether express,
//         implied, statutory or otherwise, with respect to the Service, including
//         all implied warranties of merchantability, fitness for a particular
//         purpose, title and non-infringement, and warranties that may arise out
//         of course of dealing, course of performance, usage or trade practice.
//         Without limitation to the foregoing, the Company provides no warranty or
//         undertaking, and makes no representation of any kind that the Service
//         will meet Your requirements, achieve any intended results, be compatible
//         or work with any other software, applications, systems or services,
//         operate without interruption, meet any performance or reliability
//         standards or be error free or that any errors or defects can or will be
//         corrected.
//       </p>
//       <p>
//         Without limiting the foregoing, neither the Company nor any of the
//         company's provider makes any representation or warranty of any kind,
//         express or implied: (i) as to the operation or availability of the
//         Service, or the information, content, and materials or products included
//         thereon; (ii) that the Service will be uninterrupted or error-free;
//         (iii) as to the accuracy, reliability, or currency of any information or
//         content provided through the Service; or (iv) that the Service, its
//         servers, the content, or e-mails sent from or on behalf of the Company
//         are free of viruses, scripts, trojan horses, worms, malware, timebombs
//         or other harmful components. Some jurisdictions do not allow the
//         exclusion of certain types of warranties or limitations on applicable
//         statutory rights of a consumer, so some or all of the above exclusions
//         and limitations may not apply to You. But in such a case the exclusions
//         and limitations set forth in this section shall be applied to the
//         greatest extent enforceable under applicable law.
//       </p>
//       <br />
//       <h2>Governing Law</h2>
//       <p>
//         The laws of the Country, excluding its conflicts of law rules, shall
//         govern this Terms and Your use of the Service. Your use of the
//         Application may also be subject to other local, state, national, or
//         international laws.
//       </p>
//       <br />
//       <h2>Disputes Resolution</h2>
//       <p>
//         If You have any concern or dispute about the Service, You agree to first
//         try to resolve the dispute informally by contacting the Company.
//       </p>
//       <br />
//       <h2>For European Union (EU) Users</h2>
//       <p>
//         If You are a European Union consumer, you will benefit from any
//         mandatory provisions of the law of the country in which You are
//         resident.
//       </p>
//       <br />
//       <h2>United States Legal Compliance</h2>
//       <p>
//         United States Legal Compliance You represent and warrant that (i) You
//         are not located in a country that is subject to the United States
//         government embargo, or that has been designated by the United States
//         government as a "terrorist supporting" country, and (ii) You are not
//         listed on any United States government list of prohibited or restricted
//         parties.
//       </p>
//       <br />
//     </>
//   );
// };
