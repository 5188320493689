import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./TopNavBar.module.css";
import AccountDropDown from "./AccountDropDown";
import Button from "../../UI/Button/Button";
import accountIcon from "/assets/topbar/AccountProfile.svg";
import notificationIcon from "/assets/topbar/NotificationBell.svg";
import themeSwitchIcon from "/assets/dashboard/ThemeSwitch.svg";
import { useLocation } from "react-router-dom";
import { navMenuFeatureItemsList } from "../SideBar/NavigationLists";
import {useOutsideClick} from "../../../hooks/use-outsideClick";

const TopNavBar = ({ ...props }) => {
  const ref = useRef();
  const location = useLocation();

  const { userDetails } = useSelector((state) => state.auth);
  const { navigationTitle } = useSelector((state) => state.navigation);

  const [showAccountDropDown, setShowAccountDropDown] = useState(false);

  useOutsideClick({
    ref,
    handler: () => {
      setShowAccountDropDown(false);
    },
  });
  // useEffect(() => {
  //   const checkIfClickedOutside = (event) => {
  //     if (
  //       showAccountDropDown &&
  //       ref.current &&
  //       !ref.current.contains(event.target)
  //     ) {
  //       setShowAccountDropDown(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", checkIfClickedOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [showAccountDropDown]);

  const handleAccountClick = () => {
    setShowAccountDropDown(!showAccountDropDown);
  };

  const classes = `${styles.topbar}`;
  // const classes = `${styles.topbar} ${
  //   location.pathname == "/home/entities" ? styles.topbar_white_bg : ""
  // }`;

  return (
    <div className={classes}>
      <div className={styles.page_title}>{navigationTitle}</div>

      <div className={styles.right_side}>
        {/* {location.pathname == navMenuFeatureItemsList.home.path && (
          <div className={styles.welcome_note}>Welcome back! {userDetails?.userName}</div>
        )} */}

        <Button className={styles.action}>
          <img src={notificationIcon} alt="" />
        </Button>

        <Button
          className={styles.action}
          onClick={handleAccountClick}
          ref={ref}
        >
          <img src={accountIcon} alt="" />
          {showAccountDropDown && <AccountDropDown onLogout={props.onLogout} />}
        </Button>

        {/* <Button className={styles.theme}>
          <img src={themeSwitchIcon} alt="" />
        </Button> */}
      </div>
    </div>
  );
};

export default TopNavBar;
