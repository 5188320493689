import { useRef, useState, useEffect } from "react";
import {
  FlagEmoji,
  defaultCountries,
  usePhoneInput,
} from "react-international-phone";
import { motion } from "framer-motion";
import styles from "./PhoneInput.module.css";
import arrowDown from "/assets/common/ArrowDown_Filled.svg";
import PhoneInputCountryDropdown from "./PhoneInputCountryDropdown";

function PhoneInput({ value, onChange, labelTitle, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
      forceDialCode: true,
    });

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleSelectCountry = (value) => {
    setCountry(value);
    setIsOpen(false);
  };
  const arrowVariants = { open: { rotate: -180 }, close: { rotate: 0 } };

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.container} ref={ref}>
      <label htmlFor="phone_number" className={styles.label}>
        {labelTitle}
      </label>
      <div className={styles.input__container}>
        <div className={styles.country_flag} onClick={handleClick}>
          <FlagEmoji iso2={country} className={styles.img_flag} />
          <motion.img
            key={`arrow-${isOpen ? "up" : "down"}`}
            variants={arrowVariants}
            initial={isOpen ? "close" : "open"}
            animate={isOpen ? "open" : "close"}
            transition={{ duration: 1, type: "spring" }}
            src={arrowDown}
            className={styles.arrow}
            alt=""
          />
        </div>
        <input
          name="phone_number"
          className={styles.input__phone_number}
          type="tel"
          value={phone}
          onChange={handlePhoneValueChange}
          ref={inputRef}
        />
      </div>
      {isOpen && <PhoneInputCountryDropdown onSelect={handleSelectCountry} />}
    </div>
  );
}

export default PhoneInput;
