export const getAuthToken = () => {
    const token = sessionStorage.getItem("token");
    return token;
};

export const setAuthToken = (token) => {
    sessionStorage.setItem("token", token);
};

export const removeAuthToken = () => {
    sessionStorage.removeItem("token");
};

export const getUser =() => {
    const user = sessionStorage.getItem("user");
    return user;
};

export const setUser = (user) => {
    sessionStorage.setItem("user", user);
};

export const removeUser = () => {
    sessionStorage.removeItem("user");
};