import { createSlice } from "@reduxjs/toolkit";
import { combineAddressString } from "../../../../utils/common-utils";
import { isNonUSCountry } from "../../../../utils/validations";
import { LIST__OFFICER_TITLES } from "../../../../consts/TextConstants";
const initialState = {
  primaryEntity: null,
  selectedEntity: null,
  entities: [],
  parentEntities: [],
  foreignStates: [],
  selectedForeignState: 0,
  currentEditForm: null,
  boardOfficers: [],
  boardDirectors: [],
  availableBoardOfficerTitles: [],
};

const parseEntityData = (item, allEntities) => {
  if (!item) {
    return item;
  }
  const getParentEntity = (parentEntityId) =>
    allEntities.find((entity) => entity?.entityId === parentEntityId);

  return {
    brandName: item.brand_name,
    entityName: item.entity_name,
    entityStructure: item.entity_structure,
    entityType: item.entity_type,
    entityId: item.entity_id,
    hasOtherEntity: item.has_other_entity,
    hasOtherState: item.has_other_state,
    incorporationDate: item.incorporation_date,
    incorporationState: item.incorporation_state,
    incorporationCountry: item.incorporation_country,
    isPrimary: item.isPrimary,
    isSecondary: item.isSecondary,
    isEditable: item.isEditable,
    incorporationCertificate: item.incorporation_certificate_document,
    isRegistrationEditable: item.isRegistrationEditable,
    isIrsEditable: item.isIrsEditable,
    isAgentEditable: item.isRegisterAgentEditable,
    agentInfo: {
      agentAddress:
        item.registered_agent_street == null
          ? ""
          : combineAddressString(
              item.registered_agent_street,
              item.registered_agent_city,
              item.registered_agent_state,
              item.registered_agent_zip,
              item.registered_agent_country
            ),
      agentName: item.registered_agent_name,
    },
    registrationInfo: {
      authorizedShares: item.authorized_shares,
      businessAddress:
        item.business_address_street == null
          ? ""
          : combineAddressString(
              item.business_address_street,
              item.business_address_city,
              item.business_address_state,
              item.business_address_zip,
              item.business_address_country
            ),
      businessAddressParams: {
        street: item.business_address_street,
        city: item.business_address_city,
        state: item.business_address_state,
        zipCode: item.business_address_zip,
        country: item.business_address_country,
      },
      stateId: item.state_id,
      parValue: 1 * Number.parseFloat(item.par_value),
      parentPercentage: item.perc_owned_by_parent,
      parentShares: item.shares_owned_by_parent,
      subsidizationDate: item.subsidization_date,
      isSubsidiary: item.isSubsidiary,
      parent: item.isSubsidiary
        ? getParentEntity(item.parent_entity_id)?.entityName
        : "",
    },
    irsInfo: {
      currentTaxForm: item.current_tax_form,
      currentTaxYear: item.current_tax_year,
      einNumber: item.ein_number,
      einLetter: item.ein_letter_document,
    },
  };
};
const entitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    handlePrimaryEntityData(state, action) {
      state.primaryEntity = parseEntityData(
        action.payload?.primaryEntity,
        state.entities
      );
    },

    setPrimaryEntityData(state, action) {
      state.primaryEntity = parseEntityData(action.payload, state.entities);
    },

    setSelectedEntity(state, action) {
      state.selectedEntity = parseEntityData(action.payload?.entity, [
        ...state.entities,
      ]);
    },

    handleEntityListData(state, action) {
      let entities = [];
      action.payload.Entities.forEach((item) => {
        entities.push({
          entityName: item.entity_name,
          entityId: item.entity_id,
          entityType: item.entity_type,
          state: item.incorporation_state,
          incorporationDate: item.incorporation_date,
          country: item.incorporation_country,
          isBasicComplete: !item.isRegistrationEditable,
          isFullComplete: !isNonUSCountry(item.incorporation_country)
            ? !(
                item.isRegistrationEditable ||
                item.isIrsEditable ||
                item.isRegisterAgentEditable
              )
            : !item.isRegistrationEditable,
        });
      });

      state.entities = entities;
    },

    setEditForm(state, action) {
      state.currentEditForm = action.payload;
    },

    setSelectedForeignState(state, action) {
      state.selectedForeignState = action.payload;
    },

    setForeignStates(state, action) {
      const foreignStates = [];
      action.payload.forEach((item) => {
        let foreignState = {
          state: item.foreign_state,
          nexusTypes: item.foreign_nexus_type[0].split(","),
          isRegisteredInState: item.isRegisteredInState,
          stateId: item.foreign_state_id,
          registrationCertificate: item.registeration_certificate_document,
          businessAddress:
            item.foreign_business_address_street == null
              ? ""
              : combineAddressString(
                  item.foreign_business_address_street,
                  item.foreign_business_address_city,
                  item.foreign_business_address_state,
                  item.foreign_business_address_zip,
                  item.foreign_business_address_country
                ),
          agentName: item.foreign_registered_agent_name,
          agentAddress:
            item.foreign_registered_agent_street == null
              ? ""
              : combineAddressString(
                  item.foreign_registered_agent_street,
                  item.foreign_registered_agent_city,
                  item.foreign_registered_agent_state,
                  item.foreign_registered_agent_zip,
                  item.foreign_registered_agent_country
                ),
        };
        foreignStates.push(foreignState);
      });
      state.foreignStates = foreignStates;
    },

    handleBoardMembers(state, action) {
      const { directors, officers } = action.payload;

      let boardDirectors = [];
      directors.forEach((item) => {
        boardDirectors.push({
          name: item.board_member_name,
          phoneNumber: item.board_member_phone,
          street: item.board_business_street,
          zipCode: item.board_business_zip,
          city: item.board_business_city,
          state: item.board_business_state,
          country: item.board_business_country,
        });
      });
      state.boardDirectors = boardDirectors;

      let boardOfficers = [];
      let existingOfficerTitles = [];
      officers.forEach((item) => {
        existingOfficerTitles.push(item.board_member_title);
        boardOfficers.push({
          name: item.board_member_name,
          title: item.board_member_title,
          phoneNumber: item.board_member_phone,
          street: item.board_business_street,
          zipCode: item.board_business_zip,
          city: item.board_business_city,
          state: item.board_business_state,
          country: item.board_business_country,
        });
      });
      state.boardOfficers = boardOfficers;
      state.availableBoardOfficerTitles = LIST__OFFICER_TITLES.filter(
        (title) => !existingOfficerTitles.includes(title)
      );
    },

    setParentEntities(state, action) {
      let parentEntities = {};
      action.payload?.entities.forEach((item) => {
        parentEntities = {
          ...parentEntities,
          [item.entity_name]: item.entity_id,
        };
      });
      state.parentEntities = parentEntities;
    },
  },
});

export const entityActions = entitySlice.actions;

export default entitySlice.reducer;
