import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigaionActions } from "../../MainNavigation/reducers/navigation-slice";
import ComplianceDetails from "./ComplianceDetails";
import SubscriptionPage from "../Subscription/SubscriptionPage";

const CompliancePage = () => {
  const { hasCompletedCompliance, hasRequstedQuotation } = useSelector(
    (state) => state.compliance
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(navigaionActions.setNavigationTitle(""));
  }, []);
  return (
    <>
      {(hasCompletedCompliance || hasRequstedQuotation) && (
        <ComplianceDetails />
      )}
      {!hasCompletedCompliance && !hasRequstedQuotation && (
        <SubscriptionPage complianceYear={2023} />
      )}
    </>
  );
};

export default CompliancePage;
