import { METHOD_GET, URL_GET_USER_FLAGS, URL__GET_USER_DETAILS } from "../../../consts/APIConstants";
import { apiSlice } from "../../../store/api-slice";
import { dashboardActions } from "../reducers/dashboard-slice";
import {authActions} from "../../../store/auth-slice"
import {loginActions} from "../../../store/login-slice"
const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlags: builder.query({
        query: (args) => {
          return {
            url: URL_GET_USER_FLAGS,
            method: METHOD_GET,
          };
        },
        async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(dashboardActions.setUserOnboardStatus(data?.flags?.isOnboardingComplete));
            dispatch(dashboardActions.setUserBasicInfoComplete(data?.flags?.isBasicInfoComplete));
            dispatch(loginActions.setShowUserDetailsForm(!data?.flags?.isBasicInfoComplete));

          } catch (error) {}
        },
      }),
      getUserDetails: builder.query({
        query: () => ({
          url: URL__GET_USER_DETAILS,
          method: METHOD_GET,
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(authActions.setUserDetails(data))
          } catch (error) {}
        },
      }),
  }),
});

export const { useGetFlagsQuery, useLazyGetFlagsQuery, useGetUserDetailsQuery } = dashboardApiSlice;
