import { useState } from "react";
import {
  BTN__SUBMIT,
  LIST__ACCOUNTING_METHOD,
  LIST__ACCOUNTING_SCOPE,
} from "../../../consts/TextConstants";
import Button from "../../UI/Button/Button";
import Dropdown from "../../UI/Dropdown/Dropdown";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import styles from "./GetQuoteOverlay.module.css";
import addIcon from "/assets/common/Plus_White.svg";
import { MONTH_NAMES_SHORT } from "../../../consts/Constants";
import InputCheckBox from "../../UI/Input/InputCheckBox";

const InputItem = ({ children, labelTitle }) => {
  return (
    <div className={styles.input__item}>
      <div className={styles.label}>{labelTitle}</div>
      <div className={styles.input}>{children}</div>
    </div>
  );
};

const GetQuoteOverlay = ({ className, ...props }) => {
  const [accountingMethod, setAccountingMethod] = useState("");
  const [accountingScope, setAccountingScope] = useState("");

  const [fromYear, setFromYear] = useState("");
  const [fromMonth, setFromMonth] = useState("");

  const [toYear, setToYear] = useState("");
  const [toMonth, setToMonth] = useState("");

  const [adminAccess, setAdminAccess] = useState(false);

  const handleAdminAccessChange = (event) => {
    setAdminAccess(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.onClose(true);
  };

  return (
    <OverlayRegular
      keyName="edit-overlay"
      className={`${styles.overlay} ${className}`}
      blurValue="0.15rem"
      onClose={() => props.onClose(false)}
    >
      <div className={styles.title}>Get Quote</div>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputs__container}>
          <InputItem labelTitle="Accounting Scope">
            <Dropdown
              className={styles.dropdown}
              placeholder=""
              options={LIST__ACCOUNTING_SCOPE}
              selected={accountingScope}
              onSelect={setAccountingScope}
              id="accounting-scope"
              dropdownSize={styles.dropdown}
            />
          </InputItem>

          <InputItem labelTitle="Accounting Method">
            <Dropdown
              className={styles.dropdown}
              placeholder=""
              options={LIST__ACCOUNTING_METHOD}
              selected={accountingMethod}
              onSelect={setAccountingMethod}
              id="accounting-method"
              dropdownSize={styles.dropdown}
            />
          </InputItem>

          <InputItem labelTitle="From">
            <Dropdown
              className={styles.dropdown_year}
              placeholder="Year"
              options={["2024", "2023", "2022", "2021", "2020"]}
              selected={fromYear}
              onSelect={setFromYear}
              id="from-year"
              dropdownSize={styles.dropdown_year}
              dropdownListSize={styles.dropdown_list}
            />
            <Dropdown
              className={styles.dropdown_year}
              placeholder="Month"
              options={MONTH_NAMES_SHORT}
              selected={fromMonth}
              onSelect={setFromMonth}
              id="from-month"
              dropdownSize={styles.dropdown_year}
              dropdownListSize={styles.dropdown_list}
            />
          </InputItem>

          <InputItem labelTitle="To">
            <Dropdown
              className={styles.dropdown_year}
              placeholder="Year"
              options={["2024", "2023", "2022", "2021", "2020"]}
              selected={toYear}
              onSelect={setToYear}
              id="to-year"
              dropdownSize={styles.dropdown_year}
              dropdownListSize={styles.dropdown_list}
            />
            <Dropdown
              className={styles.dropdown_year}
              placeholder="Month"
              options={MONTH_NAMES_SHORT}
              selected={toMonth}
              onSelect={setToMonth}
              id="to-month"
              dropdownSize={styles.dropdown_year}
              dropdownListSize={styles.dropdown_list}
            />
          </InputItem>
        </div>

        <div className={styles.footer}>
          <InputCheckBox
            title="Give Merze Admin Access"
            onChange={handleAdminAccessChange}
            className={styles.checkbox}
            labelStyle={styles.label_checkbox}
            checked={adminAccess}
          />

          <Button buttonstyle="default-blue" className={styles.btn__submit}>
            {BTN__SUBMIT}
          </Button>
        </div>
      </form>
    </OverlayRegular>
  );
};

export default GetQuoteOverlay;
