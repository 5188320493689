// serviceConstants.js
import { getImageURL } from '../../../MainNavigation/SideBar/NavigationLists';

export const serviceActionTypes = {
    FORM: 'FORM',
    MESSAGE: 'MESSAGE',
    DOWNLOAD: 'DOWNLOAD',
    UPLOAD: 'UPLOAD',
    CONFIRMATION: 'CONFIRMATION',
    MULTIPLE: 'MULTIPLE',
    MOREINFO: 'MOREINFO',
};

export const SERVICE_ACTIONS = {

    "trc_fill_details": {
        type: serviceActionTypes.FORM,
        label: 'FILL DETAILS',
        actionItemStyle: "yellow",
        icon: getImageURL("/assets/subscription/links/DoubleRightArrow.svg"),
    },

    "trc_drafting_application": {
        type: serviceActionTypes.MESSAGE,
        label: 'DRAFTING APPLICATION',
        actionItemStyle: "yellow",
    },

    "trc_signed_copy": {
        type: serviceActionTypes.MULTIPLE,
        actions: [
            {
                type: serviceActionTypes.DOWNLOAD,
                label: 'APPLICATION',
                actionItemStyle: "white",
                icon: getImageURL("/assets/common/DownloadArrow.svg"),
            },
            {
                type: serviceActionTypes.UPLOAD,
                label: 'SIGNED COPY',
                actionItemStyle: "SIG_COPY", //this 
                icon: getImageURL("/assets/subscription/links/UploadArrow.svg"),
            }
        ]
    },
    "trc_submitting_application": {
        type: serviceActionTypes.MESSAGE,
        label: 'SUBMITTING APPLICATION',
        actionItemStyle: "yellow"
    },
    "trc_application_submitted": {
        type: serviceActionTypes.MESSAGE,
        label: 'APPLICATION SUBMITTED',
        additionalInfo: 'Dept. takes 60-90 days to process',
        actionItemStyle: "default",
    },


    "trc_recived": {
        type: serviceActionTypes.MULTIPLE,
        actions: [
            {
                type: serviceActionTypes.MESSAGE,
                label: 'IN PROGRESS WITH IRS',
                actionItemStyle: "yellow",
                showMoreInfo: true,
                moreInfoContent: 'Dept. takes 60-90 days to process',
            },
            {
                type: serviceActionTypes.MESSAGE,
                label: 'TRC Received?',
                actionItemStyle: "TRC_RECIVED",
            },
            {
                type: serviceActionTypes.CONFIRMATION,
                confiramtionLabel: 'YES',
                actionItemStyle: 'blue',
                icon: '',
            }
        ]
    },

    "trc_upload_trc": {
        type: serviceActionTypes.UPLOAD,
        label: 'UPLOAD TRC',
        actionItemStyle: "UPLOAD_TRC",
        icon: getImageURL("/assets/subscription/links/UploadArrow.svg"),

    },
    "trc_copies": {
        type: serviceActionTypes.DOWNLOAD,
        label: 'TRC COPIES',
        actionItemStyle: "TRC_COPIES",
        icon: getImageURL("/assets/common/DownloadArrow.svg"),
    },
    "arr_upload_document": {
        type: serviceActionTypes.UPLOAD,
        label: 'UPLOAD DOCUMENTS',
        actionItemStyle: "violet", //this
        icon: getImageURL("/assets/subscription/links/UploadArrow.svg"),
    },
    "arr_drafting_report": {
        type: serviceActionTypes.MESSAGE,
        label: 'DRAFTING REPORT',
        actionItemStyle: "blue",
    },
    "arr_approve": {
        type: serviceActionTypes.MULTIPLE,
        actions: [
            {
                type: serviceActionTypes.DOWNLOAD,
                label: "DRAFT REPORT",
                icon: getImageURL("/assets/common/DownloadArrow.svg"),
                actionItemStyle: "DR",
            },
            {
                type: serviceActionTypes.CONFIRMATION,
                confiramtionLabel: "APPROVE",
                icon: getImageURL("/assets/subscription/links/DoubleRightIcon.svg"),
                actionItemStyle: "green",
            }
        ]
    },


    "arr_audit_review_report": {
        type: serviceActionTypes.DOWNLOAD,
        label: 'AUDIT REVIEW REPORT',
        actionItemStyle: "ARR",
        icon: getImageURL("/assets/common/DownloadArrow.svg"),
    },

    "analyzing": {
        type: serviceActionTypes.MESSAGE,
        label: 'IN PROGRESS',
        actionItemStyle: "yellow",
    },
    "execution": {
        type: serviceActionTypes.MESSAGE,
        label: 'IN PROGRESS',
        actionItemStyle: "yellow",
    },
};




