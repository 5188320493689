import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api-slice";
import loginReducer from "./login-slice";
import authReducer from "./auth-slice";
import onboardReducer from "../components/Onboard/reducers/onboard-slice";
import dashboardReducer from "../components/Dashboard/reducers/dashboard-slice";
import entityReducer from "../components/Main/Entities/reducers/entity-slice";
import capTableReducer from "../components/Main/CapTable/reducers/capTable-slice";
import navigationReducer from "../components/MainNavigation/reducers/navigation-slice";
import complianceReducer from "../components/Main/Compliance/reducers/compliance-slice";
import subscriptionReducer from "../components/Main/Subscription/reducers/subscription-slice";
import financeReducer from "../components/Main/Finance/reducers/finance-slice";
import servicesReducer from "../components/Main/Services/reducers/service-slice";
import serviceApplicationReducer from "../components/Main/Services/reducers/serviceApplication-slice";
const store = configureStore({
  reducer: {
    login: loginReducer,
    onboard: onboardReducer,
    auth: authReducer,
    navigation: navigationReducer,
    dashboard: dashboardReducer,
    entity: entityReducer,
    capTable: capTableReducer,
    compliance: complianceReducer,
    subscription: subscriptionReducer,
    finance: financeReducer,
    services: servicesReducer,
    serviceApplication: serviceApplicationReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: false,
});

export default store;
