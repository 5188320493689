import LoadingGradientBox from '../UI/LoadingIndicator/LoadingGradientBox';
import styles from './DashboardLoading.module.css';

const DashboardLoading = () => {
    return (
        <div className={styles.container}>
            <LoadingGradientBox className={styles.loading_top_left} direction="bottom" />
            <LoadingGradientBox className={styles.loading_bottom_left} direction="bottom" />
            <LoadingGradientBox className={styles.loading_right} direction="bottom" />
        </div>
    )
};

export default DashboardLoading;