import { MONTH_NAMES_SHORT } from "../../../consts/Constants";
import styles from "./MonthView.module.css";

const MonthView = ({ month, year, start, end, onSelect }) => {
  // const today = new Date();
  // const thisYear = today.getFullYear();
  // const thisMonth = today.getMonth();

  const startYear = start.getFullYear();
  const startMonth = start.getMonth();

  const endYear = end.getFullYear();
  const endMonth = end.getMonth();

  const handleMonthClick = (value) => {
    onSelect(value + 1);
  };

  return (
    <div className={styles.month_view}>
      {MONTH_NAMES_SHORT.map((item, index) => (
        <button
          key={item}
          className={`${styles.month_item} ${
            index + 1 == month ? styles.selected : ""
          }`}
          disabled={
            (year == endYear && index > endMonth) ||
            (year == startYear && index < startMonth)
          }
          onClick={() => handleMonthClick(index)}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default MonthView;
