import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  currentFormStep: 0,
  isFormValid: false,
  error: null,
};

const serviceApplicationSlice = createSlice({
  name: "serviceApplication",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setCurrentFormStep: (state, action) => {
      state.currentFormStep = action.payload;
    },

    resetFormData: (state) => {
      state.formData = initialState.formData;
      state.currentFormStep = initialState.currentFormStep;
      state.isFormValid = initialState.isFormValid;
      state.error = initialState.error;
    },
  },
});

export const serviceApplicationActions = serviceApplicationSlice.actions;

export default serviceApplicationSlice.reducer;

