import styles from "./Dropdown.module.css";
import arrowDown from "/assets/common/ArrowDown_Filled.svg";
import DropDownContainer from "./DropDownContainer";

const Dropdown = ({ arrowStyle, showListOnTop = false, ...props }) => {
  return (
    <DropDownContainer
      options={props.options}
      className={`${styles.dropDownContainer} ${props.className}`}
      listStyle={`${styles.dropDownList} ${props.dropdownListSize}`}
      headerStyle={`${styles.dropdownHeader} ${props.dropdownSize}`}
      arrowStyle={`${styles.arrow} ${arrowStyle}`}
      listItemStyle={`${styles.list_item} ${props.listItemStyle}`}
      arrow={arrowDown}
      {...props}
      showListOnTop={showListOnTop}
    ></DropDownContainer>
  );
};

export default Dropdown;
