import styles from "./PageIndicator.module.css";
import arrowLeftActive from "/assets/common/ArrowLeft_White.svg";
import arrowRightActive from "/assets/common/ArrowRight_White.svg";
import arrowLeftInactive from "/assets/common/ArrowLeft_Grey.svg";
import arrowRightInactive from "/assets/common/ArrowRight_Grey.svg";

const PageIndicator = ({ page = 1, pageSize, total, onPageChange }) => {
  const isPreviousDisabled = page === 1;
  const isNextDisabled = page === Math.ceil(total / pageSize);

  return (
    <div className={styles.container}>
      <div className={`${styles.previous} ${!isPreviousDisabled ? styles.active : ""}`} onClick={() => {
        if (!isPreviousDisabled) {
          onPageChange(page - 1);
        }
      }}>
        <img src={isPreviousDisabled ? arrowLeftInactive : arrowLeftActive} alt="previous" />
      </div>
      <div className={styles.page_number}>{page}</div>
      <div className={`${styles.next} ${!isNextDisabled ? styles.active : ""}`} onClick={() => {
        if (!isNextDisabled) {
          onPageChange(page + 1);
        }
      }}>
        <img src={isNextDisabled ? arrowRightInactive : arrowRightActive} alt="next" />
      </div>
    </div>
  );
};

export default PageIndicator;
