import {
  METHOD_GET,
  URL__CALLBACK_ZOHO,
  URL__GET_ACCOUNT_BALANCE,
  URL__GET_REVENUE_STATEMENT,
  URL__REDIRECT_ZOHO,
  URL__ZOHO_ADD_ADMIN_USER,
  URL__ZOHO_DISCONNECT_ADMIN_ACCESS,
  URL__ZOHO_STATUS,
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { financeActions } from "../reducers/finance-slice";

const financeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    zohoCallBack: builder.query({
      query: (args) => ({
        url: URL__CALLBACK_ZOHO,
        method: METHOD_GET,
        params: args,
      }),
    }),
    getAccountBalance: builder.query({
      query: (args) => ({
        url: URL__GET_ACCOUNT_BALANCE,
        method: METHOD_GET,
        params: args,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(financeActions.handleAccountsBalanceData(data));
        } catch (error) {
          if(error.error?.status == 404) {
            dispatch(financeActions.disconnectZoho());
          }
        }
      },
    }),
    getRevenueStatement: builder.query({
      query: (args) => ({
        url: URL__GET_REVENUE_STATEMENT,
        method: METHOD_GET,
        params: args,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(financeActions.handleRevenueStatementData(data));
        } catch (error) {}
      },
    }),
     addAdminUser: builder.query({
      query: (args) => ({
        url: URL__ZOHO_ADD_ADMIN_USER,
        method: METHOD_GET,
        params: args,
      }),
    }),
    disconnectAdmin: builder.query({
      query: (args) => ({
        url: URL__ZOHO_DISCONNECT_ADMIN_ACCESS,
        method: METHOD_GET,
        params: args,
      }),
    }),
    getZohoStatus: builder.query({
      query: (args) => ({
        url: URL__ZOHO_STATUS,
        method: METHOD_GET,
        params: args,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(financeActions.handleZohoStatus(data));
          dispatch(financeActions.connectZoho());
        } catch (error) {
          dispatch(financeActions.disconnectZoho());
        }
      },
    }),
  }),
});

export const {
  useLazyZohoCallBackQuery,
  useGetAccountBalanceQuery,
  useLazyGetAccountBalanceQuery,
  useGetRevenueStatementQuery,
  useLazyGetRevenueStatementQuery,
  useLazyAddAdminUserQuery,
  useLazyDisconnectAdminQuery,
  useGetZohoStatusQuery,
  useLazyGetZohoStatusQuery
} = financeApiSlice;
