import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authActions } from "./auth-slice";
import { BASE_URL } from "../consts/APIConstants";
import Session from "supertokens-web-js/recipe/session";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    // const token = getState().auth.token;
    // if (token) {
    //   headers.set("Authorization", `Bearer ${token}`);
    // }
    // headers.set("Access-Control-Allow-Origin", "*");
    // headers.set("Content-type", "application/json");
    // headers.set("rid", "anti-csrf");
    return headers;
  },
});

const baseQueryWithReauth1 = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus == 403) {
    //send refresh token
    const refreshResult = await baseQuery("refresh", api, extraOptions);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;

      //store the new token
      api.dispatch(authActions.setCredentials({ ...refreshResult.data, user }));

      //retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authActions.logout());
    }
  }
  return result;
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (await Session.doesSessionExist()) {
    let result = await baseQuery(args, api, extraOptions);

    return result;
  } else {
    window.alert("Session timeout. Please login again.");
    window.location.href = "/";
    return null;
  }
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
