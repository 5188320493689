import { styled } from "styled-components";
import { motion } from "framer-motion";

const StepItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
  border: none;
  font-family: "SpartanSemiBold";

  cursor: ${(props) => (props.$completed ? "pointer" : "auto")};
`;

const Horizontal = styled.div`
  position: relative;
`;
const StepNumber = styled.div`
  height: 4.6rem;
  width: 4.6rem;
  font-size: 2rem;
  border-radius: 4.6rem;

  ${(props) =>
    props.$active || props.$completed
      ? `background: linear-gradient(to left, #00e3c2, #00CC6A)`
      : `background-color: #D9D9D9`};

  color: ${(props) =>
    props.$active || props.$completed ? "#FFFFFF" : "rgba(35,35,35, 0.33)"};

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 768px) {
    height: 3.4rem;
    width: 3.4rem;
    font-size: 1.6rem;
  }
`;
const StepInstruction = styled(motion.div)`
  position: absolute;
  left: 7.8rem;
  top: 50%;
  width: 28.9rem;
  padding: 3.2rem 2.3rem;

  font-size: 1.6rem;
  line-height: 2.4rem;

  color: rgba(35, 35, 35, 0.73);
  background-color: #ffffff;
  box-shadow: 0 0 2.2rem rgba(0, 0, 0, 0.21);
  border-radius: 1.2rem;

  align-self: center;

  @media (max-height: 768px) {
    width: 25.2rem;
    left: 5.3rem;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 2.3rem 1.9rem;
  }
`;

const ProgressLine = styled.div`
  height: 9.5rem;
  width: 0.4rem;
  margin: 1.3rem auto 1.3rem 2.1rem;
  border-radius: 0.2rem;

  ${(props) =>
    props.$completed
      ? `background: linear-gradient(to top, #0047FF, #00e3c2 )`
      : `background-color: #D9D9D9`};

  @media (max-height: 768px) {
    margin-left: 1.5rem;
  }
`;

const ProgressStep = ({ step, instruction, lastStep, ...props }) => {
  return (
    <StepItem $completed={props.completed} onClick={props.onClick}>
      <Horizontal>
        <StepNumber $active={props.active} $completed={props.completed}>
          {step}
        </StepNumber>
        {props.active && (
          <StepInstruction layoutId="step-instruction" animate={{ y: -50 }}>
            {instruction}
          </StepInstruction>
        )}
      </Horizontal>
      {!lastStep && <ProgressLine $completed={props.completed} />}
    </StepItem>
  );
};

export default ProgressStep;
