import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OnboardingPage from "../Onboard/OnboardingPage.jsx";
import { onboardActions } from "../Onboard/reducers/onboard-slice.jsx";
import WelcomePage from "../Welcome/WelcomePage.jsx";
import { AnimatePresence } from "framer-motion";
import { authActions } from "../../store/auth-slice.jsx";
import { dashboardActions } from "./reducers/dashboard-slice.jsx";
import { getUser } from "../../utils/authentication.jsx";
import DashboardContent from "./DashboardContent.jsx";
import { navigaionActions } from "../MainNavigation/reducers/navigation-slice.jsx";
import { useGetPrimaryEntityQuery } from "../Main/Entities/apis/entityApi-slice.jsx";
import { useGetFlagsQuery } from "./apis/dashboardApi-slice.jsx";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showWelcome, setShowWelcome] = useState(false);

  const { isUserOnboarded } = useSelector((state) => state.dashboard);
  const { user, userDetails } = useSelector((state) => state.auth);
  const { primaryEntity } = useSelector((state) => state.entity);

  const { isLoading: isFlagsLoading } = useGetFlagsQuery();

  const { isSuccess, error, isLoading } = useGetPrimaryEntityQuery();

  useEffect(() => {
    if (!user) {
      const storedUser = getUser();
      dispatch(authActions.setUser(storedUser));
    }
    dispatch(
      navigaionActions.setNavigationTitle(
        `Welcome ${userDetails?.userName || ""}`
      )
    );
  }, [primaryEntity]);

  const handleCloseOnboard = () => {
    dispatch(dashboardActions.setUserOnboardStatus(true));
    dispatch(onboardActions.resetCollectedData());
    setShowWelcome(true);
  };

  const handleCloseWelcome = () => {
    setShowWelcome(false);
    navigate("/home/entities");
  };
  
  return (
    <>
      <DashboardContent />
      <AnimatePresence>
        {!isUserOnboarded && <OnboardingPage onClose={handleCloseOnboard} />}
      </AnimatePresence>
      <AnimatePresence>
        {showWelcome && <WelcomePage onClose={handleCloseWelcome} />}
      </AnimatePresence>
    </>
  );
};

export default DashboardPage;
