import { useRef, useState } from "react";
import { SERVICE_ACTIONS } from "./consts/ServiceConstants";
import ActionRenderer from "./ActionRenderer";
import TRCApplicationForm from "./ApplicationForms/TRCApplicationForm";
import { useUploadSignedTrcMutation, useUpdateTaskPhaseMutation } from "./apis/serviceApi-slice";
import {
  BASE_URL,
  URL__DOWNLOAD_TRC_DRAFT,
} from "../../../consts/APIConstants";
import { downloadFile } from "../../../utils/fileHandler";
import ConfirmationDialog from "../../UI/Modal/ConfirmationDialog";
import { MSG__TRC_RECEIVED_CONFIRMATION } from "../../../consts/TextConstants";

const ActionItems = ({ serviceItem, entityId, onSuccess }) => {
  const fileInputRef = useRef(null);

  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const [showTRCConfirmation, setShowTRCConfirmation] = useState(false);

  const [uploadSignedTrc, { isLoading: isLoadingUploadSignedTrc }] =
    useUploadSignedTrcMutation();

  const [updateTaskPhase, { isLoading: isLoadingUpdateTaskPhase }] =
    useUpdateTaskPhaseMutation();

  const handleButtonClick = () => {
    // if (fileInputRef.current) {
    //     fileInputRef.current.click();
    // }

    setShowApplicationForm(true);
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.set("trc_signed_copy", file);

        await uploadSignedTrc({
          data: formData,
          params: {
            task_id: serviceItem.taskId,
            task_phase_id: serviceItem.taskPhaseId,
          },
        }).unwrap();
        onSuccess();
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const handleDownload = () => {
    try {
      const fileDownloadURL = BASE_URL + URL__DOWNLOAD_TRC_DRAFT;

      const queryparams = `?task_id=${encodeURIComponent(
        serviceItem.taskId
      )}&task_phase_id=${encodeURIComponent(serviceItem.taskPhaseId)}`;

      downloadFile(fileDownloadURL, queryparams, "TRC_Draft.pdf");
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = () => {
    //TODO: upload the file
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleTRCConfirmation = async() => {
    try {
      await updateTaskPhase({
        entity_id: entityId,
        task_id: serviceItem.taskId,
        task_phase_id: serviceItem.taskPhaseId,
        task_input: {
          trc_received: true,
        },
      }).unwrap();
      setShowTRCConfirmation(false);
      onSuccess();
    } catch (error) {
      console.log("error", error);
    }
  };


  //All ActionItems are in ServiceContants component inside are there....
  const action = SERVICE_ACTIONS[serviceItem?.taskPhase];

  if (!action) return null;

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".pdf"
      />
      <ActionRenderer
        action={action}
        serviceItem={serviceItem}
        onUpload={handleUpload}
        onDownload={handleDownload}
        onButtonClick={handleButtonClick}
        onConfirmation={() => setShowTRCConfirmation(true)}
      />

      {showApplicationForm && (
        <TRCApplicationForm
          onClose={() => setShowApplicationForm(false)}
          serviceItem={serviceItem}
          entityId={entityId}
          onSuccess={onSuccess}
        />
      )}
      {showTRCConfirmation && (
        <ConfirmationDialog
          onClose={() => setShowTRCConfirmation(false)}
          onConfirm={handleTRCConfirmation}
          message={MSG__TRC_RECEIVED_CONFIRMATION}
        />
      )}
    </>
  );
};

export default ActionItems;
