import styles from "./EditFormInputs.module.css";
import { motion } from "framer-motion";
const defaultVariants = {moveIn: { x: -400}, moveOut: {  x: 400}, visible: {  x: 0 }};


const EditFormInputs = ({ children, variants=defaultVariants, ...props }) => {
  return (
    <motion.div
      // variants={variants}
      // initial="moveIn"
      // animate="visible"
      // exit="moveOut"
      // transition={{duration: 0.25}}
      className={styles.form}
    >
      {children}
    </motion.div>
  );
};

export default EditFormInputs;
