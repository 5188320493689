import { METHOD_POST, URL__CREATE_PRIMARY_ENTITY } from "../../../consts/APIConstants";
import { LBL__YES } from "../../../consts/TextConstants";
import { apiSlice } from "../../../store/api-slice";

const onboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createEntity: builder.mutation({
      query: (data) => ({
        url: URL__CREATE_PRIMARY_ENTITY,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "multipart/form-data" },
        body: data,
      }),
    }),
  }),
});

export const { useCreateEntityMutation } = onboardApiSlice;
