import { useState } from "react";
import { CustomTable, TableData, TableRow } from "../../UI/Table/CustomTable";
import styles from "./AddedServicesList.module.css";
import { useSelector } from "react-redux";
import Payments from "./PaymentsFolder/Payments";
import EmailCard from "../../UI/Email/EmailCard";
import ServicesDocumentOverlay from "./ServicesDocumentsOverlay";
import ServiceTableRow from "./ServiceTableRow";

const AddedServicesList = ({ onSuccess, entityName }) => {
  // const { primaryEntity } = useSelector((state) => state.entity);
  const { servicesAdded } = useSelector((state) => state.services);

  const [showPayments, setShowPayments] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const [showServicesDocumentOverlay, setShowServicesDocumentOverlay] =
    useState(false);

  const handleEmail = (service) => {
    setSelectedService(service);
    setShowEmail(true);
  };

  const handlePayClick = (service) => {
    setSelectedService(service);
    setShowPayments(true);
  };

  const handleOverlayClose = () => {
    setShowEmail(false);
  };

  const handleServicesDocumentOverlay = (service) => {
    setSelectedService(service);
    setShowServicesDocumentOverlay(true);
  };

  const handlePaymentSuccess = () => {
    setShowPayments(false);
    onSuccess();
  };

  return (
    <div className={styles.table_container}>
      <CustomTable className={styles.table}>
        <tbody>
          {servicesAdded.length > 0 ? (
            servicesAdded.map((serviceItem, row) => (
              <ServiceTableRow
                key={`service-row-${row}`}
                serviceItem={serviceItem}
                row={row}
                handleEmail={handleEmail}
                handlePayClick={handlePayClick}
                handleDocumentsClick={handleServicesDocumentOverlay}
                onSuccess={onSuccess}
              />
            ))
          ) : (
            <TableRow key="no-data">
              <TableData colSpan="6">
                <div className={styles.noData}>No services found</div>
              </TableData>
            </TableRow>
          )}
        </tbody>
      </CustomTable>

      {showPayments && selectedService && (
        <Payments
          onClose={() => setShowPayments(false)}
          onSuccess={handlePaymentSuccess}
          selectedService={selectedService}
          subscriptionId={selectedService.subscriptionId}
        />
      )}
      {showEmail && (
        <EmailCard
          onClose={handleOverlayClose}
          serviceItem={selectedService}
          entityId={selectedService?.entityId}
          subscriptionId={selectedService.subscriptionId}
          taskId={selectedService.taskId}
          disableSubject={true}
          initiatedFromTask={true}
          defaultSubject={`${selectedService.ticketNumber} | ${selectedService.serviceName} | ${selectedService.entityName}`}
        />
      )}
      {showServicesDocumentOverlay && (
        <ServicesDocumentOverlay
          onClose={() => setShowServicesDocumentOverlay(false)}
          serviceItem={selectedService}
        />
      )}
    </div>
  );
};

export default AddedServicesList;
