import styles from "./EditOverlay.module.css";
import OverlayRegular from "../../UI/Modal/OverlayRegular";

const EditOverlay = ({
  children,
  title,
  className,
  titleStyle,
  sub_text,
  ...props
}) => {
  return (
    <OverlayRegular
      keyName="edit-overlay"
      className={`${styles.overlay} ${className}`}
      blurValue="0.15rem"
      onClose={props.onClose}
    >
      <div className={`${styles.title} ${titleStyle}`}>
        <div>{title}</div>
        {sub_text}
      </div>

      {children}
    </OverlayRegular>
  );
};

export default EditOverlay;
