import { useState, useEffect } from "react";
import styles from "./EmailCard.module.css";
import OverlayRegular from "../Modal/OverlayRegular";
import sendEmail from "/assets/subscription/links/Send.svg";
import { useSendSupportEmailMutation } from "../../Main/Services/apis/serviceApi-slice";
import useInput from "../../../hooks/use-input";

import { isNotEmpty } from "../../../utils/validations";
import Button from "../Button/Button";

// Reusable Input Field Component
const InputField = ({
  label,
  id,
  type = "text",
  className,
  showError,
  errorMessage,
  ...props
}) => (
  <div className={styles.input_container}>
    <label htmlFor={id} className={styles.label}>
      {label}
    </label>
    {type === "textarea" ? (
      <textarea id={id} className={className} {...props} />
    ) : (
      <input type={type} id={id} className={className} {...props} />
    )}
    {showError && <p className={styles.error_message}>{errorMessage}</p>}
  </div>
);

const EmailCard = ({
  onClose,
  entityId,
  subscriptionId,
  taskId,
  isSubjectOptional = false,
  disableSubject = false,
  defaultSubject = "",
  initiatedFromTask = false,
}) => {
  const {
    value: subject,
    handleChange: handleSubjectChange,
    setShowError: setShowSubjectError,
    showError: showSubjectError,
    handleBlur: handleSubjectBlur,
    handleFocus: handleSubjectFocus,
    isValid: isSubjectValid,
  } = useInput(isNotEmpty, defaultSubject);
  const {
    value: details,
    handleChange: handleDetailsChange,
    setShowError: setShowDetailsError,
    showError: showDetailsError,
    handleBlur: handleDetailsBlur,
    handleFocus: handleDetailsFocus,
    isValid: isDetailsValid,
  } = useInput(isNotEmpty);

  // Use the mutation hook for sending emails
  const [sendSupportEmail, { isLoading: isLoading }] =
    useSendSupportEmailMutation();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const validateForm = () => {
    if (!isSubjectValid || !isDetailsValid) {
      setShowSubjectError(!isSubjectValid);
      setShowDetailsError(!isDetailsValid);
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await sendSupportEmail({
        params: {
          entity_id: entityId,
          subscription_id: subscriptionId,
          task_id: taskId,
        },
        data: {
          ...(isSubjectOptional ? {} : { subject }),
          body: details,
        },
      }).unwrap(); // .unwrap() is used to handle fulfilled and rejected state

      onClose(); // Close modal after successful submission
    } catch (error) {
      if (error.status === "FETCH_ERROR") {
        console.error(
          "Network error: Failed to fetch. Please check your internet connection."
        );
      } else if (error.status === 504) {
        console.error("Server timeout. Please try again later.");
      } else {
        console.error("Failed to send email. Please try again.");
      }
      console.error("Error sending email:", error);
    }
  };

  return (
    <OverlayRegular onClick={onClose}>
      {/* Prevent click events from propagating to the parent overlay */}
      <div
        className={styles.overlay_content}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.header}>Contact Us</div>
        <div className={styles.middle_content}>
          <InputField
            label={initiatedFromTask ? "" : "What do you need help with?"}
            id="subject"
            value={subject}
            onChange={handleSubjectChange}
            onBlur={handleSubjectBlur}
            onFocus={handleSubjectFocus}
            showError={!disableSubject && showSubjectError}
            errorMessage="Subject is required"
            disabled={disableSubject}
          />

          <InputField
            label={initiatedFromTask ? "What do you need help with?" : "Tell us more"}
            id="details"
            type="textarea"
            value={details}
            onChange={handleDetailsChange}
            onBlur={handleDetailsBlur}
            onFocus={handleDetailsFocus}
            showError={showDetailsError}
            errorMessage="More information is required"
          />
        </div>

        <div className={styles.btn_container}>
          <Button
            buttonstyle="default-blue"
            className={styles.btn__send}
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          >
            SEND
            <img src={sendEmail} alt="Send Email" />
          </Button>
        </div>
      </div>
    </OverlayRegular>
  );
};

export default EmailCard;
