import styles from "./StatusRepresentationBox.module.css";

const StatusRepresentationBox = ({ children, status, className, ...props }) => {
  let classes = `${styles.main} ${className}`;
  switch (status) {
    case "green":
      classes = `${styles.main} ${styles.green} ${className}`;
      break;
    case "blue":
      classes = `${styles.main} ${styles.blue} ${className}`;
      break;
    case "yellow":
      classes = `${styles.main} ${styles.yellow} ${className}`;
      break;
  }
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default StatusRepresentationBox;
