
import React from 'react';
import styles from './QuoteSuccessDialog.module.css';
import OverlayRegular from '../../../UI/Modal/OverlayRegular';

const QuoteSuccessDialog = ({ onClose }) => {

    return (
        <OverlayRegular showCloseIcon={false}>
            <div className={styles.quote_popup_page}>
                <div className={styles.small_popup_para}>
                    Thanks for your Interest,
                    Our Sales Team will get back to you with the best Quote.
                </div>

                <button onClick={onClose} className={styles.quote_button}>
                    CLOSE
                </button>
            </div>
        </OverlayRegular >

    );
};



export default QuoteSuccessDialog;


