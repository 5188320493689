import React, { useState } from 'react';
import styles from './DashboardHeader.module.css';
import Sparkle from '/assets/dashboard/Sparkle_Blue.svg';
import promoBanner from '/assets/dashboard/Temp_Banner.svg';
import closeIcon from '/assets/common/Close_White.svg';


const Header = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className={styles.header_cont}>
                <div className={styles.header_left}>
                    <img src={Sparkle} alt="sparkle" />
                    <h1 className={styles.header_title}>The Ultimate US Tax & Accounting Platform.</h1>
                </div>
                <img src={promoBanner} alt="promo" className={styles.promo_banner} />
                <button className={styles.btn__close} onClick={handleClose}>
                    <img src={closeIcon} alt="close" className={styles.btn__img} />
                </button>
            </div>
        )
    );
}

export default Header;





// import styles from './DashboardHeader.module.css';
// import Sparkle from '/assets/dashboard/Sparkleblack.svg';
// import closeIcon from '/assets/Close.svg';

// const Header = () => {
//     return (
//         <div className={styles.header_cont}>
//             <img src={Sparkle} alt="sparkle" className={styles.sparkle_img} />
//             <button className={styles.btn__close} >
//                 <img src={closeIcon} alt="close" className={styles.btn__img} />
//             </button>
//         </div>

//     )
// }

// export default Header;


