import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import search from "/assets/loading/Search_Icon.svg";
import file from "/assets/loading/File_Icon.svg";
import styles from "./LoadingDocument.module.css";

const LoadingDocument = ({ className }) => {
  const searchControls = useAnimation();
  const fileControls1 = useAnimation();
  const fileControls2 = useAnimation();
  const fileControls3 = useAnimation();

  const vibrateSearchIcon = () => {
    searchControls.start({
      x: [-1.6, 1.6, -1.6, 1.6, 0],
      y: [-0.6, 0.6, -0.6, 0.6, 0],
      transition: {
        duration: 1.5,
        ease: "easeInOut",
        repeat: Infinity,
      },
    });
  };

  useEffect(() => {
    vibrateSearchIcon();

    const commonAnimationProps = {
      x: ["6vw", "0vw", "-6vw"],
      scale: [0.4, 1, 0.4],
      opacity: [0, 0.6, 0],
      transition: {
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 0.5,
      },
    };

    // Start animations with adjusted speeds and staggered delays
    fileControls1.start({
      ...commonAnimationProps,
      transition: { ...commonAnimationProps.transition, delay: 0 },
    });
    fileControls2.start({
      ...commonAnimationProps,
      transition: { ...commonAnimationProps.transition, delay: 0.75 },
    });
    fileControls3.start({
      ...commonAnimationProps,
      transition: { ...commonAnimationProps.transition, delay: 1.5 },
    });
  }, [fileControls1, fileControls2, fileControls3, searchControls]);

  return (
    <div className={`${styles.document_container} ${className}`}>
      <motion.img
        src={search}
        className={styles.search_icon}
        animate={searchControls}
        style={{ position: "absolute" }}
      />

      {/* File Icons */}
      <motion.img
        src={file}
        className={styles.file_icon}
        animate={fileControls1}
        style={{ position: "absolute" }}
      />
      <motion.img
        src={file}
        className={styles.file_icon}
        animate={fileControls2}
        style={{ position: "absolute" }}
      />
      <motion.img
        src={file}
        className={styles.file_icon}
        animate={fileControls3}
        style={{ position: "absolute" }}
      />
    </div>
  );
};

export default LoadingDocument;
