import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormValidation } from "../hooks/use-formValidation";
import {
  BusinessInfoForm,
  DeliveryInfoForm,
  PaymentTypeForm,
  BankDetailsForm,
} from "./TRCFormSteps";
import EditOverlay from "../../Edit/EditOverlay";
import Button from "../../../UI/Button/Button";
import styles from "./TRCApplicationForm.module.css";
import { serviceApplicationActions } from "../reducers/serviceApplication-slice";
import magicWandIcon from "/assets/subscription/MagicWand.svg";
import {
  useUpdateTaskPhaseMutation,
  useLazyGetAllUserServicesQuery,
} from "../apis/serviceApi-slice";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import { BTN__RETRY } from "../../../../consts/TextConstants";
import { useGetBoardMembersQuery } from "../../Entities/apis/entityApi-slice";

const FORM_STEPS = [
  { title: "Fill Details" },
  { title: "Fill Details" },
  {
    title: "How do you want to pay the Govt. charges?",
    subText: "Govt. Charges - $185",
  },
  { title: "Payment Method" },
];

const TRCApplicationForm = ({ onClose, serviceItem, entityId, onSuccess }) => {
  const { currentFormStep, formData } = useSelector(
    (state) => state.serviceApplication
  );

  // const { primaryEntity } = useSelector((state) => state.entity);

  const { isFormValid, validateForm } = useFormValidation();
  const [showError, setShowError] = useState(false);
  const [updateError, setUpdateError] = useState({ isError: false });

  const dispatch = useDispatch();

  const [updateTaskPhase, { isLoading: isLoadingUpdateTaskPhase }] =
    useUpdateTaskPhaseMutation();

  const [getAllUserServices] = useLazyGetAllUserServicesQuery();

  const { isLoading: isLoadingBoardMembers } = useGetBoardMembersQuery(
    { entity_id: entityId },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    validateForm(currentFormStep, formData);
  }, [formData, currentFormStep]);

  const handleCloseForm = () => {
    dispatch(serviceApplicationActions.resetFormData());
    onClose();
  };

  const handleInputChange = (field, value) => {
    dispatch(serviceApplicationActions.updateFormData({ [field]: value }));
  };

  const handleNext = async () => {
    if (!isFormValid) {
      setShowError(true);
      return;
    }
    setShowError(false);

    if (currentFormStep < FORM_STEPS.length - 1) {
      dispatch(
        serviceApplicationActions.setCurrentFormStep(currentFormStep + 1)
      );
    } else {
      try {
        const requestData = {
          task_id: serviceItem?.taskId,
          task_phase_id: serviceItem?.taskPhaseId,
          entity_id: entityId,
          task_input: {
            requesting_officer_name: formData?.officerDetails?.name,
            requesting_officer_title: formData?.title,
            requesting_officer_phone: formData?.phoneNumber,
            is_new_board_member: !formData?.officerDetails?.title,
            bussiness_address_street:
              formData?.businessAddress?.addressParams?.street,
            bussiness_address_city:
              formData?.businessAddress?.addressParams?.city,
            bussiness_address_state:
              formData?.businessAddress?.addressParams?.state,
            bussiness_address_zip:
              formData?.businessAddress?.addressParams?.zipCode,
            bussiness_address_country:
              formData?.businessAddress?.addressParams?.country,
            is_same_address: formData?.isDeliveryAddressSameAsBusiness
              ? true
              : false,
            ...(!formData?.isDeliveryAddressSameAsBusiness
              ? {
                  delivery_address_street:
                    formData?.deliveryAddress?.addressParams?.street,
                  delivery_address_city:
                    formData?.deliveryAddress?.addressParams?.city,
                  delivery_address_state:
                    formData?.deliveryAddress?.addressParams?.state,
                  delivery_address_zip:
                    formData?.deliveryAddress?.addressParams?.zipCode,
                  delivery_address_country:
                    formData?.deliveryAddress?.addressParams?.country,
                }
              : {}),
            countries_applied_for: formData?.appliedCountries,
          },
          ach_input: {
            entity_id: entityId,
            ach_payment_number: formData?.achNumber,
            ach_payment_account_number: formData?.accountNumber,
            ach_payment_bank_name: formData?.bankName,
            ach_payment_bank_street:
              formData?.bankAddress?.addressParams?.street,
            ach_payment_bank_city: formData?.bankAddress?.addressParams?.city,
            ach_payment_bank_state: formData?.bankAddress?.addressParams?.state,
            ach_payment_bank_zip: formData?.bankAddress?.addressParams?.zipCode,
            ach_payment_bank_country:
              formData?.bankAddress?.addressParams?.country,
          },
        };
        await updateTaskPhase(requestData).unwrap();
        onSuccess();
        handleCloseForm();
      } catch (err) {
        setUpdateError({
          isError: true,
          title: "Update error",
          message: err?.data?.message,
        });
      }
    }
  };

  const handleCloseError = () => {
    setUpdateError({ isError: false, title: "", message: "" });
  };

  const handleBack = () => {
    if (currentFormStep > 0) {
      dispatch(
        serviceApplicationActions.setCurrentFormStep(currentFormStep - 1)
      );
    }
  };

  const renderFormContent = () => {
    switch (currentFormStep) {
      case 0:
        return (
          <BusinessInfoForm
            formData={formData}
            onChange={handleInputChange}
            showError={currentFormStep == 0 && showError}
          />
        );
      case 1:
        return (
          <DeliveryInfoForm
            formData={formData}
            onChange={handleInputChange}
            showError={currentFormStep == 1 && showError}
          />
        );
      case 2:
        return (
          <PaymentTypeForm
            onSelect={handleNext}
            sub_text={FORM_STEPS[currentFormStep].subText}
          />
        );
      case 3:
        return (
          <BankDetailsForm
            formData={formData}
            onChange={handleInputChange}
            showError={currentFormStep == 3 && showError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <EditOverlay
      onClose={handleCloseForm}
      title={FORM_STEPS[currentFormStep].title}
      className={styles.overlay}
    >
      <div className={styles.content}>
        {renderFormContent()}
        <div className={styles.footer}>
          {currentFormStep == 0 && (
            <div className={styles.note}>
              <img src={magicWandIcon} alt="" />
              <span>Auto filled from Entity information</span>
            </div>
          )}
          {currentFormStep > 0 && (
            <Button
              className={styles.btn__back}
              buttonstyle="default-blue-outline"
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {currentFormStep !== 2 && (
            <Button
              className={styles.btn__submit}
              buttonstyle="default-blue"
              // disabled={!isFormValid}
              onClick={handleNext}
              isLoading={isLoadingUpdateTaskPhase}
              disabled={isLoadingUpdateTaskPhase}
            >
              {currentFormStep === FORM_STEPS.length - 1 ? "Submit" : "Next"}
            </Button>
          )}
        </div>
      </div>
      {updateError.isError && (
        <MessageDialog
          title={updateError.title}
          message={updateError.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
    </EditOverlay>
  );
};

export default TRCApplicationForm;
