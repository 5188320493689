import { createSlice } from "@reduxjs/toolkit";
import {
  removeAuthToken,
  setAuthToken,
  setUser,
  removeUser,
} from "../utils/authentication";

const initialState = {
  user: null,
  token: null,
  userDetails: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials(state, action) {
      const { accessToken } = action.payload;
      state.token = accessToken;
      setAuthToken(accessToken);
    },
    
    setUser(state, action) {
      state.user = action.payload;
      setUser(action.payload);
    },

    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },

    logout(state) {
      state.user = null;
      state.token = null;
      removeAuthToken();
      removeUser();
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectCurrentToken = (state) => state.auth.token;
