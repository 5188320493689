import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ErrorPage.module.css";
import ErrorImage from "/assets/error/Error_404.svg";
import RightDirectionArrow from "/assets/error/Arrow_Right_Direction_white.svg";
import Button from "../UI/Button/Button";
const ErrorPage = () => {
  const navigate = useNavigate();

  const handleStartOverClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.main_container}>
      <h1 className={styles.header}>404</h1>
      <img src={ErrorImage} alt="Error Image" className={styles.error_img} />
      <Button
        buttonstyle="default-blue"
        className={styles.start_btn}
        onClick={handleStartOverClick}
      >
        Start over <img src={RightDirectionArrow} alt="Right Arrow" />
      </Button>
      <p className={styles.first_para}>Something went wrong.</p>
      <p>We can’t find the page you’re looking for.</p>
    </div>
  );
};

export default ErrorPage;
