import {
  METHOD_POST,
  URL_FORGOT_PASSWORD,
  URL_VERIFY_FORGOT_PASSWORD_OTP,
  URL_RESET_PASSWORD,
} from "../../../consts/APIConstants";
import { apiSlice } from "../../../store/api-slice";

export const forgotPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: URL_FORGOT_PASSWORD,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: { ...data },
      }),
    }),
    verifyOTP: builder.mutation({
      query: (data) => ({
        url: URL_VERIFY_FORGOT_PASSWORD_OTP,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: { ...data },
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: URL_RESET_PASSWORD,
        method: METHOD_POST,
        header: { Accept: "*/*", "content-type": "application/json" },
        body: { ...data },
      }),
    }),
  }),
});

export const { useForgotPasswordMutation, useVerifyOTPMutation, useResetPasswordMutation } = forgotPasswordApiSlice;
