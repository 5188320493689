import styles from "./ProgressBar.module.css";
import { LIST_ONBORDING_STEPS } from "../../../consts/TextConstants";
import ProgressStep from "./ProgressStep";
import { useSelector } from "react-redux";

const ProgressBar = ({ active, ...props }) => {
  const steps = LIST_ONBORDING_STEPS.length;
  const {stepCompletionStatus} = useSelector(state => state.onboard);

  const handleClick = (index) => {
    if (index < active) {
      //change step
      props.onChangeStep(index);
    }
  };

  return (
    <div className={styles.container}>
      {LIST_ONBORDING_STEPS.map((step, index) => (
        <ProgressStep
          key={`step_${index}`}
          active={index + 1 == active}
          completed={stepCompletionStatus[index]}
          step={index + 1}
          instruction={step}
          lastStep={index == LIST_ONBORDING_STEPS.length - 1}
          onClick={() => handleClick(index + 1)}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
