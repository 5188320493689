// BasePrice.jsx

import React from "react";
import styles from "./BasePrice.module.css";
import editGstForm from "/assets/subscription/links/Editgstform.svg";

const BasePrice = ({ basePriceInINR, gstInINR, servicePrice, businessName, handleEditGstFormClick }) => {
    return (
        <div className={styles.base_price}>
            <div className={styles.base_price_container}>
                <div className={styles.item_gst}>
                    <span className={styles.label_gst}>BASE PRICE</span>
                    <span className={styles.value_gst}>
                        ₹{basePriceInINR} (${servicePrice})
                    </span>
                </div>
                <div className={styles.item_gst}>
                    <span className={styles.label_gst}>GST (18%)</span>
                    <span className={styles.value_gst}>₹{gstInINR}</span>
                </div>
                {businessName && handleEditGstFormClick && (
                    <div className={styles.edit_gst_form} >
                        <p>{businessName}</p>
                        <img src={editGstForm} alt="Edit" onClick={handleEditGstFormClick} className={styles.edit_gst_icon} />
                    </div>
                )}
            </div>

        </div>
    );
};

export default BasePrice;
