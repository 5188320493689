import { useState, useEffect } from "react";
import deleteIcon from "/assets/common/Delete.svg";
import fileIcon from "/assets/common/File_Gray.svg";
import styled from "styled-components";
import InputCheckBox from "../Input/InputCheckBox";
import styles from "./Table.module.css";
import { FlagEmoji } from "react-international-phone";

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Table = ({
  tableHeaders,
  tableData = [],
  minRows = 0,
  id,
  enableDelete = false,
  onDelete,
  showEmptyRowSeparator = true,
  hasFooter = false,
  footerData,
  name,
  needOutlineBorder = true,
  ...props
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const columns =
    Object.values(tableHeaders).length +
    (enableDelete && tableData?.length > 0
      ? checkedList.length > 0
        ? 2
        : 1
      : 0);

  useEffect(() => {
    if (enableDelete) {
      setShowDelete(checkedList.length > 0);
    }
  }, [checkedList]);

  useEffect(() => {
    setCheckedList([]);
  }, [name]);

  const handleCheckBoxClick = (event, index) => {
    let newList = [...checkedList];
    if (event.target.checked) {
      newList.push(index);
    } else {
      //remove unchecked item from array
      const pos = newList.indexOf(index);
      if (pos > -1) {
        newList.splice(pos, 1);
      }
    }
    setCheckedList(newList);
  };

  const handleClickSelectAll = (event) => {
    let newList = [];
    if (event.target.checked) {
      for (let i = 0; i < tableData.length; i++) {
        newList.push(i);
      }
    }
    setCheckedList(newList);
  };

  const handleDeleteSelectedRows = (rows) => {
    onDelete(rows);
    let newList = [...checkedList];
    rows.forEach((row) => {
      const pos = newList.indexOf(row);
      if (pos > -1) {
        newList.splice(pos, 1);
      }
    });
    setCheckedList(newList);
  };
  return (
    <>
      <table
        className={`${styles.table} ${props.className} `}
      >
        {/* --------Table headers----------- */}
        <thead >
          <tr key={`${id}-header-row`}>
            {/* show select all checkbox when delete is enabled and atleast an item is selected */}
            {/* {enableDelete && tableData.length > 0 && (
              <th
                key={`${id}-header--checkbox`}
                className={styles.checkbox_cell}
              >
                {checkedList.length > 0 && (
                  <InputCheckBox
                    name={`${id}-header--checkbox`}
                    onChange={handleClickSelectAll}
                    checked={checkedList?.length == tableData?.length}
                    className={styles.checkbox}
                  />
                )}
              </th>
            )} */}

            {/* headers of the table */}
            {Object.values(tableHeaders).map((item, index) => (
              <th key={`${id}-header--${index}`}>{item}</th>
            ))}

            {/* show delete column when delete is enabled and atleast an item is selected */}
            {enableDelete && (
              <th key={`${id}-header-delete-${columns}`} className={styles.column_delete}>
                {/* show delete selected button when more than one items are selected */}
                {/* {checkedList.length > 1 && (
                  <Button onClick={() => handleDeleteSelectedRows(checkedList)}>
                    <img src={deleteIcon} alt="" />
                  </Button>
                )} */}
              </th>
            )}
          </tr>
        </thead>
        {/* -----------Table Data rows---------------- */}
        <tbody>
          {tableData.map((dataItem, row) => {
            return (
              <tr key={`${id}-row-${row}`}>
                {/* show checkbox when delete is enabled */}
                {/* {enableDelete && (
                  <td
                    key={`${id}-checkbox-${row}-delete`}
                    className={styles.checkbox_cell}
                  >
                    <InputCheckBox
                      name={`${id}-checkbox-${row}-delete`}
                      onChange={(event) => handleCheckBoxClick(event, row)}
                      checked={checkedList.indexOf(row) > -1}
                      className={styles.checkbox}
                    />
                  </td>
                )} */}

                {/* table rows with table data*/}
                {Object.keys(tableHeaders).map((key, index) => (
                  <td key={`${id}-detail-${row}-${index}`}>
                    {dataItem[key] instanceof Object ? (
                      <div className={styles.img_data}>
                        {dataItem[key].value == "file" ? (
                          <img
                            src={fileIcon}
                            alt=""
                            onClick={() => props.onFileClick(row)}
                            className={styles.img_file}
                          />
                        ) : (
                          <FlagEmoji
                            iso2={dataItem[key].value}
                            className={styles.img_flag}
                          />
                        )}
                      </div>
                    ) : (
                      dataItem[key]
                    )}
                  </td>
                ))}

                {/* show delete column when any checkbox is checked*/}
                {enableDelete && (
                  <td key={`${id}-detail-${row}-delete`}>
                    {/* show delete row button when corresponding checkbox is checked */}
                    {/* {checkedList.indexOf(row) != -1 && ( */}
                      <Button onClick={() => handleDeleteSelectedRows([row])}>
                        <img src={deleteIcon} alt="" />
                      </Button>
                    {/* )} */}
                  </td>
                )}
              </tr>
            );
          })}
          {/* show empty rows when table data rows are less than minimum no of rows*/}
          {minRows > tableData.length &&
            [...Array(minRows - tableData.length).keys()].map((row) => {
              const factor = minRows - tableData.length;

              return (
                <tr key={`${id}-detail-empty-row-${row + factor}`}>
                  {[...Array(columns).keys()].map((index) => (
                    <td
                      key={`${id}-detail-empty-${row + factor}-${index}`}
                      className={styles.empty_rows}
                    />
                  ))}
                </tr>
              );
            })}
        </tbody>

        {/*------------Table Footer if enabled---------------*/}
        <tfoot>
          {hasFooter && (
            <tr key={`${id}-footer-row-${0}`}>
              {/* {enableDelete && tableData.length > 0 && (
                <td key={`${id}-footer-0-${0}`} />
              )} */}
              {[...Array(columns).keys()].map((index) => (
                <td key={`${id}-footer-0-${index + 1}`}>{footerData[index]}</td>
              ))}
            </tr>
          )}
        </tfoot>
      </table>
    </>
  );
};

export default Table;
