import React, { useState, useEffect, useMemo } from "react";
import styles from "./PaymentDetails.module.css";
import copyIcon from "/assets/subscription/Copy.svg";
import ToggleButton from "../../../UI/ToggleButton/ToggleButton";
import { motion } from "framer-motion";
import backArrow from "/assets/common/BackArrow_Icon.svg";
import PaymentStatus from "./PaymentStatus";
import { useGetBankAccountsQuery } from "../apis/serviceApi-slice";
import { useSelector } from "react-redux";
import { TXT__PAYMENT_CONFIRMATION } from "../../../../consts/TextConstants";
import { paymentHeaderDetails } from "../../../../consts/PaymentConstants";
import PriceDetails from "./PriceDetails";
import Button from "../../../UI/Button/Button";

const PaymentDetails = ({
  option,
  onBack,
  onChangeBank,
  selectedService,
  subscriptionId,
  selectedBankId,
  onSuccess,
}) => {
  const isUSA = useMemo(() => option === "U.S.A", [option]);

  const { bankAccounts } = useSelector((state) => state.services);

  const [bankType, setBankType] = useState(isUSA ? "Mercury | Brex" : "India");
  const [detailsData, setDetailsData] = useState(bankAccounts[bankType]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const { isLoading, isError } = useGetBankAccountsQuery();

  useEffect(() => {
    onChangeBank(detailsData?.bank_id);
  }, [detailsData]);

  useEffect(() => {
    setDetailsData(bankAccounts[bankType]);
  }, [bankAccounts, bankType]);

  const handleCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.payment_header}>
        <Button className={styles.btn__back} onClick={onBack}>
          <img src={backArrow} alt="Back" />
          Back
        </Button>
        <PaymentStatus fileUploaded={isFileUploaded} />
      </div>

      {isUSA && (
        <>
          <div className={styles.label_toggle}>Choose your US Bank</div>
          <ToggleButton
            className={styles.toggle}
            titleLeft="Mercury | Brex"
            titleRight="Other Banks"
            active={bankType}
            onToggle={setBankType}
          />
        </>
      )}

      <div
        className={`${styles.header_transfer} ${
          !isUSA ? styles.india_header_transfer : ""
        }`}
      >
        <div>
          {paymentHeaderDetails[bankType].header}
          <span>{paymentHeaderDetails[bankType].subText}</span>
        </div>
        <p>{TXT__PAYMENT_CONFIRMATION}</p>
      </div>

      <div className={`${styles.middle_cont}`}>
        <div className={`${styles.payment_box_common} `}>
          {detailsData?.data?.map((item) => (
            <div key={item.title} className={styles.detail_row}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.detail}>
                <div>:&nbsp;&nbsp;{item.detail}&nbsp;&nbsp;</div>
                {item.copyToClipboard && (
                  <motion.button
                    animate={{ scale: 1 }}
                    whileTap={{ scale: 1.5 }}
                    transition={{ duration: 0.6 }}
                    onClick={() => handleCopyToClipboard(item.detail)}
                  >
                    <img src={copyIcon} alt="Copy" />
                  </motion.button>
                )}
                {item.suggestion && <div>&nbsp;&nbsp;{item.suggestion}</div>}
              </div>
            </div>
          ))}
        </div>
        <PriceDetails
          option={option}
          onFileChange={setIsFileUploaded}
          selectedService={selectedService}
          subscriptionId={subscriptionId}
          selectedBankId={selectedBankId}
          onSuccess={onSuccess}
        />
      </div>

      <div className={`${styles.footer_link} `}>
        <div className={styles.footer_label}>Login to your Bank A/C:</div>
        <div className={styles.external_links}>
          {detailsData?.linkData?.map((item) => (
            <>
              {item.id == "Other" && (
                <div
                  onClick={() => window.open(item.path, "_blank")}
                  className={styles.link_other_bank}
                >
                  <img src={item.image} alt="" />
                </div>
              )}
              {item.id != "Other" && (
                <a
                  href={item.path}
                  key={item.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.image} alt={`${item.id} Bank Link`} />
                </a>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
