import React from "react";
import styles from "./MoreInfo.module.css";
import moreInfoIcon from "/assets/common/MoreInfo.svg";
import useHover from "../../../hooks/use-hover";

const MoreInfo = ({
  children,
  content,
  position = "right",
  showMoreInfoIcon = true,
  className,
  descriptionstyle,
  customContainerComponent = false,
  ...props
}) => {
  const [isHovered, hoverProps] = useHover();

  const allProps = {
    ...props,
    ...hoverProps,
  };

  return (
    <div className={`${styles.container} ${className}`} {...allProps}>
      {showMoreInfoIcon && <img src={moreInfoIcon} alt="" />}
      {customContainerComponent && children}
      {isHovered && (
        <div
          className={`${styles.description} ${styles[position]} ${descriptionstyle}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default MoreInfo;
