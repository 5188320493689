import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./WelcomeContent.module.css";
import Button from "../UI/Button/Button";
import { GradientText } from "../UI/GradientText";
import logoIcon from "/assets/welcome/WelcomeLogo.svg";
import complianceImage from "/assets/welcome/ComplianceStatus.svg";
import actionItemsImage from "/assets/welcome/ActionItems.svg";
import {
  APP_NAME,
  BTN__DASHBOARD,
  BTN__NEXT,
} from "../../consts/TextConstants";

const WelcomeContent = ({ ...props }) => {
  const handleNext = () => {
    props.onChange(props.active + 1);
  };

  const transition = { type: "tween", duration: 0.6 };

  const variants = {
    slidein: { opacity: 0, y: 300 },
    visible: { opacity: 1, y: 0 },
    slideout: { opacity: 0, y: -300 },
  };

  const getContent = (active) => {
    let content = <></>;
    switch (active) {
      case 0:
        content = (
          <div className={styles.container} key="container-one">
            <div className={styles.wrapper}>
              <motion.div
                key="welcome-one"
                layoutId="welcome-one"
                variants={variants}
                className={`${styles.main} ${styles.main__first}`}
                transition={transition}
                exit={variants.slideout}
              >
                <div className={styles.logo_container}>
                  <img src={logoIcon} alt="" />
                  <div className={styles.logo_title}>{APP_NAME}</div>
                </div>
                <div className={`${styles.title} ${styles.title__first}`}>
                  Welcome! <br /> Your Account has been successfully created.
                </div>
              </motion.div>

              <Button
                className={styles.btn__nav}
                buttonstyle="default-blue"
                onClick={handleNext}
                layoutId="welcome-btn"
                transition={transition}
              >
                {BTN__NEXT}
              </Button>
            </div>
          </div>
        );
        break;
      case 1:
        content = (
          <div
            key="container-two"
            className={styles.container}
            variants={variants}
          >
            <motion.div
              layoutId="welcome-img-two"
              className={styles.image}
              variants={variants}
              transition={transition}
              exit={variants.slideout}
            >
              <img src={complianceImage} alt="" />
            </motion.div>
            <div className={`${styles.wrapper} ${styles.wrapper_multi}`}>
              <motion.div
                layoutId="welcome-two"
                className={styles.main}
                variants={variants}
                transition={transition}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
              >
                <div className={styles.title}>Automated Compliance</div>
                <div className={styles.description}>
                  We have loaded your Compliance list automatically.
                </div>
              </motion.div>

              <Button
                className={`${styles.btn__nav} ${styles.btn__right}`}
                buttonstyle="default-blue"
                onClick={handleNext}
                layoutId="welcome-btn"
                transition={transition}
              >
                {BTN__NEXT}
              </Button>
            </div>
          </div>
        );
        break;
      case 2:
        content = (
          <div
            key="container-three"
            className={styles.container}
            variants={variants}
          >
            <motion.div
              key="welcome-img-three"
              layoutId="welcome-img-three"
              variants={variants}
              transition={transition}
              exit={variants.slideout}
            >
              <img src={actionItemsImage} alt="" />
            </motion.div>
            <div className={`${styles.wrapper} ${styles.wrapper_multi}`}>
              <motion.div
                key="welcome-three"
                layoutId="welcome-three"
                className={styles.main}
                variants={variants}
                transition={transition}
                exit={variants.slideout}
              >
                <div className={styles.title}>
                  Get Started with Onboarding Tasks.
                </div>
                <div className={styles.description}>
                  Enjoy the full capabilities of{" "}
                  <GradientText>Merze</GradientText> by completing onboarding
                  tasks.
                </div>
              </motion.div>
              <Button
                className={`${styles.btn__nav} ${styles.btn__right}`}
                buttonstyle="default-blue"
                onClick={props.onClose}
                layoutId="welcome-btn"
                transition={transition}
              >
                {BTN__DASHBOARD}
              </Button>
            </div>
          </div>
        );
        break;
    }
    return <AnimatePresence mode="popLayout">{content}</AnimatePresence>;
  };

  let content = getContent(props.active);

  useEffect(() => {
    content = getContent(props.active);
  }, [props.active]);

  return content;
};

export default WelcomeContent;
