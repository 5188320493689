import styles from "./InputCustom.module.css";
import lockIcon from "/assets/common/Lock.svg";

const InputCustom = ({ type = "text", showCurrency = false, ...props }) => {
  return (
    <div className={`${styles.input__container} `}>
      {showCurrency && <div className={styles.currency}>$</div>}

      <input
        className={`${styles.input} ${props.className}`}
        type={type}
        autoComplete={props.autoComplete ? props.autoComplete : "off"}
        {...props}
      />
      {props.disabled && <img src={lockIcon} alt="" />}
    </div>
  );
};

export default InputCustom;
