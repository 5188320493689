import LoginPage from "../components/Login/SignInUpPage.jsx";
import SignInUpLandingPage from "../components/Login/SignInUpLandingPage.jsx";
import { Navigate, useLoaderData, useLocation } from "react-router-dom";

const Login = () => {
  const isValidSession = useLoaderData();
  const location = useLocation();
  return isValidSession && !location.state?.showUserDetailsPage ? (
    <Navigate to="/home" state={{ from: location }} replace />
  ) : (
    <SignInUpLandingPage />
  );
};

export default Login;
