import { useState } from "react";
import styles from "./YearView.module.css";

const getStartYear = (year, start, end) => {
  if (year - start < end - year) {
    return year - ((year - start) % 16);
  }
  return year - (16 - ((end - year) % 16)) + 1;
};

const YearView = ({ year, start, end, onSelect }) => {
  const today = new Date();
  // const thisYear = today.getFullYear();
  const [startYear, setStartYear] = useState(getStartYear(year, start, end));

  const handleClickPrev = () => {
    if (startYear > start) {
      setStartYear(startYear - 16);
    }
  };

  const handleClickNext = () => {
    if (startYear + 16 < end) {
      setStartYear(startYear + 16);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <div
          className={`${startYear <= start ? styles.disabled : ""}`}
          onClick={handleClickPrev}
        >
          Prev
        </div>
        <div
          className={`${startYear + 16 > end ? styles.disabled : ""}`}
          onClick={handleClickNext}
        >
          Next
        </div>
      </div>
      <div className={styles.year_view}>
        {[
          ...Array.from({ length: 16 }, (value, index) => startYear + index),
        ].map((item) => (
          <button
            key={item}
            className={`${styles.year_item} ${
              year == item ? styles.selected : ""
            }`}
            disabled={item > end || item < start}
            onClick={() => onSelect(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default YearView;
