import Button from "../UI/Button/Button";
import styles from "./OnboardingForm.module.css";
import {
  BTN__RETRY,
  BTN__NEXT,
  BTN__SKIP,
  BTN__SUBMIT,
  TITLE__ONBOARDING_FORM,
  TITLE__UPLOAD_DOCUMENTS,
  LBL__YES,
} from "../../consts/TextConstants";
import OnboardingCollectEnityDetails from "./OnboardingCollectEnityDetails";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onboardActions } from "./reducers/onboard-slice";
import OnboardingCollectIncorporationDetails from "./OnboardingCollectIncorporationDetails";
import OnboardingCollectDocuments from "./OnboardingCollectDocuments";
import { useCreateEntityMutation } from "./apis/onboardApi-slice";
import { getUser } from "../../utils/authentication";
import MessageDialog from "../UI/Modal/MessageDialog";
import { authActions } from "../../store/auth-slice";
import { useNavigate } from "react-router-dom";
import { useLazyGetPrimaryEntityQuery } from "../Main/Entities/apis/entityApi-slice";
import { PAYLD__CREATE_ENTITY } from "../../consts/PayloadConstants";
import { formatRequestData } from "../../utils/dataFormatter";

const OnboardingForm = ({ ...props }) => {
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState({ isError: false });
  const { currentStep, collectedData } = useSelector((state) => state.onboard);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const email = getUser();

  const [createEntity, { isLoading }] = useCreateEntityMutation();
  const [getPrimaryEntity] = useLazyGetPrimaryEntityQuery();
  const dispatch = useDispatch();

  const handleDataChange = (
    data,
    valid,
    isFileData = false,
    fileType,
    file
  ) => {
    setIsValid(valid);
    if (isFileData) {
      setFiles((prev) => [...prev, fileType, file]);
    } else {
      dispatch(onboardActions.setCollectedData(data));
    }
  };

  useEffect(() => {
    dispatch(
      onboardActions.updateStepCompletionStatus({
        step: currentStep - 1,
        status: !!isValid,
      })
    );
  }, [isValid]);

  const handleNextClick = async (skipDocuments = false) => {
    let newStep = currentStep + 1;
    if (currentStep == 3) {
      try {
        const uploadDocuments = skipDocuments ? [] : files;
        const formData = formatRequestData(
          {
            ...collectedData,
            otherStateValue:
              collectedData?.otherStateValue == LBL__YES ? true : false,
            standalone: collectedData.standalone == LBL__YES ? true : false,
            isPrimary: true,
          },
          uploadDocuments,
          PAYLD__CREATE_ENTITY
        );
        const userData = await createEntity(formData).unwrap();

        dispatch(authActions.setCredentials({ ...userData }));
        // navigate("/home");
        props.onClose();
        await getPrimaryEntity();
      } catch (err) {
        setError({
          isError: true,
          title: "Onboard error",
          message: err?.data?.message,
        });
      }
    } else {
      setIsValid(false);
      dispatch(onboardActions.setCurrentStep(newStep));
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <div className={styles.container}>
      <div className={styles.outer}>
        <div>
          <div className={styles.title}>
            {currentStep == 3
              ? TITLE__UPLOAD_DOCUMENTS
              : TITLE__ONBOARDING_FORM}
          </div>
          <div className={styles.inner_container}>
            <div className={styles.spacing}></div>
            {currentStep == 1 && (
              <OnboardingCollectEnityDetails onChange={handleDataChange} />
            )}
            {currentStep == 2 && (
              <OnboardingCollectIncorporationDetails
                onChange={handleDataChange}
              />
            )}
            {currentStep == 3 && (
              <OnboardingCollectDocuments
                onChange={handleDataChange}
                files={files}
              />
            )}
          </div>
        </div>
        <div
          className={`${styles.btn_container} ${
            currentStep == 3 ? styles.multiple : ""
          }`}
        >
          {currentStep == 3 && !isValid && (
            <Button
              key={BTN__SKIP}
              className={styles.btn__skip}
              onClick={() => handleNextClick(true)}
              isLoading={isLoading}
              buttonstyle="default-blue-outline"
              disabled={isLoading}
            >
              {BTN__SKIP.toUpperCase()}
            </Button>
          )}
          <Button
            key={currentStep == 3 ? BTN__SUBMIT : BTN__NEXT}
            className={styles.btn__next}
            disabled={!isValid || isLoading}
            onClick={() => handleNextClick(false)}
            layoutId="btn-next"
            isLoading={isLoading}
            buttonstyle="default-blue"
          >
            {currentStep == 3
              ? BTN__SUBMIT.toUpperCase()
              : BTN__NEXT.toUpperCase()}
          </Button>
        </div>
      </div>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
    </div>
  );
};

export default OnboardingForm;
