import { createSlice } from "@reduxjs/toolkit";
import { isValid } from "../../../../utils/validations";
import { partitionArray } from "../../../../utils/common-utils";

const initialState = {
  askAccountingPermission: false,
  totalBalance: 0,
  runway: null,
  bankAccounts: [],
  revenueStatement: {},
  adminAccess: false,
  baseCurrencySymbol: "",
  organizationName: "",
  isAdminConnected: false,
  isZohoConnected: false
};

const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    handleAccountsBalanceData(state, action) {
      const {
        total_bank_balance,
        runway,
        bank_accounts,
        base_currency_symbol,
      } = action.payload;

      state.totalBalance = total_bank_balance;
      state.runway = runway;

      let accounts = [];
      bank_accounts.forEach((item) => {
        accounts.push({
          accountNumber: item.account_number,
          bankName: item.bank_name,
          accountName: item.account_name,
          balance: item.bank_balance,
          isActive: item.is_active,
          currencyCode: item.currency_code,
        });
      });

      const sortAccounts = (accounts) => {
        return accounts.sort((a, b) => {
          // Prioritize accounts with both number and balance
          if (isValid(a.accountNumber) && a.balance > 0) return -1;
          if (isValid(b.accountNumber) && b.balance > 0) return 1;

          // Then accounts with name and balance
          if (isValid(a.accountName) && a.balance > 0) return -1;
          if (isValid(b.accountName) && b.balance > 0) return 1;

          // Then accounts with just a number
          if (isValid(a.accountNumber)) return -1;
          if (isValid(b.accountNumber)) return 1;

          // Then accounts with just a name
          if (isValid(a.accountName)) return -1;
          if (isValid(b.accountName)) return 1;

          return 0;
        });
      };

      state.bankAccounts = sortAccounts(accounts);
    },
    handleRevenueStatementData(state, action) {
      const data = action.payload;

      state.revenueStatement = {
        currencySymbol: data.base_currency_symbol,
        income: data.income,
        expenses: data.expenses,
        netLossProfit: data.net_loss_profit,
        burnRate: data.burn_rate,
      };
    },
    setAccountingPermission(state, action) {
      state.askAccountingPermission = action.payload;
    },
    setAdminAccess(state, action) {
      state.adminAccess = action.payload;
    },
    handleZohoStatus(state, action) {
      const data = action.payload;
      state.organizationName = data.zohoOrganizationName;
      state.baseCurrencySymbol = data.zohoBaseCurrencySymbol;
      state.isAdminConnected = data.isAdminConnected;
    },
    connectZoho(state) {
      state.isZohoConnected = true;
    },
    disconnectZoho(state) {
      state.isZohoConnected = false;
    }
  },
});

export const financeActions = financeSlice.actions;

export default financeSlice.reducer;
