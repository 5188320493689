import styles from "./ToggleButton.module.css";
import ToggleButtonItem from "./ToggleButtonItem";

const ToggleButton = ({ titleLeft, titleRight, active, ...props }) => {
  const handleToggle = (selected) => {
    if (selected != active) {
      props.onToggle(selected);
    }
  };

  return (
    <div className={`${props.className} ${styles.container}`}>
      <ToggleButtonItem
        title={titleLeft}
        onClick={handleToggle}
        active={active}
      />
      <ToggleButtonItem
        title={titleRight}
        onClick={handleToggle}
        active={active}
      />
    </div>
  );
};

export default ToggleButton;
