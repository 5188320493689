import { ONBOARDING_TITLE } from "../../consts/TextConstants";
import ModalLarge from "../UI/Modal/ModalLarge";
import OnboardingForm from "./OnboardingForm";
import styles from "./OnboardingPage.module.css";
import ProgressBar from "./ProgressBar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { onboardActions } from "./reducers/onboard-slice";
import { GradientText } from "../UI/GradientText";

const OnboardingPage = ({ ...props }) => {
  const { currentStep } = useSelector((state) => state.onboard);
  const dispatch = useDispatch();

  const handleStepChange = (step) => {
    dispatch(onboardActions.setCurrentStep(step));
  };

  return (
    <ModalLarge>
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <h1 className={styles.title}>
              <GradientText>{ONBOARDING_TITLE}</GradientText>
            </h1>
            <ProgressBar active={currentStep} onChangeStep={handleStepChange} />
          </div>
          <OnboardingForm onClose={props.onClose} />
        </div>
      </div>
    </ModalLarge>
  );
};

export default OnboardingPage;
