import { useEffect } from "react";
import styles from "./SearchBar.module.css";
import searchIcon from "/assets/common/Search.svg";
import useInput from "../../../hooks/use-input";
import { isNotEmpty } from "../../../utils/validations";

const SearchBar = ({ inputstyle, className, onChange, ...props }) => {
  const {
    value: searchValue,
    handleChange: handleSearchChange,
    handleBlur: handleSearchBlur,
    handleFocus: handleSearchFocus,
  } = useInput(isNotEmpty);

  useEffect(() => {
    onChange(searchValue);
  }, [searchValue]);

  return (
    <div className={`${styles.input__control} ${className}`}>
      <div className={`${styles.input} ${styles.searchbar} ${inputstyle}`}>
        <div className={styles.search_icon}>
          <img src={searchIcon} alt="" />
        </div>
        <input
          onChange={handleSearchChange}
          onBlur={handleSearchBlur}
          onFocus={handleSearchFocus}
          {...props}
        />
      </div>
    </div>
  );
};

export default SearchBar;
