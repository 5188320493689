import { LBL__PAY_FROM } from "../../../../consts/TextConstants";
import styles from "./PayFromOptions.module.css";
import rupeeIcon from "/assets/subscription/Currency_Rupee.svg";
import dollarIcon from "/assets/subscription/Currency_Dollar.svg";
import PaymentStatus from "./PaymentStatus";

const PayFromOption = ({ currency, country, onSelect }) => {
  return (
    <div onClick={() => onSelect(country)} className={styles.btn__pay_option}>
      <img src={currency} alt="" />
      <span className={styles.country_name}>{country?.toUpperCase()}</span>
    </div>
  );
};

const PayFromOptions = ({ onSelect }) => {
  return (
    <div className={styles.container}>

      <PaymentStatus />
      <div className={styles.question}>{LBL__PAY_FROM}</div>

      <div className={styles.pay_options}>
        <PayFromOption
          currency={dollarIcon}
          country="U.S.A"
          onSelect={onSelect}
        />
        <PayFromOption
          currency={rupeeIcon}
          country="India"
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};

export default PayFromOptions;
