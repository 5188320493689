import styles from "./Pagination.module.css";

const Pagination = ({ count = 3, active, onChange, ...props }) => {
    const handleClick = (index) => {
        if(active != index) {
            onChange(index);
        }
    };
  return (
    <div className={`${styles.container} ${props.className}`}>
      {[...Array(count).keys()].map((index) => (
        <div key={`pagination-${index}`}
          className={`${styles.item} ${active == index ? styles.active : ""}`}
          onClick={() => handleClick(index)}
        ></div>
      ))}
    </div>
  );
};

export default Pagination;
