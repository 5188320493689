/* --------------- URLS ----------------------*/
export const BASE_URL = import.meta.env.VITE_API_ENDPOINT;

export const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL; //Redirect URL from external apps

//Context paths
export const CONTEXT_PATH_AUTH = import.meta.env.VITE_AUTH_CONTEXT_PATH;
export const CONTEXT_PATH_ENTITY = import.meta.env.VITE_ENTITY_CONTEXT_PATH;
export const CONTEXT_PATH_USER = import.meta.env.VITE_USER_CONTEXT_PATH;

//super tokens website domain

// export const URL__WEBSITE_DOMAIN = "http://localhost:5173";
// // export const URL__WEBSITE_DOMAIN = "https://dev.app.merze.io";

// create user
export const URL_CREATE_USER = CONTEXT_PATH_AUTH + "register";

// get user authentication
export const URL_GET_TOKEN = CONTEXT_PATH_AUTH + "login";
export const URL_LOGOUT = CONTEXT_PATH_AUTH + "logout";

// verify otp
export const URL_VERIFY_OTP = CONTEXT_PATH_AUTH + "verify";

// gather user details for sign up
export const URL_SEND_USER_DETAILS = CONTEXT_PATH_USER + "details";

// get user details
export const URL__GET_USER_DETAILS = CONTEXT_PATH_USER + "details";

//create new password while registering
export const URL_CREATE_PASSWORD = CONTEXT_PATH_AUTH + "setPassword";

// forgot password
export const URL_FORGOT_PASSWORD = CONTEXT_PATH_AUTH + "forgotPassword";

export const URL_VERIFY_FORGOT_PASSWORD_OTP = CONTEXT_PATH_AUTH + "confirmOTP";

export const URL_RESET_PASSWORD = CONTEXT_PATH_AUTH + "setPassword";

// user flags
export const URL_GET_USER_FLAGS = CONTEXT_PATH_USER + "flags";

//Redirect google callback

// export const URL__REDIRECT_GOOGLE = "http://localhost:5173/auth/callback/google";
// export const URL__REDIRECT_GOOGLE = "https://dev.app.merze.io/auth/callback/google";

/* -------------------------------------- Entites ----------------------------------------*/

//get primary entity
export const URL_GET_PRIMARY_ENTITY =
  CONTEXT_PATH_ENTITY + "entity/getPrimaryEntity";

//get entities
export const URL_GET_ENTITIES = CONTEXT_PATH_ENTITY + "entity/listEntities";

//get entity by Id
export const URL__GET_ENTITY_BY_ID =
  CONTEXT_PATH_ENTITY + "entity/getEntityById";

// primary entity
export const URL__CREATE_PRIMARY_ENTITY =
  CONTEXT_PATH_ENTITY + "entity/onboarding-entity";

 //get parent entities
 export const URL__GET_PARENT_ENTITIES = CONTEXT_PATH_ENTITY + "entity/getEntitiesforSubsidiary";

//update entity details
export const URL__UPDATE_ENTITY = CONTEXT_PATH_ENTITY + "entity/updateEntity";

export const URL_GET_ENTITY_DOCUMENTS =
  CONTEXT_PATH_ENTITY + "entity/getDocuments/";

//Add new entity
export const URL__CREATE_OTHER_ENTITY =
  CONTEXT_PATH_ENTITY + "entity/createOtherEntities";

//foreign state
export const URL_CREATE_FOREIGN_STATE =
  CONTEXT_PATH_ENTITY + "entity/createForeignState";

export const URL_GET_FOREIGN_STATE =
  CONTEXT_PATH_ENTITY + "entity/getForeignState";

//board members
export const URL_GET_BOARD_MEMBERS =
  CONTEXT_PATH_ENTITY + "entity/getBoardMembers";

export const URL_ADD_BOARD_MEMBER =
  CONTEXT_PATH_ENTITY + "entity/createBoardMember";

export const URL_DELETE_BOARD_MEMBER =
  CONTEXT_PATH_ENTITY + "entity/deleteBoardMember";

/* ------------------------------------- Cap Table -------------------------------------- */
export const URL_GET_SHARE_HOLDERS =
  CONTEXT_PATH_ENTITY + "captable/getShareHolder";

export const URL_CREATE_SHARE_HOLDER =
  CONTEXT_PATH_ENTITY + "captable/createShareHolder";

export const URL_DELETE_SHARE_HOLDER =
  CONTEXT_PATH_ENTITY + "captable/deleteShareHolder";

export const URL_GET_SHARE_CERTIFICATE =
  CONTEXT_PATH_ENTITY + "captable/getShareHolderCertificate";

export const URL_GET_SHARE_HOLDER_EXCEL =
  CONTEXT_PATH_ENTITY + "captable/getShareHolderExcel";

/* ------------------------------------FInance ------------------------------------------ */
export const URL__CALLBACK_ZOHO = CONTEXT_PATH_ENTITY + "finance/zoho/callback";

// export const URL__REDIRECT_ZOHO =
//   "http://dev.api.merze.io/api/v1/finance/zoho/login?redirect_uri=http://localhost:5173/zoho/callback";
// export const URL__REDIRECT_ZOHO =
//   "http://dev.api.merze.io/api/v1/finance/zoho/login?redirect_uri=https://dev.app.merze.io/zoho/callback";

export const URL__GET_ACCOUNT_BALANCE =
  CONTEXT_PATH_ENTITY + "finance/zoho/getAccountsBalance";

export const REDIRECT_URL__ZOHO = REDIRECT_URL + "zoho/callback";

export const URL__GET_REVENUE_STATEMENT =
  CONTEXT_PATH_ENTITY + "finance/zoho/revenue";

export const URL__ZOHO_ADD_ADMIN_USER =
  CONTEXT_PATH_ENTITY + "finance/zoho/addAdminUser";

export const URL__ZOHO_DISCONNECT_ADMIN_ACCESS =
  CONTEXT_PATH_ENTITY + "finance/zoho/disconnect";

export const URL__ZOHO_STATUS = CONTEXT_PATH_ENTITY + "finance/zoho/status";

export const URL__ZOHO_SIGN_UP = "https://store.zoho.com/ResellerCustomerSignUp.do?id=7269f1b2d643bffb15910ba98ae9632a";

/* ---------------- Methods ------------------------ */
export const METHOD_GET = "GET";
export const METHOD_PUT = "PUT";
export const METHOD_POST = "POST";

/* --------------Headers ------------------*/
export const CT_APP_JSON = "application/json";

// /* ===========================================  SERVICES API ============================================================ */

export const URL__GET_ALL_SERVICES = CONTEXT_PATH_ENTITY + "services";

export const URL__POST_SERVICES = CONTEXT_PATH_ENTITY + "services/user";

export const URL__GET_ALL_SUBSCRIBED_SERVICES =
  CONTEXT_PATH_ENTITY + "services/user?";

export const URL__GET_BANK_ACCOUNT =
  CONTEXT_PATH_ENTITY + "payments/banks/get-bank-account";

export const URL__POST_GST_DETAILS =
  CONTEXT_PATH_ENTITY + "payments/banks/create-gst";

export const URL__PAYMENT_UPLOAD_RECIPT =
  CONTEXT_PATH_ENTITY + "payments/receipt/upload";

export const URL__SERVICES_SUPPORT_EMAIL = CONTEXT_PATH_ENTITY + `services/support-email`;

export  const URL__UPDATE_TASK_PHASE = CONTEXT_PATH_ENTITY + "services/tasks/update-task-phase";

export const URL__DOWNLOAD_TRC_DRAFT = CONTEXT_PATH_ENTITY + "services/tasks/download-trc-draft";

export const URL__UPLOAD_SIGNED_TRC = CONTEXT_PATH_ENTITY + "services/tasks/upload-trc-signed-copy";

export const URL__GET_TRC_YEARS = CONTEXT_PATH_ENTITY + "services/trc-years/";

export const URL__DOWNLOAD_SERVICE_PHASE_FILES = CONTEXT_PATH_ENTITY + "services/tasks/phase/files";

/* ===========================================  DEV URLS  ============================================================ */
//Enable before pushing into development branch

export const URL__WEBSITE_DOMAIN = REDIRECT_URL;

export const URL__REDIRECT_GOOGLE = REDIRECT_URL + "auth/callback/google";

export const URL__REDIRECT_ZOHO = BASE_URL + CONTEXT_PATH_ENTITY + "finance/zoho/login?redirect_uri=" + REDIRECT_URL + "zoho/callback";


/* ===========================================  LOCALHOST URLS  ============================================================ */
//Comment before pushing into development branch

// export const URL__WEBSITE_DOMAIN = "http://localhost:5173";

// export const URL__REDIRECT_GOOGLE = "http://localhost:5173/auth/callback/google";

// export const URL__REDIRECT_ZOHO = "http://dev.api.merze.io/api/v1/finance/zoho/login?redirect_uri=http://localhost:5173/zoho/callback";

/* ===========================================  END ============================================================ */
