import styles from "./ToggleButtonItem.module.css";
import { motion } from "framer-motion";

const ToggleButtonItem = ({ title, onClick, active }) => {
  return (
    <div className={styles.wrapper}>
      {active == title && (
        <motion.div
          layoutId="tab-button"
          className={styles.indicator}
        ></motion.div>
      )}
      <button
        className={`${styles.button__toggle} ${
          active == title ? styles.active : ""
        }`}
        onClick={() => onClick(title)}
      >
        {title}
      </button>
    </div>
  );
};

export default ToggleButtonItem;
