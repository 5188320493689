// GstForm.jsx

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSubmitGstDetailsMutation } from "../apis/serviceApi-slice";
import {
  BTN__NEXT,
  LBL__BUSINESS_ADDRESS,
  LBL__BUSINESS_NAME,
  LBL__GST,
} from "../../../../consts/TextConstants";
import styles from "./GstForm.module.css";
import Button from "../../../UI/Button/Button";
import { isNotEmpty } from "../../../../utils/validations";

const useInput = (validateValue) => {
  const [value, setValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const isValid = validateValue(value);
  const hasError = !isValid && isTouched;

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleBlur = () => {
    setIsTouched(true);
  };
  const handleFocus = () => {
    setIsTouched(false);
  };

  return {
    value,
    isValid,
    hasError,
    handleChange,
    handleBlur,
    handleFocus,
  };
};

const GSTInput = ({
  titleLabel,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
}) => (
  <div className={styles.input}>
    <label htmlFor={titleLabel}>{titleLabel}</label>

    <input
      id={titleLabel}
      name={titleLabel}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      type="text"
    />
  </div>
);

const GstForm = ({ onSubmit, onHide, entityId,onBusinessNameChange }) => {
  // const { primaryEntity } = useSelector((state) => state.entity);
  // const entity_id = primaryEntity?.entityId;

  const businessNameInput = useInput(isNotEmpty);
  const businessAddressInput = useInput(isNotEmpty);
  const gstinInput = useInput(isNotEmpty);
  const [isFormValid, setIsFormValid] = useState(false);

  const [submitGstDetails, { isLoading }] = useSubmitGstDetailsMutation(); // Use the hook for submitting GST

  useEffect(() => {
    setIsFormValid(
      businessNameInput.isValid &&
        businessAddressInput.isValid &&
        gstinInput.isValid
    );
  }, [
    businessNameInput.isValid,
    businessAddressInput.isValid,
    gstinInput.isValid,
  ]);

  const handleNextClick = async () => {
    if (isFormValid) {
      const requestData = {
        entity_id: entityId,
        business_name: businessNameInput.value,
        business_address: businessAddressInput.value,
        business_gstin: gstinInput.value,
      };

      try {
        await submitGstDetails(requestData).unwrap(); // Use the mutation to submit GST details
        onSubmit(requestData);
        onBusinessNameChange(businessNameInput.value);
        onHide();
      } catch (error) {
        console.error("Failed to submit GST data:", error);
      }
    }
  };

  return (
    <div className={styles.payments_container}>
      <div className={styles.gst_container}>
        <div className={styles.gst_title}>GST Details</div>
        <GSTInput
          titleLabel={LBL__BUSINESS_NAME}
          value={businessNameInput.value}
          onChange={businessNameInput.handleChange}
          onBlur={businessNameInput.handleBlur}
          onFocus={businessNameInput.handleFocus}
        />
        <GSTInput
          titleLabel={LBL__BUSINESS_ADDRESS}
          value={businessAddressInput.value}
          onChange={businessAddressInput.handleChange}
          onBlur={businessAddressInput.handleBlur}
          onFocus={businessAddressInput.handleFocus}
        />
        <GSTInput
          titleLabel={LBL__GST}
          value={gstinInput.value}
          onChange={gstinInput.handleChange}
          onBlur={gstinInput.handleBlur}
          onFocus={gstinInput.handleFocus}
        />
      </div>
      <Button
        className={`${styles.btn__next}`}
        buttonstyle="default-blue"
        onClick={handleNextClick}
        disabled={!isFormValid || isLoading}
        isLoading={isLoading}
      >
        {BTN__NEXT}
      </Button>
    </div>
  );
};

export default GstForm;
