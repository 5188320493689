import { motion } from "framer-motion";
import styles from "./LoadingGradientBox.module.css";

const LoadingGradientBox = ({ direction = "bottom", className }) => {
  const gradientAnimation = {
    animate: {
      backgroundPosition:
        direction === "right" ? ["200% 0%", "0% 0%"] : ["0% 200%", "0% 0%"],
    },
    transition: {
      duration: 3.4,
      ease: "linear",
      repeat: Infinity,
    },
  };

  return (
    <motion.div
      className={`${styles[direction]} ${className}`}
      animate={gradientAnimation.animate}
      transition={gradientAnimation.transition}
    />
  );
};

export default LoadingGradientBox;
