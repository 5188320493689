import styles from "./YearDropDown.module.css";
import DropDownContainer from "./DropDownContainer";
import arrowIcon from "/assets/common/Arrow_White.svg";

const YearDropdown = ({
  showMonth = true,
  months = ["31 Dec"],
  start,
  end,
  customOptions = [],
  ...props
}) => {
  let options = customOptions;
  if (customOptions.length == 0) {
    if (start) {
      for (let year = start; year <= end; year++) {
        if (showMonth) {
          months.forEach((month) => {
            options.push(`${month} ${year}`);
          });
        } else {
          options.push(`${year}`);
        }
      }
    } else {
      if (showMonth) {
        months.forEach((month) => {
          options.push(`${month} ${end}`);
        });
      } else {
        options.push(`${end}`);
      }
    }

    options.reverse();
  }

  return (
    <DropDownContainer
      options={options}
      className={`${styles.dropDownContainer} ${props.className}`}
      listStyle={`${styles.dropDownList} ${props.dropdownListSize}`}
      headerStyle={`${styles.dropdownHeader} ${props.dropdownSize}`}
      listItemStyle={styles.list_item}
      arrow={arrowIcon}
      {...props}
    ></DropDownContainer>
  );
};

export default YearDropdown;
