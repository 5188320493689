import styles from "./LoadingIndicator.module.css";
import { motion } from "framer-motion";

const LoadingIndicator = () => {
  return (
    <motion.div
      animate={{
        rotate: 360,
      }}
      transition={{
        ease: "linear",
        repeat: Infinity,
        duration: 1
      }}
      className={styles.loader}
    ></motion.div>
  );
};

export default LoadingIndicator;
